.kanban-filters-modal {
  .ant-modal-body {
    min-height: 300px;
    .filtersContent {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      // display: flex;
      // flex-direction: row;
      // flex-wrap: wrap;
      gap: 10px;
      .filterData {
        display: flex;
        flex-direction: column;
        span {
          font-weight: 600;
          font-size: 14px;
        }
        .ant-select {
          // width: 270px !important;
          height: 32px !important;
          border: none !important;
          border-radius: 5px !important;
          background-color: #f5f5f7 !important;
        }
        .ant-select-selector {
          border: none !important;
          border-radius: 5px !important;
          background-color: #f5f5f7 !important;
        }
        .ant-select-selection-placeholder {
          color: #acacac !important;
          font-weight: 400 !important;
        }
        .ant-picker {
          // width: 270px;
          border: none !important;
          border-radius: 5px !important;
          background-color: #f5f5f7 !important;
        }
        .ant-picker-input > input::placeholder {
          color: #acacac !important;
          font-weight: 400 !important;
        }
      }
    }
  }
}

@media (max-width:450px){
  .kanban-filters-modal{
    .ant-modal-body{
      .filtersContent{
        grid-template-columns: 1fr 1fr;
        padding: 5px 25px;
      }
    }
  }
}
