@import "../../../assets/globalVariables.scss";

.userResponsesModal {
  // height: 90vh;
  .ant-modal,
  .ant-modal-content {
    display: flex;
    flex-direction: column;
    height: 100%;
    margin: 0;
    top: 0;
    overflow: hidden;
  }
  .ant-modal-body {
    display: flex;
    flex-direction: column;
    height: 100%;
    gap: 20px;
    overflow: hidden;
  }
  &.dark {
    .charty text {
      fill: $white;
    }
    .charty svg > rect {
      fill: none;
    }
  }
}
