@import "../../../../assets/globalVariables.scss";

.holiday-widget-comp {
  display: flex;
  flex-direction: column;
  height: 100%;
  .holiday-widget-header {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 40px;
    padding: 5px 0;
    // background-color: red;
    .item-header {
      display: flex;
      padding-left: 10px;
      font-weight: 600;
      font-size: 15px;
    }
  }

  .holiday-calendar-content {
    overflow-y: auto;
  }
  .content-holiday {
    display: flex;
    padding: 5px 0;
    .content-item {
      display: flex;
      align-items: center;
      font-size: 14px;
      font-weight: 400;
      padding-left: 10px;
      .item-day {
        width: 50%;
        border-radius: 5px;
        color: #fff;
        display: flex;
        justify-content: center;
      }
    }
    &:nth-child(odd) {
      background-color: #f5f5f7;
    }
  }
  &.dark .content-holiday:nth-child(odd) {
    background-color: $d-black2;
  }

  // .holiday-calendar-content {
  // 	// margin-top: -30px;
  // }
}
