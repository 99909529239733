.small-card {
  margin-left: 20px;
  margin-top: 20px;
  // top: 139px;
  // left: 694px;
  width: 100%;
  height: 280px;
  background: yellow 0% 0% no-repeat padding-box;
  box-shadow: 0px 1px 4px #00000029;
  border-radius: 10px;
  opacity: 1;
}
