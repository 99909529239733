@import "../../../../assets/globalVariables.scss";

.pagaCardBruto {
  position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, 50%);
  transform: translate(-50%, -50%);
  animation: calculatorBruto 1s;

  .calculator {
    width: 700px;
    height: 726px;
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0px 1px 4px #00000029;
    border-radius: 10px;
    opacity: 1;
  }
  .ant-card-head {
    width: 100%;
    height: 45px;
    background: #1d3445 0% 0% no-repeat padding-box;
    border-radius: 10px 10px 0px 0px;
    opacity: 1;
    height: 24px;
    font: normal normal 600 18px/24px Open Sans;
    color: #ffffff;
    font-weight: bold;
  }

  .pagatHeader {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 2rem;
    width: 380px;
    height: 230px;
    background: #1d3445 0% 0% no-repeat padding-box;
    border-radius: 5px;
    opacity: 1;
    label {
      height: 22px;
      font: normal normal 600 16px/22px Open Sans;
      letter-spacing: 0px;
      color: #ffffff;
      opacity: 1;
    }
  }
  Input {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 306px;
    height: 37px;
    background: #ffffff 0% 0% no-repeat padding-box;
    border-radius: 5px;
    opacity: 1;
    font-size: 15px;
    padding-left: 13px;
  }

  .calculatorNumbers {
    gap: 10px;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    width: 160px;
    .btn {
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 62px;
      height: 52px;
      background: #1d3445 0% 0% no-repeat padding-box;
      border-radius: 5px;
      opacity: 1;
      color: #ffffff;
      font: normal normal 600 25px/34px Open Sans;
    }
  }

  .calcComponent {
    position: absolute;
    top: 9.5%;
    right: 12%;
  }

  .formContent {
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: absolute;
    bottom: 0;
  }

  .formInp {
    display: flex;
    flex-direction: row;
    gap: 5rem;
    position: absolute;
    bottom: 0;
    label {
      display: flex;
      width: 420px;
      font-weight: 600;
      font-size: 15px;
    }
    Input {
      position: relative;
      left: 12px;
      width: 230px;
    }
  }

  .icona {
    position: absolute;
    top: 1%;
    right: 2%;
    background: #1da193 0% 0% no-repeat padding-box;
    border-radius: 5px;

    Button {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 120px;
      font-size: 16px;
      font-weight: bold;
      height: 32px;
      background: linear-gradient(90deg, #02aab0, #00cdac);
      border-radius: 5px;
      opacity: 1;
      color: linear-gradient(90deg, #000428, #004e92);
    }
  }

  .brutOrNet {
    label {
      font-size: 25px;
      color: #1d3445;
      font-weight: bold;
    }
  }

  @keyframes calculatorBruto {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  .dark {
    .pagatHeader {
      background: $d-black5;
      .Input {
        background: inherit;
      }
    }
    .calculatorNumbers .btn {
      background: $d-black5;
    }
  }
}

// @media (max-width: 390px) {
//   .pagaCard {
//     .calculator {
//       height: 100%;
//     }
//     .ant-card.ant-card-bordered.calculator {
//       width: 300px;
//     }

//     .ant-row.calculator-row {
//       display: grid;
//       .ant-col {
//         max-width: 100%;
//       }
//     }
//   }
//   .pagaCard .contents {
//     height: 100%;
//   }
//   .pagaCard .contents .innerPaga {
//     padding-bottom: 15px !important;
//     font-size: 10px;
//   }
//   .pagaCard .innerPaga .pagaName {
//     font-size: 11px;
//     width: 300px !important;
//   }
// }
.pagaCardNeto {
  position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, 50%);
  transform: translate(-50%, -50%);
  animation: calculatorNeto 1s;

  .calculator {
    width: 700px;
    height: 726px;
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0px 1px 4px #00000029;
    border-radius: 10px;
    opacity: 1;
  }
  .ant-card-head {
    width: 100%;
    height: 45px;
    background: #1d3445 0% 0% no-repeat padding-box;
    border-radius: 10px 10px 0px 0px;
    opacity: 1;
    height: 24px;
    font: normal normal 600 18px/24px Open Sans;
    color: #ffffff;
    font-weight: bold;
  }

  .pagatHeader {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 2rem;
    width: 380px;
    height: 230px;
    background: #1d3445 0% 0% no-repeat padding-box;
    border-radius: 5px;
    opacity: 1;
    label {
      height: 22px;
      font: normal normal 600 16px/22px Open Sans;
      letter-spacing: 0px;
      color: #ffffff;
      opacity: 1;
    }
    Input {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 306px;
      height: 37px;
      background: #ffffff 0% 0% no-repeat padding-box;
      border-radius: 5px;
      opacity: 1;
      font-size: 15px;
      padding-left: 13px;
    }
  }

  .calculatorNumbers {
    gap: 10px;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    width: 160px;
    .btn {
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 62px;
      height: 52px;
      background: #1d3445 0% 0% no-repeat padding-box;
      border-radius: 5px;
      opacity: 1;
      color: #ffffff;
      font: normal normal 600 25px/34px Open Sans;
    }
  }

  .calcComponent {
    position: absolute;
    top: 9.5%;
    right: 12%;
  }

  .formContent {
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: absolute;
    bottom: 0;
  }

  .formInp {
    display: flex;
    flex-direction: row;
    gap: 5rem;
    position: absolute;
    bottom: 0;
    label {
      display: flex;
      width: 420px;
      font-weight: 600;
      font-size: 15px;
    }
    Input {
      position: relative;
      left: 12px;
      width: 230px;
    }
  }

  .icona {
    position: absolute;
    top: 1%;
    right: 2%;
    background: #1da193 0% 0% no-repeat padding-box;
    border-radius: 5px;

    Button {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 120px;
      font-size: 16px;
      font-weight: bold;
      height: 32px;
      background: linear-gradient(90deg, #02aab0, #00cdac);
      border-radius: 5px;
      opacity: 1;
      color: linear-gradient(90deg, #000428, #004e92);
    }
  }

  .brutOrNet {
    label {
      font-size: 25px;
      color: #1d3445;
      font-weight: bold;
    }
  }
  .dark {
    .pagatHeader {
      background: $d-black5;
      .Input {
        background: inherit;
      }
    }
    .calculatorNumbers .btn {
      background: $d-black5;
    }
  }
}
@keyframes calculatorNeto {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@media (max-width: 450px) {
  .pagaCardBruto {
    position: relative;
    padding: 10px;
    top: 0px;
    left: 0px;
    bottom: 0px;
    right: 0px;
    transform: unset !important;
    width: 100% !important;
  }
  .pagaCardBruto .pagatHeader {
    width: 100% !important;
  }
  .pagaCardBruto .calculator {
    width: 100% !important;
    height: fit-content !important;
  }
  .pagaCardBruto .formInp {
    position: sticky !important;
    width: 100% !important;
    .ant-form .ant-form-item {
      width: 360px !important;
    }
  }
  .pagaCardBruto .calcComponent {
    position: relative;
    left: 0px !important;
    right: 0px !important;
    top: 0px !important;
    margin-top: 10px;
  }

  .pagaCardNeto {
    position: relative;
    padding: 10px;
    top: 0px;
    left: 0px;
    bottom: 0px;
    right: 0px;
    transform: unset !important;
    width: 100% !important;
  }
  .pagaCardNeto .pagatHeader {
    width: 100% !important;
  }
  .pagaCardNeto .calculator {
    width: 100% !important;
    height: fit-content !important;
  }
  .pagaCardNeto .formInp {
    position: sticky !important;
    width: 100% !important;
    .ant-form .ant-form-item {
      width: 360px !important;
    }
  }
  .pagaCardNeto .calcComponent {
    position: relative;
    left: 0px !important;
    right: 0px !important;
    top: 0px !important;
    margin-top: 10px;
  }
}
