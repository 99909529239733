.confirm-modal {
  .ant-modal-content {
    .ant-modal-header {
      border-radius: 5px 5px 0 0;
    }
    .ant-modal-body {
      display: flex;
      justify-content: center;
      .confirm-body {
        display: flex;
        align-items: center;
        gap: 20px;
        flex-direction: column;
      }
    }
    .ant-modal-footer {
      display: flex;
      gap: 30px;
      justify-content: center;
    }
  }
}
