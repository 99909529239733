.wrap {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  .searchInput {
    width: 200px;
    margin-left: 15px;
    border-radius: 5px;
    // color: red;
    input::placeholder {
      color: #1d3445;
    }
  }
  .add-request {
    width: 250px;
    height: 32px;
    background: #1da193 0% 0% no-repeat padding-box;
    color: #fff;
    font: normal normal normal 14px/6px "Open Sans";
    border-radius: 5px;
    margin-right: 20px;
    // margin-top: -30px;
  }
  .kerkesa-leje-container {
    overflow: scroll;

    height: 100%;
    width: 100%;
    // max-width: 810px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
  }
}
.add-request-mobile {
  width: 250px;
  height: 32px;
  background-color: #1da193 !important;
  color: #fff !important;
  font: normal normal normal 14px/6px "Open Sans";
  border-radius: 5px !important;
  border: none !important;
  margin-top: 10px;
  margin-bottom: 10px;
}
.req-container {
  display: flex;
  justify-content: space-between;
  .ant-spin-nested-loading {
    width: 60px;
    height: 60px;
    div,
    .user-img {
      width: inherit;
      height: inherit;
      object-fit: cover;
    }
  }
  p {
    margin-bottom: 0px;
    font: normal normal normal 14px/19px "Open Sans";
  }
}
.req-container > div {
  display: flex;
  gap: 10px;
}
.start-date {
  font: normal normal 600 14px/19px "Open Sans";
  color: #323338;
  text-align: center;
  p {
    background: #1da193 0% 0% no-repeat padding-box;
    font: normal normal 600 14px/19px "Open Sans";
    border-radius: 2px;
    text-align: center;
    width: 103px;
    height: 21px;
    color: white;
  }
}
.end-date {
  font: normal normal 600 14px/19px "Open Sans";
  color: #323338;
  text-align: center;
  p {
    background: #ea3943 0% 0% no-repeat padding-box;
    font: normal normal 600 14px/19px "Open Sans";
    border-radius: 2px;
    text-align: center;
    width: 103px;
    height: 21px;
    color: white;
  }
}
.leave-requester {
  // display: flex;
  color: #1da193;
  font-weight: 600;
  cursor: pointer;
  .employeeName {
    margin-left: -60px;
  }
}

.disabled-requests {
  color: #a4a0a0;
  font-weight: 600;
  cursor: pointer;
  .employeeName {
    margin-left: -60px;
  }
}

.headerContent {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  gap: 2.7rem;
  span {
    font: normal normal 600 14px/19px "Open Sans";
  }
}

.mobile-pushime-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px;
}
