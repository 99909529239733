@import "../../../../../../assets/globalVariables.scss";

.problematikaModal {
  .ant-modal-content {
    // height: calc(100vh - 20px) !important;
    // height: calc(100dvh - 20px) !important;
    display: flex;
    flex-direction: column;
    .ant-modal-body {
      // height: calc(100vh - 97px);
      // max-height: 600px;
      height: 600px;
      .ant-form {
        display: flex;
        flex-direction: column;
        justify-content: center;
        // flex-wrap: wrap;
        height: 100%;
        gap: 10px !important;
        .selectUserFormItem {
          width: 100%;
        }
        .statusSelect {
          height: 30px;
          display: flex;
          justify-content: center;
          align-items: center;
          max-width: 155px;
          height: 25px;
          border-radius: 5px;
          font-family: Open Sans;
          font-size: 14px;
          font-weight: 400;
          line-height: 19px;
          letter-spacing: 0em;
          text-align: center;
          .ant-select-selector {
            height: 30px;
            border: none;
            background-color: transparent !important;
            align-items: center;
            font-size: 14px;
            border-radius: 5px;
          }

          .ant-select-arrow {
            display: none;
          }
        }
        .textAreaFormItem {
          width: 100% !important;
          // height: calc(100% - 164px);
          // min-height: 200px;
          height: 100%;
          .ant-form-item-row {
            height: 100%;
            .ant-col {
              height: 100%;
              .ant-form-item-control-input {
                height: 100%;
                .ant-form-item-control-input-content {
                  height: 100%;
                }
              }
            }
            .lajmerime-quill {
              height: 100%;
              border-radius: 5px !important;
              .ql-container.ql-snow {
                border: none !important;
              }
              .ql-disabled {
                .ql-editor {
                  > * {
                    cursor: not-allowed !important;
                  }
                }
              }
              .ql-image {
                display: inline-block !important;
              }
              input.ql-image {
                display: none !important;
              }
              .ql-editor {
                height: calc(100% - 64px);
              }
              .ql-editor > p > img {
                width: fit-content !important;
                height: 100% !important;
              }
            }
          }
        }

        .ngarkoniDokumenteButtonContainer {
          display: flex;
          justify-content: space-around;
          align-items: center;
          width: 187px;
          height: 32px;
          border-radius: 2px;
          border: 1px dashed #1d3445;
        }

        .formFirstRowContainer {
          height: fit-content;
          width: 100%;
          display: flex;
          justify-content: space-between;
          align-items: center;
          gap: 10px;
        }
        .formSecondRowContainer {
          height: fit-content;
          display: flex;
          width: 100%;
          justify-content: space-between;
          align-items: flex-end;
          gap: 10px;
          .ant-select {
            width: 30%;
            width: 345px !important;
          }
        }

        .formItemButtonContainer {
          display: flex;
          justify-content: space-between;
          align-content: flex-start;
          gap: 10px;
          width: 70%;
          .ant-select:not(.ant-select-customize-input) .ant-select-selector {
            background-color: transparent !important;
          }
        }
      }
    }
    .ant-modal-footer {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .leftSideButtonFooter {
        display: flex;
        align-items: center;
        gap: 10px;
        .ant-btn {
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }
      .confirmButtonModal {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 10px;
      }
    }
  }
  &.dark .ant-modal-body .ant-form .ngarkoniDokumenteButtonContainer {
    border-color: $white;
  }
}

.ant-form-item-label {
  font-family: Open Sans;
  font-size: 14px;
  font-weight: 600;
  line-height: 19px;
  letter-spacing: 0em;
  text-align: left;
}
@media (max-width: 820px) {
  .formSecondRowContainer {
    flex-direction: column;
    justify-content: center;
    align-items: center !important;
  }
}
@media (max-width: 820px) {
  .formSecondRowContainer {
    width: 100% !important;
    flex-direction: column;
    justify-content: center;
    align-items: center !important;
    .ant-form-item {
      .ant-select {
        width: 100% !important;
        // width: 345px !important;
      }
    }
    .formItemButtonContainer {
      justify-content: center !important;
      gap: 15px;
    }
  }
}
@media (max-width: 550px) {
  .formItemButtonContainer {
    flex-direction: column;
    justify-content: center !important;
    align-items: center;
    gap: 5px !important;
    .ngarkoniDokumenteButtonContainer {
      margin-bottom: 0px !important;
    }
  }
  .formFirstRowContainer {
    flex-direction: column;
  }
}
@media (max-width: 1560px) {
  .logsModalSideContainer {
    right: auto !important;
  }
}
