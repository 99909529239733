.chart-container {
  // margin: 10px;
  // margin-left: 20px;
  // padding-top: 10px;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 10px;
  animation: appearSoftly 1s;
  justify-content: center;
  padding-right: 0px;
  align-items: center;
  .custom-tooltip {
    display: flex;
    flex-direction: column;
    padding: 10px;
    width: max-content;
    transition: opacity 1s;
    font-family: "Open Sans";
  }
}
.chart-skeleton-loading {
  margin: 20px;
}
