@import "../../../../../../../index.scss";

//* styles documentationViewCard align-items:center, justify-content:space-between,and  text font-size:16px, font-weight:600, letter-spacing, and text color
.documentationViewCard {
  @include flex(center, space-between);
  @include text(16px, 600, 0, #323338);

  width: 100%;
  height: 61px;
  // width: stretch;
  background-color: #f7f8fa;
  border-radius: 10px;
  padding: 0 15px;
  position: relative;
  cursor: pointer;
  // animation: 0.8s ease-in-out 0s 1 loadAnimation;

  &:hover {
    background-color: #e7e9ec;
  }
  //* styles leftSection align-items:center, justify-content:center
  .leftSection {
    @include flex(center, center);
  }
  //* styles docCreationTime font-size:12px, font-weight:400, letter-spacing:0, and text color
  .docCreationTime {
    @include text(12px, 400, 0, rgba(0, 0, 0, 0.45));
  }
  //* styles renewalStamp align-items:center, justify-content:center
  .renewalStamp {
    position: absolute;
    color: #fff;
    background-color: #1264a3;
    border-radius: 5px;
    padding: 0 5px;
    height: 25px;
    top: -15px;
    right: -10px;
    font-size: 12px;
    line-height: 1;
    @include flex(center, center);
  }
  //* styles docStatusLabel font-size:14px, font-weight:400, letter-spacing:0, and text color
  .docStatusLabel {
    @include text(14px, 400, 0, #fff);
    text-align: center;
    padding: 6px 8px;
    border-radius: 5px;
    margin: 0 15px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  //* styles uploadedFilesLabel align-items:center, justify-content:center,and font-size:14px, font-weight:400, letter-spacing:0, and text color
  .uploadedFilesLabel {
    @include flex(center, center);
    @include text(14px, 400, 0, #fff);
    background-color: #71cf48;
    border-radius: 5px;
    text-align: center;
    padding: 6px 8px;
  }

  .warningIcon {
    color: #fff;
    margin-right: 8px;
    font-size: 18px;
  }

  .noFiles {
    background-color: #fe4c4a;
  }
  //* styles docTitle text-overflow: ellipsis
  .docTitle {
    @include textEllipsis();
    max-width: 220px;
  }
  &.dark {
    color: $grey-2;
    background: $d-black3;
    &:hover {
      background: $d-black4;
    }
    .docCreationTime {
      color: $white;
    }
  }
}

@keyframes loadAnimation {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
//* makes DocumentationCard responsive for  Pc monitor
@media (max-width: 1025px) {
  .documentationViewCard {
    width: max-content;
  }
}
//* makes DocumentationCard responsive for iPhone 13 Pro
@media (max-width: 391px) {
  .documentationViewCard {
    width: 260px;
    background-color: #f7f8fa !important;
    right: 28px;
  }
  .documentationViewCard .docTitle {
    font-size: 12px;
  }
  .documentationViewCard .docCreationTime {
    font-size: 10px;
  }
  .documentationViewCard .docStatusLabel {
    font-size: 10px;
    margin: 0 7px;
  }
  .documentationViewCard .uploadedFilesLabel {
    font-size: 10px;
  }
}
