@import "../../../../index.scss";

.docFormActionButtons {
  .ant-btn {
    overflow: hidden;
  }

  .discardButton {
    @include mondayButton(#ff4d4f, #eb4345);
    .mondayButtonIcon {
      color: #fff;
    }
    margin-right: 12px;
  }

  .submitIcon {
    width: 23px;
  }
}
