.crash-shift-modal {
  .ant-modal-close {
    display: none;
  }
  .ant-modal-header {
    // height: 45px;
    background: #ea3943 0% 0% no-repeat padding-box !important;
    border-radius: 10px 10px 0px 0px;
    color: white;
    display: flex;
    justify-content: center;
  }

  .ant-modal-body {
    background-color: white;
    height: 220px;
    display: flex;
    flex-direction: column;
    .crash-shift-wrapper {
      display: flex;
      justify-content: center;
      flex-direction: column;
      .date {
        display: flex;
        justify-content: center;
        font: normal normal 600 16px/19px Open Sans;
      }
      .warning-text {
        display: flex;
        justify-content: center;
        // width: 292px;
        margin-top: 10px;
        height: 38px;
        /* UI Properties */
        text-align: center;
        font: normal normal normal 14px/19px Open Sans;
      }
      .crash-shifts {
        margin-top: 30px;
        display: flex;
        justify-content: center;
        flex-direction: row;
        gap: 50px;
        .crash-old-shift,
        .crash-new-shift {
          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: column;

          .shift-label {
            font: normal normal 600 14px/19px Open Sans;
          }
          .time-shift-format {
            width: 193px;
            height: 21px;
            border-radius: 2px;
            display: flex;
            justify-content: center;
            color: white;
          }
        }
      }
    }
    .footer {
      height: 48px;
      /* UI Properties */
      background: #f5f5f7 0% 0% no-repeat padding-box;
      border-radius: 0px 0px 5px 5px;
      opacity: 1;
    }
  }
}
