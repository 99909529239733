// punojsit page CARDS PUNOJSTI STYLE
// .punonjesit-cards {
//   display: flex;
//   justify-content: center;
//   align-items: center;
// width: auto;
// .ant-card.ant-card-bordered {
//   border-radius: 10px;
//   box-shadow: 1px 1px 1px 1px #00000029;
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   width: 1190px;
// }
// }

//ICONA per cards
// svg#Layer_1 {
//   fill: transparent;
// }
.add-icon {
  path#Path_6861_00000147205192370109036890000003473032641409739905_ {
    fill: #1da193;
  }
}

.shiftGridContent {
  // display: flex;
  gap: 20px;
  // width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: 3fr 1fr;
  max-width: 1300px;
  min-height: 600px;
  .agGridComponent {
    // width: 900px;
    // height: 600px;
    border-radius: 15px;
  }
}
.ag-root-wrapper.ag-layout-normal.ag-ltr {
  width: 100% !important;
  height: 100%;
}

// .ant-dropdown.ant-dropdown-placement-bottomCenter
//   li.ant-dropdown-menu-item.ant-dropdown-menu-item-only-child {
//   height: 32px !important;
//   border-bottom: 2px solid #efefef;
//   padding-top: 19px;
//   padding-bottom: 19px;
// }

// .ant-dropdown.ant-dropdown-placement-bottomCenter .ant-dropdown-menu {
//   background: #f5f5f7;
//   padding: 0px 0px;
// }

// ag grid  punojsit
.month-picker.ant-picker {
  border: none;
  background-color: #f1f7fb;
  // .anticon{
  //   display: none;
  // }
  .ant-picker-input > input {
    text-align: center;
  }
}

@media (max-width: 1250px) {
  .ag-grid-shifts {
    .shiftGridContent {
      display: grid;
      grid-template-columns: 1fr;
      grid-template-rows: minmax(0, 1fr) minmax(0, 1fr);
      width: 100%;
      height: 100%;
      overflow-y: scroll;
    }
  }
}
