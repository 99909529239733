@import "../../../../../assets/globalVariables.scss";

.detailedFestaModal {
  width: 1300px !important;
  .festatContent {
    display: flex;
    flex-direction: row;
    gap: 20px;
    .depsContent {
      display: flex;
      flex-direction: column;
      gap: 15px;
      width: 400px;
      height: 510px;
    }
  }
  .inputSearch {
    display: flex;
    flex-direction: row;
    gap: 20px;
  }
  .allDeps {
    display: flex;
    flex-direction: column;
    width: 380px;
    overflow-y: auto;
    .department-row {
      padding: 10px;
      cursor: pointer;
      font-size: 16px;
      &.selected {
        background: #f0f5f9;
        color: #1d3445;
        font-weight: 600;
      }
    }
  }
  .footerContent {
    display: flex;
    justify-content: space-between;
    padding-top: 50px;
    Button {
      width: 150px;
      height: 32px;
      border-radius: 5px;
      opacity: 1;
      border: none;
      color: #fff;
      &:nth-child(1) {
        background-color: #ea3943;
      }
      &:nth-child(2) {
        background-color: #1da193;
      }
    }
  }
  &.dark .allDeps .department-row.selected {
    color: $white;
    background: $d-black2;
  }
}
