.info-rreth-kompanis {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 10px;
  overflow: hidden;
  .ant-form {
    overflow-y: auto;
  }
  // padding-top: 30px;.
  // .sortable-list-container {
  //   height: calc(100vh - 195px);
  //   overflow: auto;
  //   margin-right: 15px !important;
  // }
  // .sortable-item-style {
  //   height: 380px !important;
  // }
  .ant-row.ant-form-item {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0px;
  }
  // .ant-form-item-explain.ant-form-item-explain-connected{
  //     display: none;
  // }
  .info-rreth-kompanis-footer {
    height: 50px;
    align-items: center;
    justify-content: flex-end;
    display: flex;
    gap: 30px;
    padding: 15px;
    margin: 0 10px;
    box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.1607843137);
    border-radius: 10px;
    .ant-form-item {
      margin: 0px !important;
    }
  }
  .comp-info-card-container {
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 15px;
  }
  .employee-of-month{
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  .input,
  .text-area {
    display: grid;
    grid-template-columns: 8fr 15fr 1fr;
    gap: 10px;
    align-items: center;

    span.star-color {
      color: #ff4e00;
    }
    h3 {
      font-weight: 600;
      text-align: right;
      font-size: larger;
      margin: 0;
    }
    span.card-input-icon {
      background: #1da193;
      width: 23px;
      height: 23px;
      justify-content: center;
      align-items: center;
      color: #fff;
      display: flex;
      // border-radius: 5px;
      svg {
        color: #fff;
      }
    }
    .ant-input-group-addon {
      border: 0px !important;
    }
  }
}

// dynamic component scss for checkbox
.dynamic-checkbox {
  label.ant-checkbox-wrapper {
    align-items: center;
    display: flex;
    height: 25px;
    margin-top: 20px;
    margin-left: 20px;
  }
  .ant-checkbox-inner {
    background: #1da193 !important;
    width: 25px;
    height: 25px;
  }
  .ant-checkbox-inner:after {
    width: 6px;
    height: 15px;
  }
  h5 {
    margin-top: 10px;
    font-size: medium;
    font-weight: 500;
  }
}

.cards-konfig .ant-form-item-explain.ant-form-item-explain-connected {
  display: none;
}

.cilsimet_perzgjedhura_checkbox {
  margin-left: 40px;
  .ant-form-item-explain.ant-form-item-explain-connected {
    display: flex;
    margin-left: 40px;
  }
}

// .cards-konfig .ant-form-item{
//     margin-bottom: 0px !important;
// }

.detajet-kompania-card-responsive {
  .ant-picker.responsive-date-picker {
    width: 300px !important;
  }
}

.info-rreth-kompanis {
  .ant-form-item-label > label {
    font: normal normal 600 16px/22px Open Sans;
  }
}

.img-emp-month {
  width: 30%;
  height: 100%;
  display: flex;
  .img-div {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: flex-start;
  }
}

@media (max-width: 450px) {
  .detajet-kompania-card-responsive {
    display: flex;
    flex-direction: column;
    gap: 5px;
    .ant-row {
      display: flex;
      flex-direction: column;
      gap: 5px;
    }
  }
}
