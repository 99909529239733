@import "../../../../assets/globalVariables.scss";

.report-wrapper {
  display: flex;
  justify-content: center;
  width: 100%;
  height: 100%;
  .ant-card {
    .ant-card-head {
      background: #1d3445;
      color: #fbfbfb;
      border: none;
    }
    &.dark {
      .report .report-body {
        border-color: $grey-3;
      }
    }
  }
  // background-color: aquamarine;
  .report {
    display: flex;
    flex-direction: column;
    gap: 15px;
    height: 100%;
    // min-width: 1200px;

    .report-tools {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-wrap: wrap;
      // border: 1px solid #e3eaf3;
      border-bottom: none;
      &:last-child {
        padding: 10px;
        .print {
          background: #009fb7 !important;
          &:hover {
            color: #b3d2fb;
          }
        }
        button.ant-btn.ant-btn-default.print {
          background: #009fb7;
          width: 120px;
          border: none;
          border-radius: 5px;
          height: 32px;
          color: #fff;
        }
      }
      div {
        display: flex;
        gap: 10px;
      }
      button#test-table-xls-button {
        padding: 0;
        border: none;
        background: none;
      }
    }

    .report-body {
      border: 1px solid #e3eaf3;
      padding: 15px;
      display: flex;
      flex-direction: column;
      width: 100%;
      height: 610px;
      overflow: auto;
      input:focus {
        box-shadow: none;
      }
      .report-title {
        width: 100%;
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        gap: 10px;
        height: 130px;
        .input-holder {
          span {
            width: 100%;
            font-size: 20px;

            font-weight: 600;

            h5,
            h1 {
              border: 1px solid #fff;
              padding: 2px 5px;
              &:hover {
                border: 1px solid #e3eaf3;
              }
            }
          }
        }
      }
      .report-notes {
        margin-top: 20;
        margin-bottom: 20;
        width: 100%;

        span {
          width: 100%;
          &:hover {
            border: 1px solid #e3eaf3;
          }
        }
      }
      .reports-footer {
        display: flex;
        margin-top: auto;
        justify-content: center;
        height: 50px;
        span {
          margin-top: 20;
        }
      }
    }
  }
}

@media print {
  * {
    size: A4 portrait;
  }
  @page {
    size: A4 portrait;
    // margin: 20mm;
  }

  .report {
    // thead {
    // 	// display: table-header-group;
    // }
    size: A4 portrait;

    // padding: 20px !important;
    // margin: 20px !important;
    // margin-right: 20px !important;
    // margin-left: 20px !important;
    text-align: center;
    .report-tools {
      display: none;
    }
  }
}
