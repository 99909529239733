@import "../../../index.scss";

.statusesPanelContainer {
  width: 100%;
  padding: 23px;
  border-radius: 10px;
  overflow-x: auto;
  min-height: 150px;
  user-select: none;
  margin-bottom: 0px !important;

  //* styles statusesPanelContent align-items:center, justify-content:stretch,and distributes items evenly ti fit the div
  .statusesPanelContent {
    @include flex(center, stretch);

    flex-wrap: nowrap;
  }
  .clickable {
    cursor: pointer;
    &:hover {
      filter: brightness(0.95);
    }
  }
}
