.fetchDateRangeModal {
  .filtersWrapper {
    display: flex;
    justify-content: space-evenly;
    .pickers {
      display: flex;
      flex-direction: column;
      gap: 10px;
    }
    .ant-picker {
      width: 100%;
    }
  }
}
