.clock-log-drawer {
  .ant-drawer-content-wrapper {
    width: 530px !important;
    background-color: #f5f5f7 !important;
    margin: 0 !important;
    padding: 0 !important;
  }
  .ant-drawer-body {
    overflow: scroll;
  }

  .ant-drawer-header {
    height: 45px !important;
    background-color: #1d3445;
    .ant-drawer-title {
      color: #1d3445;
      font-family: Open Sans;
      font-size: 16px;
      font-weight: 600;
      line-height: 22px;
      letter-spacing: 0em;
    }
  }

  .ant-drawer-body {
    padding: 0 !important;
  }
}

.clock-in-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  svg#Layer_1 {
    width: 25px;
  }
}

.list-of-shift-logs {
  width: 500px;
  list-style-type: none;
  padding: 0 !important;

  .list-item-drawer {
    display: flex;
    justify-content: center;
    flex-direction: row;
    align-items: center;
    width: 500px;
    padding: 0 !important;
  }
  ul {
    background-color: #f5f5f7;
    display: flex;
    padding-top: 5px;
    padding-bottom: 5px;
    margin-left: -20px !important;
    list-style-type: none;

    .date {
      margin-left: 0px;
      font: normal normal 600 16px/22px Open Sans;
      letter-spacing: 0px;
      color: #323338;
      width: 90px;
    }
    .day {
      margin-left: 40px;
      font: normal normal normal 16px/22px Open Sans;
      letter-spacing: 0px;
      color: #323338;
      width: 130px;
    }

    .clock-in-title {
      // margin-right: 150px;
      width: 90px;
      margin-left: 10px;
      color: #323338;
    }
    .clock-in-icon {
      // flex: 1;
      width: 27.7px !important;
      height: 27.7px;
      // margin-top: 10px;
      margin-top: -5px;

      // margin-left: -145px;
      path#Path_7693 {
        fill: #1da193;
      }
    }
    .clock-out-icon {
      // flex: 1;
      width: 27.7px !important;
      height: 27.7px;
      // margin-top: 10px;
      margin-top: -5px;

      // margin-left: -145px;
      path#Path_7710 {
        fill: #ea3943;
      }
    }
  }
  ul:nth-child(2n) {
    background-color: white;
  }
}

.multi-clocks {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding-top: 5px;
  padding-bottom: 5px;
}

.multi-clock {
  display: flex;
  flex-direction: row;
}

.clock-block {
  padding-top: 5px;
  padding-bottom: 5px;
}
.search-header {
  height: 70px;
  display: flex;
  justify-content: center;
  align-items: center;
  // margin-left: 20px;
  .ant-input-affix-wrapper {
    width: 480px !important;

    .ant-input {
      width: 300px !important;
    }
  }
}

@media (max-width: 450px) {
  .ant-drawer.ant-drawer-right.ant-drawer-open.clock-log-drawer {
    .shift-log-container {
      width: 100% !important;
      .list-of-shift-logs {
        width: 100% !important;
      }
      .list-of-shift-logs .list-item-drawer {
        width: 100% !important;
      }
      .list-of-shift-logs ul {
        margin-left: -60px !important;
      }
    }
  }
}
