@import "../../../../../assets/globalVariables.scss";

.enable2FaModal {
  .ant-modal-body {
    display: flex;
    justify-content: center;
    .enable2FaModalcontainer {
      display: flex;
      flex-direction: column;
      align-items: center;
      height: 520px;
      // max-height: calc(100dvh - 200px);
      .headerSearch {
        display: flex;
        gap: 10px;
        margin-bottom: 20px;
        .icon {
          display: flex;
          align-items: center;
        }
      }
      .item.ant-checkbox-wrapper {
        display: flex;
        align-items: center;
        gap: 20px;
        width: 250px;
        padding: 10px 5px;
        animation: appearSoftly 1s;
        transition: padding 300ms;
        user-select: none;
        color: #323338;

        .ant-checkbox {
          top: 0;
        }
        span {
          font-size: 14px;
          font-family: "Open Sans", sans-serif;
          overflow-wrap: break-word;
          font-weight: 400;
        }
        .ant-checkbox-inner {
          border: 3px solid #1da193;
          background-color: #1da193;
          padding: 8px;
          &:hover {
            border: 3px solid #1da193;
            background-color: #1da193;
          }
        }
      }
      .item.ant-checkbox-wrapper:hover,
      .item.ant-checkbox-wrapper.checked:hover {
        background-color: #dce4e9;
        padding: 10px;
      }
      .item.ant-checkbox-wrapper.checked {
        background-color: #f3f6f7;
      }
      .item.ant-checkbox-wrapper.hide {
        display: none;
        animation: disappearSoftly 1s;
      }
      .item.ant-checkbox-wrapper.animate {
        animation: appearSoftly 1s;
      }
      .divider {
        border: 2px solid #f1f1f1;
        border-radius: 10px;
      }
      .tableContent {
        display: flex;
        flex-direction: column;
        gap: 2px;
        // width: 250px;
        height: 100%;
        overflow-y: auto;
      }
    }
  }
  .mondayButtonGreen {
    .ant-spin-dot-holder {
      color: #fff;
    }
  }
  &.dark {
    .ant-modal-body .enable2FaModalcontainer .item.ant-checkbox-wrapper {
      color: $white;
      &:hover,
      &.checked:hover {
        background-color: $d-black5;
        padding: 10px;
      }
      &.checked {
        background-color: $d-black2;
      }
    }
  }
}
@keyframes appearSoftly {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes disappearSoftly {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
