.addLeaveEmailContainer {
  .ant-modal-body {
    min-height: 600px;
    overflow-x: hidden;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
}
