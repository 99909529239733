@import "../../../../../../../index.scss";
@import "../../../../../../../assets/globalVariables.scss";

.column-gap-10 {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.row-gap-10 {
  display: flex;
  flex-direction: row;
  gap: 10px;
}
.docUploader {
  display: flex;
  flex-direction: column;
  gap: 30px;
  min-width: 355px;
  box-sizing: border-box;
  padding: 0;

  .dropdown {
    background: #f5f5f7;
    border: 0;
    border-radius: 5px;
    width: 100%;
    height: 100%;
  }

  //* styles uploadSection align-items:center, justify-content:center, and flex-direction:column
  .uploadSection {
    @include flex(center, center, column);
    margin-bottom: 0;
  }
  //* styles uploaderTitle font-size:16px, font-weight:600, letter-spacing:0, and text color
  .uploaderTitle {
    @include text(16px, 600, 0, #0f5c97);
    margin-bottom: 11px;
    margin-top: 11px;
  }

  //* styles uploadedFilesSection align-items:center, justify-content:flex-start, and flex-direction:column
  .uploadedFilesSection {
    @include flex(center, flex-start, column);
    gap: 10px;
    max-height: 400px;
    padding: 0;
    overflow-y: auto;
    width: 100%;
  }

  //* styles uploadedFile align-items:center, justify-content:center
  .uploadedFile {
    @include flex(center, space-between);
    flex-grow: 1;
  }

  .filesIcon {
    justify-self: flex-start;
    width: 30px;
  }

  .fileCard {
    margin: 0;
    background: #f7f8fa;
    border-radius: 5px;
    padding: 10px 20px;
    animation: fadeInDown 1000ms both;
    width: 100%;
  }

  //* styles fileName font-size:16px, font-weight:700, letter-spacing:0, and text color
  .fileName {
    @include text(16px, 700, 0, #1264a3);
    margin: 0;
    cursor: pointer;
    width: 220px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    &:hover {
      color: #1f2a44;
    }
  }

  //* styles fileAuthor align-items:center, justify-content:space-around
  .fileAuthor {
    width: 100%;
    @include flex(center, space-between);
    color: rgba(0, 0, 0, 0.45);
  }

  .closeIcon {
    width: 12px;
    fill: #fff;
  }

  //* styles closeIconContainer align-items:center, justify-content:center
  .closeIconContainer {
    background: #fe4c4a;
    justify-self: flex-end;
    width: 25px;
    height: 25px;
    border-radius: 5px;
    cursor: pointer;
    @include flex(center, center);

    &:hover {
      background: #eb4345;
    }
  }
  &.dark {
    .uploaderTitle,
    .fileName {
      color: $grey-1;
    }
    .fileAuthor {
      color: $grey-2;
    }
    .fileCard {
      background: $d-black4;
    }
  }
}

@keyframes fadeInDown {
  from {
    opacity: 0;
    transform: translate3d(0, -100%, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0) 0;
  }
}
.uploadButton {
  border-radius: 5px !important;
  @include mondayButton(#0f5c97, #0f5c97);
}
