.emp-Month-card {
  display: flex;
  justify-content: space-between;
  height: 100%;
  .cepi-majtas {
    display: flex;
    align-items: flex-end;
    height: 100%;
  }
  .content-emp-month-noData {
    display: flex;
    justify-content: center;
    align-items: center;
    font: normal normal bold 14px/38px Open Sans;
  }
  .content-emp-month {
    display: flex;
    justify-content: flex-start;
    height: 280px;
    .content-emp {
      margin-left: -40px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      // gap: 10px;
      .muaj {
        font: normal normal bold 14px/38px Open Sans;
        color: #323338;
      }
      .emri {
        font: normal normal bold 30px/38px Open Sans;
        color: #323338;
      }
      .message {
        font: normal normal normal 16px/38px Open Sans;
        color: #323338;
      }
    }
    .medalja {
      margin-right: 30px;
      padding-top: 20px;
      margin-left: 30px;
    }
    .foto {
      margin-right: -40px;
      display: flex;
      align-items: flex-end;
    }
  }
}

@media (max-width: 450px) {
  .emp-Month-card .content-emp-month {
    flex-direction: column !important;
  }
  .emp-Month-card {
    height: 370px !important;
  }
  .emp-Month-card .content-emp-month .medalja {
    margin-left: -30px !important;
    margin-right: 30px !important;
  }
  .emp-Month-card .content-emp-month .content-emp .message {
    overflow: auto;
    height: 115px;
  }
  .emp-Month-card .content-emp-month .content-emp {
    margin-left: -60px !important;
  }
  .emp-Month-card .content-emp-month .content-emp .muaj {
    height: 36px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    width: 260px;
  }
  .emp-Month-card .content-emp-month {
    width: calc(100% - 172px);
  }
}
