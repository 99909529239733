@import "../../../../assets/globalVariables.scss";

.edit-employee-wage {
  .edit-wage-div {
    display: flex;
    flex-direction: column;
    gap: 10px;
    .edit-wage-header {
      display: flex;
      flex-direction: column;
      gap: 5px;
      .warning-header {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        gap: 10px;
        font: normal normal 600 14px/19px Open Sans;
        letter-spacing: 0px;
        color: #ea3943;
        opacity: 1;
        padding: 10px;
        background: #fce8e8 0% 0% no-repeat padding-box;
        border: 1px solid #f5d4d4;
        border-radius: 5px;
      }
    }
    .edit-wage-row {
      display: flex;
      justify-content: space-between;
      gap: 40px;
      border-radius: 5px;
      padding: 20px;
      .editWageItem {
        display: flex;
        flex-direction: column;
        justify-content: center;
        gap: 5px;
        width: 100%;
        label {
          font: normal normal 600 16px/22px Open Sans;
          text-align: left;
          margin-left: 5px;
        }
        .ant-picker,
        .ant-select-selector,
        .ant-input-number {
          width: 100%;
        }
      }
    }
  }
  &.dark {
    .edit-wage-header {
      .warning-header {
        background: unset;
        border-color: $red;
        color: #f0747b;
      }
      .edit-wage-row {
        background: $d-black2;
      }
    }
    .ant-card-head {
      color: $white;
    }
    .ant-card-bordered {
      color: $white;
      background: unset;
      border: 1px solid $grey-3;
      .ant-card-grid {
        box-shadow: none;
        border: none;
        box-shadow: 0px 0px 1px $white;
      }
    }
  }
}

.ant-popover.ant-popconfirm.popup-edit-wage.ant-popover-placement-left {
  padding-right: 25.313708500000004px;
}

.ant-popover.ant-popconfirm.popup-edit-wage.ant-popover-placement-left {
  width: 542px !important;
  height: 70px;
  .ant-popover-inner-content {
    height: 70px !important;
    display: flex;
    margin-left: 10px;
    justify-content: center;
    align-items: center;
    padding: 0px;
  }
  .ant-popover-inner {
    border-radius: 10px !important;
  }
  .ant-popover-message {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .ant-popover-message-title {
    margin-right: 13px;
    font-weight: 500;
  }
  .ant-popover-buttons {
    height: 70px;
    margin: 0px;
    padding-left: 8.2px;
    button.ant-btn.ant-btn-sm {
      width: 50px;
      height: 70px;
      background: #ff4e00;
      border: none;
      color: #fff;
      border-radius: 0px;
      cursor: pointer;
    }
    button.ant-btn.ant-btn-primary.ant-btn-sm {
      width: 50px;
      height: 70px;
      border: none;
      background: #1da193;
      margin: 0px;
      color: #fff;
      border-radius: 0px 8px 8px 0px;
      cursor: pointer;
    }
  }
}

@media (max-width: 450px) {
  .edit-employee-wage {
    .edit-wage-div {
      .edit-wage-row {
        flex-direction: column;
        .editWageItem {
          display: grid;
          grid-template-columns: minmax(0, 2fr) minmax(0, 3fr);
        }
      }
    }
  }
}
