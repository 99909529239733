@import "../../../../assets/globalVariables.scss";

.user-request {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  .noData {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 0.5rem;
    padding-top: 60px;
    p {
      font-size: 15px;
      font-weight: 600;
    }
  }

  .headersLabel {
    display: flex;
    flex-direction: row;
    align-items: center;

    width: 100%;
    height: 35px;
    .headerTips {
      font-weight: 600;
      font-size: 15px;
      width: 20%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .userRequestsData {
    display: flex;
    flex-direction: column;
    width: 100%;
    overflow-y: auto;
    .requests-item {
      display: grid;
      width: 100%;
      padding: 5px 0;
      &:nth-child(odd) {
        background-color: #f5f5f7;
      }
      .requests-item-cell {
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }
  &.dark .userRequestsData .requests-item:nth-child(odd) {
    background-color: $d-black2;
  }
}
