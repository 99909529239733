.employeesPersonaleView {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 10px;
  height: 100%;
  overflow-y: auto;
  .ant-row.editableCards {
    display: grid;
    grid-template-columns: minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr) minmax(
        0,
        1fr
      );
    gap: 10px;
    .ant-card {
      width: 100%;
      .ant-card-head {
        max-height: 48px;
        .ant-card-head-wrapper {
          height: 100%;
        }
      }
    }
  }
  .ant-row.userActionCards {
    display: grid;
    grid-template-columns: minmax(0, 1fr) minmax(0, 1fr) minmax(0, 2fr);
    gap: 10px;
  }
}

@media (max-width: 1200px) {
  .employeesPersonaleView {
    gap: 50px;
    .ant-row.editableCards,
    .ant-row.userActionCards {
      grid-template-columns: minmax(0, 1fr) minmax(0, 1fr);
      gap: 50px;
      .user-action-card {
        margin: 0;
      }
      .user-action-card:last-child {
        grid-column: 1 / 3;
      }
    }
  }
}
@media (max-width: 768px) {
  .employeesPersonaleView {
    gap: 10px;
    .ant-row.editableCards,
    .ant-row.userActionCards {
      gap: 10px;
    }
  }
}
@media (max-width: 450px) {
  .employeesPersonaleView {
    .ant-row.editableCards,
    .ant-row.userActionCards {
      grid-template-columns: minmax(0, 1fr);
      .user-action-card {
        width: 100%;
      }
      .user-action-card:last-child {
        grid-column: auto;
      }
    }
  }
}
