@import "../../../assets/globalVariables.scss";

.kanban {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 10px;
  animation: kanbanBoardAnim 0.5s;
  height: 100%;
  .kanbanBoard {
    flex: 1 1 0;
    height: 100%;
    border-radius: 10px;
    background-color: #ffffff;

    .kanbanBoardColor {
      width: 100%;
      height: 10px;
      border-radius: 10px 10px 0px 0px;
    }
    .namesContent {
      display: flex;
      justify-content: center;
      align-items: center;
      padding-top: 10px;
      span {
        font-weight: 600;
        font-size: 14px;
      }
    }
    .displayInterviews {
      display: flex;
      align-items: center;
      flex-direction: column;
      gap: 5px;
      padding: 10px;
      height: 740px;
      overflow-y: auto;
      // .interviewer {
      //   display: flex;
      //   flex-direction: column;
      //   padding: 10px;
      //   width: calc(100% - 40px);
      //   border-radius: 5px;
      //   background-color: #f8f8fa;
      //   cursor: pointer;
      //   transition: all 300ms ease;
      //   &:hover {
      //     background-color: #f1f5fd;
      //     width: calc(100% - 20px);
      //   }
      //   .dndIcon {
      //     display: flex;
      //     justify-content: space-between;
      //   }
      //   .interviewDate {
      //     display: flex;
      //     align-items: center;
      //     gap: 5px;
      //     .clockIcon {
      //       position: relative;
      //       top: 1px;
      //     }
      //   }
      // }
    }
  }
  &.dark .kanbanBoard {
    background: $d-black1;
  }
}

@media (max-width: 450px) {
  .kanban {
    display: flex;
    flex-direction: column;
    .kanbanBoard {
      display: flex;
      flex-direction: column;
      max-height: 500px;
      .displayInterviews {
        height: 100% !important;
      }
    }
  }
}

@keyframes kanbanBoardAnim {
  0% {
    opacity: 0;
    transform: translateY(5px);
  }
  100% {
    opacity: 1;
    transform: translateY(0px);
  }
}
