.success-modal {
  .success-modal-text {
    margin: 20px;
    text-align: center;
    font: normal normal 600 25px/39px Open Sans;
    letter-spacing: 0px;
    color: #323338;
    opacity: 1;
  }
  .mondayButtonContainer{
    margin: auto;
  }
  &.darkHeader .success-modal-text{
    color: #fbfbfb;
  }
}
