.otpContainer {
  display: flex;
  flex-direction: column;
  gap: 30px;
  .ant-statistic-content-value {
    font-size: 18px;
  }
  .timeoutAlert {
    display: flex;
    justify-content: center;
    gap: 20px;
    width: 100%;
    font-size: 14px;
    color: #ea3943;
    .ant-statistic-content-value {
      font-size: 16px;
      color: #ea3943;
    }
  }
  .otpInput {
    display: flex;
    gap: 10px;
    .otpInputNum {
      height: 100px;
      div,
      input {
        font-size: 38px;
        text-align: center;
        width: 100%;
        height: 100%;
        border-radius: 22px;
      }
    }
    .ant-input-number {
      transition: all 100ms;
    }
    .ant-input-number:hover,
    .ant-input-number-focused {
      border-color: #1da193;
    }
  }
  .otpInput.success {
    .ant-input-number,
    .ant-input-number:hover,
    .ant-input-number-focused {
      border: 2px solid #11c3b1;
    }
  }
  .otpInput.fail {
    .ant-input-number,
    .ant-input-number:hover,
    .ant-input-number-focused {
      border: 2px solid #ea3943;
    }
  }
  .failedReceivingCode {
    display: flex;
    justify-content: center;
    gap: 20px;
  }
  .resendCodeLink {
    color: #2e5d9e;
    font-weight: 600;
    &:hover {
      cursor: pointer;
      color: #1b3d6e;
    }
  }
}

@media (max-width: 450px) {
  .otpContainer {
    .otpInput {
      .otpInputNum {
        height: 80px;
      }
    }
  }
}
