@import "../../../assets/globalVariables.scss";

.full-card {
  width: 100%;
  height: 100%;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 1px 4px #00000029;
  //winter
  // background: none;
  border-radius: 10px;
  opacity: 1;
  &.dark {
    color: $white;
    background: $d-black3;
    box-shadow: 0px 0px 5px #75eade;
  }
}
