@import "../../../../index.scss";

//* styles docCustomFields justify-content:center, align-items:center, flex-direction:column
.docCustomFields {
  @include flex(center, center, column);
  color: #323338;
  //* styles compTitle text font-size:18px, font-weight:600, letter-spacing:0, and text color
  .compTitle {
    @include text(18px, 600, 0, inherit);
    margin-bottom: 10px;
  }

  //* styles fieldOptionsContainer justify-content:center, align-items:flex-start, and flex-direction:column
  .fieldOptionsContainer {
    @include flex(center, flex-start, column);
    max-height: 435px;
    padding: 2px;
    overflow-y: auto;
  }

  .expirationDateField {
    margin-top: 40px;
  }
  &.dark {
    color: $white;
  }
}

//* responsive CustomFields for iPhone 12 Pro
@media (max-width: 391px) {
  .docCustomFields .expirationDateField {
    margin-top: 25px;
  }
  .docCustomFields {
    margin-top: -45px;
    margin-right: 110px;
  }
}
