@import "../../../../../../assets/globalVariables.scss";

.siderModal {
  .ant-modal-body {
    height: 600px;
    .uploadedFilesSection {
      display: flex;
      flex-direction: column;
      gap: 10px;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      height: 100%;
      overflow: auto;
      .fileCard {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        max-width: 275px;
        height: 47px;
        border-radius: 5px;
        background-color: #f1f1f5;
        .uploadedFile {
          display: flex;
          justify-content: space-between;
          align-items: center;
          width: 100%;
          padding: 10px;
        }
        .uploadedFileIcon {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 12px;
          height: 15px;
        }
        .uploadFileName {
          display: flex;
          align-items: center;
          width: 100%;
          // padding: 10px 0;
          cursor: pointer;
        }
        .fileName {
          font-family: Open Sans;
          font-size: 14px;
          font-weight: 400;
          line-height: 19px;
          letter-spacing: 0em;
          text-align: left;
          margin-left: 5px;
          max-width: 210px;
          overflow: hidden;
          text-wrap: nowrap;
        }
        .closeIconContainer {
          background-color: transparent;
          border: none;
          height: fit-content;
          width: fit-content;
        }
        .closeIcon {
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }
    }
  }
  &.dark .uploadedFilesSection .fileCard {
    background-color: $d-black2;
  }
}
.siderProblemsModal {
  width: fit-content;

  left: calc(50% + 420px) !important;
  right: 50% !important;
}

@media (max-width: 1450px) {
  .siderProblemsModal {
    left: auto !important;
    right: auto !important;
    width: 100%;
  }
}
