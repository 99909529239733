// @import "../../../../../index.scss";
@import "../../../assets/globalVariables.scss";
$mainDarkBackgroundColor: #20212d;

.NotFoundViewContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 5px;
  width: 100%;
  height: 100%;
  background: transparent;
  background-color: #fbfbfb;
  svg,
  img {
    width: 40%;
  }
  .title {
    font-weight: 550;
    font-size: 60px;
    text-align: center;
    letter-spacing: -3px;
    color: #1f2a44;
  }
  .subtitle {
    font-size: 18px;
    text-align: center;
    letter-spacing: 0px;
    color: #c3c3cd;
    opacity: 1;
  }
  &.dark {
    background: $d-black2;
    .title {
      color: $grey-2;
    }
  }
}
@media (max-width: 450px) {
  .NotFoundViewContainer {
    svg,
    img {
      width: 100%;
    }
    .title {
      font-size: 40px;
    }
    .subtitle {
      font-size: 16px;
      text-align: center;
      letter-spacing: 0px;
      color: #c3c3cd;
      opacity: 1;
    }
  }
}
