.new-announcemenet-modal {
  .ant-modal-content {
    .new-announcemenet-content {
      display: flex;
      flex-direction: column;
      gap: 15px;
    }
    .newAnnouncementDropdowns {
      // background-color: red;
      display: flex;
      justify-content: space-between;
      gap: 10px;
      .formItem {
        width: 100%;
      }
    }
    .ant-select-selector {
      border-radius: 5px;
      background: #f5f5f7;
      border: none;
    }
    .ant-input {
      border-radius: 5px;
      background: #f5f5f7;
    }
  }
}

@media (max-width: 850px) {
  .new-announcemenet-modal {
    .ant-modal-content {
      .newAnnouncementDropdowns {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
      }
    }
  }
}
@media (max-width: 450px) {
  .new-announcemenet-modal {
    .ant-modal-content {
      .newAnnouncementDropdowns {
        display: grid;
        grid-template-columns: 1fr;
      }
    }
  }
}
