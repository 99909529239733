.ant-modal.global-filter-modal {
  width: fit-content !important;
  .ant-modal-content {
    border-radius: 10px;
    height: 100%;
    width: 700px !important;
    .ant-modal-body {
      .ant-row {
        display: grid;
        grid-template-columns: 3fr 1fr;
        gap: 15px;
        margin: 0 !important;
        .ant-col {
          display: grid;
          grid-template-columns: 1fr 1fr;
          gap: 15px;
          padding: 0 !important;
          flex: 1;
          max-width: 100%;
          height: max-content;
          .filter-dropdown {
            width: 100%;
          }
        }
        .weekFilters {
          display: flex;
          flex-direction: column;
          button {
            width: 100%;
            margin-bottom: 5px;
          }
        }
      }
    }
  }
}
@media (max-width: 450px) {
  .ant-modal.global-filter-modal {
    width: 100% !important;
    .ant-modal-content {
      width: 100% !important;
      .ant-modal-body {
        .ant-row {
          display: flex;
          flex-direction: column;
          .ant-col{
            grid-template-columns: 1fr;
          }
          .filterItem{
            display: grid;
            grid-template-columns: minmax(0, 2fr) minmax(0, 3fr);
            align-items: center;
            gap: 15px;
            h3{
              text-align: right;
              margin: 0;
            }
          }
        }
      }
    }
  }
}
