@import "../../../../../../assets/globalVariables.scss";

.edit-shift-wrapper {
  .warning-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font: normal normal normal 14px/19px Open Sans;
    letter-spacing: 0px;
    color: #ea3943;
  }
  .to-edit-fields {
    margin-top: 20px;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    gap: 20px;
    width: 100%;
    .shift-field-start {
      display: flex;
      flex-direction: column;
      width: 100%;
      label {
        margin-right: 10px;
        margin-left: 5px;
      }
    }
    .shift-field-end {
      display: flex;
      flex-direction: column;
      width: 100%;
      label {
        margin-right: 10px;
        margin-left: 5px;
      }
    }
  }
  .to-note {
    margin-top: 30px;
    display: flex;
    flex-direction: column;
    margin-left: 0px;
    margin-bottom: 20px;
    // justify-content: center;

    textarea.ant-input.to-note-textarea {
      margin-left: 0px;
      width: 630px;
      height: 81px;
      /* UI Properties */
      background-color: #f1f7fb;
      border-radius: 5px;
      border: none;
      opacity: 1;
    }
  }

  .hours-generation {
    display: flex;
    // align-items: center;
    margin-left: 0px;
    margin-top: 20px;
    flex-direction: column;
    strong {
      align-items: center;
    }
    .animated-hours {
      // margin-left: 10px;

      width: 708px;
      flex: 1;
      background-color: #f1f7fb;
      border-radius: 5px;
      height: 32px;
      .calculated-hours {
        background-color: #1da193 !important;
        // background: linear-gradient(90deg, hsla(175, 79%, 63%, 1) 0%, hsla(82, 96%, 57%, 1) 100%);
        border-radius: 5px;
        height: 32px;
        animation: chartAnimation 1.9s;
      }
    }
  }
  .status-wrapper {
    display: flex;
    align-items: center;
    margin-top: 10px;
    strong {
      align-items: center;
    }
  }
  &.dark {
    color: $white;
    .warning-title {
      color: #f0747b;
      svg {
        fill: currentColor;
      }
    }
    .animated-hours {
      background-color: transparent;
      // border: 1px solid $d-black1;
      box-shadow: 0px 0px 2px $white;
    }
  }
}

@keyframes chartAnimation {
  from {
    width: 0;
  }
  to {
    width: 1;
  }
}

@media (max-width: 450px) {
  .ant-modal.change-shift {
    .edit-shift-wrapper .warning-title {
      margin-left: 0px !important;
      width: 100% !important;
    }
    .edit-shift-wrapper .to-edit-fields {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 10px;
      column-gap: 30px;
      align-items: end;
      .mondayButtonContainer {
        width: 100%;
        button {
          width: inherit;
        }
      }
    }
    .ant-modal-body {
      height: 100% !important;
    }
    .edit-shift-wrapper .hours-generation .animated-hours {
      width: 100% !important;
    }
  }
}
