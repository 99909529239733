@import "../../../../index.scss";
@import "../CategoryCard//CategoryCard.scss";
@import "../../../../assets/globalVariables.scss";

//* docConfigNewCategoryPanel justify-content:flex-start, align-items:center, and flex-direction:column
.docConfigNewCategoryPanel {
  @include flex(flex-start, center, column);

  .formCard {
    margin: 0;
  }

  .previewCard {
    .categoryTitle {
      font-size: 12px;
    }
    .mondayButtonText {
      font-size: 11px;
    }
  }

  //* styles cardContainer justify-content:center, and align-items:center
  .cardsContainer {
    @include flex(center, center);
  }

  //* styles panelTitle text font-size:16px, font-weight:bold, letter-spacing:0, and text color
  .panelTitle {
    @include text(16px, bold, 0, #323338);
    margin-bottom: 10px;
  }

  //* styles fieldsContainer justify-content:center, align-items:center, and flex-direction:column
  .fieldsContainer {
    @include flex(center, center, column);
    gap: 10px;
  }

  .newCategoryCard {
    @extend .docCategoryCard;
  }

  .previewIcon {
    font-size: 18px;
    margin: 25px;
  }

  .createButton {
    @include mondayButton(#71cf48, #68c142);

    .mondayButtonIcon {
      width: 18px;
      height: 18px;
    }
  }
  &.dark {
    .panelTitle {
      color: $white;
    }
    .newCategoryCard {
      background: $d-black2;
    }
  }
}

//* responsive NewCategoryPanel for Pc monitor
@media (max-width: 1025px) {
  .docConfigNewCategoryPanel .panelTitle {
    font-size: 20px;
  }
  .docConfigNewCategoryPanel .previewCard .categoryTitle {
    font-size: 16px;
  }
  .docConfigNewCategoryPanel .previewCard .mondayButtonText {
    font-size: 14px;
  }
}

//* responsive NewCategoryPanel for iPhone 12 Pro
@media (max-width: 391px) {
  .docConfigNewCategoryPanel {
    margin-top: 30px;
  }
  .docConfigNewCategoryPanel .panelTitle {
    font-size: 14px;
  }
  .docConfigNewCategoryPanel .cardsContainer {
    display: flex;
    flex-direction: column;
  }
  .docConfigNewCategoryPanel .formCard {
    margin: 0px -10px -13px -13px;
  }
  .docConfigNewCategoryPanel .fieldsContainer .ant-select-selector {
    min-width: 100px;
  }
  button.ant-btn.ant-btn-default.mondayButtonStyle.createButton {
    width: 120px;
  }
  button.ant-btn.ant-btn-default.mondayButtonStyle.configuration {
    width: 150px;
  }
  .docConfigNewCategoryPanel .previewCard .categoryTitle {
    font-size: 14px;
  }
}
