@import "https://fonts.googleapis.com/css?family=Roboto";

.agComponentWrapper {
  display: flex;
  flex-direction: column;
  gap: 10px;
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.3);
  overflow: hidden !important;
  border-radius: 10px;
  .agGridComponent {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    overflow: hidden !important;
    border-radius: 10px;
  }
  .customFooter {
    position: relative;
    display: flex;
    gap: 20px;
    margin: -48px 0 0 20px;
    background-color: transparent;
    width: max-content;
  }
  &.dark {
    box-shadow: 0px 0px 5px #4b87b4;
  }
}
.mobileAgComponentWrapper {
  flex: 1 1;
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
  height: 100%;
  background: transparent;
  overflow: hidden;
}
.mobileAgCollapse {
  width: 100%;
  .ant-collapse-header {
    color: unset !important;
    outline: none;
  }
  .ant-collapse-content {
    background: none;
    border: none;
  }
}
@media (max-width: 450px) {
  .mobileAgComponentWrapper {
    gap: 5px;
  }
}
