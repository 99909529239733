@import "../../../../../assets/globalVariables.scss";

.createCandidateModal {
  // .ant-form{
  //   height: 100%;
  // }
  .generalInfo {
    display: grid;
    grid-template-columns: 2fr 1fr;
    gap: 15px;
    height: 100%;
    flex: 1;
    .personalInfo {
      display: flex;
      flex-direction: column;
      height: 100%;
      max-height: 490px;
      overflow-y: auto;
      padding: 3px;
      .gridInfos {
        display: grid;
        grid-template-columns: repeat(3, minmax(0, 1fr));
        gap: 10px;
        .ant-form-item {
          .ant-picker,
          input.form-control {
            width: 100%;
          }
          .react-tel-input {
            width: 100%;
          }
        }
      }
      .textareaInfos {
        display: grid;
        grid-template-columns: 1fr;
        gap: 15px;
        flex: 1;
        .ant-form-item {
          .custom-quill {
            display: flex;
            flex-direction: column;
            .radio-group {
              margin: -20px 0 5px 0;
              text-align: end;
            }
            .notAllowed {
              cursor: not-allowed;
              .ql-container {
                .ql-editor {
                  opacity: 0;
                }
              }
            }
            .quill-editor {
              height: 140px;
            }
          }
        }
      }
    }
    .sidebarInfos {
      .ant-form-item {
        .ant-picker {
          width: 100%;
        }
      }
    }
    &.dark {
      .gridInfos .ant-form-item .react-tel-input {
        .form-control {
          color: $white;
        }
      }
    }
  }
}
// @media (max-width: 1200px) {
//   .createCandidateModal {
//     .generalInfo {
//       grid-template-columns: 6fr 2fr;
//       .gridInfos {
//         grid-template-columns: repeat(3, minmax(0, 1fr)) !important;
//       }
//     }
//   }
// }
@media (max-width: 450px) {
  .createCandidateModal {
    .generalInfo {
      grid-template-columns: 1fr;
      .gridInfos {
        grid-template-columns: 1fr !important;
      }
    }
  }
}
