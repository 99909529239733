.actions-ded-add {
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
}

@media (max-width: 450px) {
  .actions-ded-add {
    .actions-wrapper {
      .action-btns {
        flex-direction: column;
        gap: 10px;
      }
      .ant-card-body {
        display: grid;
        grid-template-columns: 1fr 1fr;
        .ant-card-grid {
          width: 100% !important;
        }
      }
    }
  }
}
