.ant-popover.ant-popconfirm.global-popup.ant-popover-placement-top {
  .ant-popover-inner {
    border-radius: 10px !important;
  }
  button.ant-btn.ant-btn-sm {
    width: 50px;
    height: 30px;
    background: #ea3943;
    color: #fff;
    border: none;
  }
  button.ant-btn.ant-btn-primary.ant-btn-sm {
    margin-left: 5px;
    background: #1da193;
    border: none;
  }
  .ant-popover-message {
    display: flex;
    margin-left: auto;
    margin-right: auto;
  }
  .ant-popover-message-title {
    padding-left: 5px !important;
  }
  .ant-popover-inner-content {
    padding: 7px 10px !important;
  }
}

.ant-popover.ant-popconfirm.global-popup.ant-popover-placement-bottom {
  .ant-popover-inner {
    border-radius: 10px !important;
  }
  button.ant-btn.ant-btn-sm {
    width: 50px;
    height: 30px;
    background: #ea3943;
    color: #fff;
    border: none;
  }
  button.ant-btn.ant-btn-primary.ant-btn-sm {
    background: #1da193;
    border: none;
  }
  .ant-popover-message {
    display: flex;
    margin-left: auto;
    margin-right: auto;
  }
  .ant-popover-message-title {
    padding-left: 5px !important;
  }
  .ant-popover-inner-content {
    padding: 7px 10px !important;
  }
}
