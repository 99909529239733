.drawerFilterContainer {
  display: flex;
  gap: 10px;
  .filterSelectors {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    column-gap: 10px;
    width: 100%;
  }
}
