@import "../../../../assets/globalVariables.scss";

.tag-modal {
  width: calc(100vw - 20px) !important;
  max-width: 700px !important;
  .tag-modal-container {
    height: calc(100vh - 5px);
    max-height: 375px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    .modal-header {
      width: calc(100% - 10px);
      display: flex;
      gap: 10px;
      align-content: center;
      p {
        margin: 0;
        span {
          font-weight: 700;
        }
      }
    }
    .modal-content {
      display: flex;
      flex-direction: column;
      gap: 10px;
      .input-container {
        display: flex;
        gap: 10px;
        .custom-add-button {
          border: none;
          border-radius: 5px;
          background-color: #f8f8fa;
        }
        .input-left-padding {
          padding-left: 10px;
        }
      }
      .tags-container {
        display: flex;
        flex-flow: wrap;
        max-height: 250px;
        overflow-y: scroll;
        padding: 5px;
        gap: 9px 5px;
        .ant-tag {
          border: none;
          border-radius: 30px;
          padding: 5px 0 5px 20px;
          background-color: #f5f5f7;
          font-weight: 600;
          .anticon {
            position: relative;
            top: -10px;
            left: 5px;
            background-color: #ea3943;
            border-radius: 50%;
            padding: 4px;
            svg {
              fill: #fff;
            }
          }
        }
      }
    }
  }

  .ant-modal-footer {
    border: none;
    padding: 20px 29px;
    .flex-between {
      display: flex;
      justify-content: space-between;
    }
    .ant-btn {
      border-radius: 5px;
    }
    .custom-confirm-button {
      background-color: #20ac9d;
      border: none;
      color: #ffffff;
    }
    .custom-confirm-button:hover {
      background-color: #20ac9ebd;
    }
  }
  &.dark {
    .tag-modal-container .modal-content .tags-container .ant-tag {
      background: none;
      border: 1px solid $white;
      color: $white;
    }
  }
}
