.interviewtodayContainer {
  display: flex;
  flex-direction: column;
  gap: 10px;
  height: 100%;
  width: 100%;
  .interviewTodayHeaderContainer {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    justify-items: center;
    align-items: center;
    gap: 10px;
    font-weight: 600;
    font-size: 15px;
    background-color: #f1f1f5;
    height: 32px;
  }
  .interview-container {
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 100%;
    flex: 1 1 0;
    font: normal normal normal 14px/19px "Open Sans";
    letter-spacing: 0px;
    color: #323338;
    .ant-row {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr 1fr;
      gap: 10px;
      margin: 0 !important;
      width: 100%;
      .ant-col {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0 !important;
        span {
          text-align: center;
        }
      }
    }
  }
}
