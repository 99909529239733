.filter-item-wrapper {
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;

  .filter-item-drawer {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 15px;
  }
  .action-btns {
    display: flex;
    justify-content: flex-end;
    margin-right: 30px;
    gap: 20px;
    .add-filter-btn {
      border: none;
      width: 100px;
      border-radius: 5px;
      color: white;
      height: 32px;

      background: #1da193 0% 0% no-repeat padding-box;
      &:hover {
        opacity: 0.8;
        cursor: pointer;
      }
    }
    .clear-filter-btn {
      border: none;
      width: 130px;

      border-radius: 5px;
      color: white;
      height: 32px;

      background: #ea3943 0% 0% no-repeat padding-box;
      &:hover {
        opacity: 0.8;
        cursor: pointer;
      }
    }
    .generate-btn {
      height: 32px;
      width: 100px;

      border: none;
      border-radius: 5px;
      color: white;
      background: #239281 0% 0% no-repeat padding-box;
      &:hover {
        opacity: 0.8;
        cursor: pointer;
      }
    }
  }
}
