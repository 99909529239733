@import "../../../assets/globalVariables.scss";

.konfigurimet-menu {
  z-index: 1;
  max-width: 270px;
  height: 100%;
  .HideSearch {
    display: none;
  }
  path#Path_6858_00000086666139294581268680000013212754614146113725_ {
    fill: #1d3445;
  }
  path#Path_8192 {
    fill: #fff;
  }
  path#Path_8191 {
    fill: #fff;
  }
  circle#Ellipse_228 {
    fill: #fff;
  }
  path#Path_8190 {
    fill: #fff;
  }
  .konfig-menu-container {
    justify-content: center;
    align-items: center;
    max-width: 270px;
    height: 100%;
    background: #1d3445;
    //comment on winter ^^
  }
  .konfig-search-input {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 5px;
    width: 250px;
    height: 40px;
    padding: 0 10px;
    border-radius: 5px;
    margin: 10px auto;
    background: #fbfbfb;
    input {
      background: none;
    }
  }
  .menu-header {
    width: 270px;
    height: 160px;
    align-items: center;
    justify-content: center;
    text-align: center;
    display: grid;
    svg#Layer_1 {
      margin-left: 50px;
      margin-top: 30px;
    }
    h1 {
      font-size: 22px;
      color: #fff;
    }
  }
  &.dark {
    .konfig-search-input {
      color: $white;
      background: none;
      box-shadow: 0px 0px 2px $white;
      input {
        box-shadow: none;
      }
    }
    .konfig-menu-container {
      background: none;
      .subMenu-konfig {
        &:hover {
          background-color: $d-black2;
        }
        &.ant-menu-submenu-open.ant-menu-submenu-active {
          background-color: $d-black2;
        }
        &.ant-menu-submenu-selected {
          background-color: $d-black2;
        }
      }
      li.ant-menu-item.ant-menu-item-selected.subMenu-konfig {
        background-color: $d-black2 !important;
      }
    }
  }
}
.ant-menu-submenu-popup {
  .subMenu-konfig {
    background-color: #1d3445;
  }
}

// li.ant-menu-item.ant-menu-item-active.ant-menu-item-selected.subMenu-konfig {
//   background: #1d3943 !important;
// }

aside.ant-layout-sider.ant-layout-sider-dark.ant-layout-sider-collapsed.ant-layout-sider-has-trigger {
  .konfig-search-input {
    display: none;
  }
  .menu-header {
    width: 80px;
    height: 160px;
    justify-content: center;
    align-items: center;
    display: flex;
    margin: 0px;
    h1 {
      display: none;
    }
    .konfigurimet-menu .menu-header .kofig-icon {
      margin-right: 10px;
      justify-content: center;
      align-items: center;
      display: flex;
    }
    .konfig-menu-container {
      width: 80px;
    }
  }
}

li.ant-menu-item.ant-menu-item-only-child.subMenu-konfig {
  color: #fff !important;
  margin: 0;
  height: 50px;
  align-items: center;
  display: flex;
  width: 100%;
  border-radius: 0;
  a {
    color: #fff !important;
  }
}
li.ant-menu-item.ant-menu-item-only-child.subMenu-konfig:hover {
  color: #fff !important;
  // background: #1d3445 !important;
  margin: 0;
  a {
    color: #fff !important;
  }
}

.ant-menu-light .ant-menu:not(.ant-menu-inline) {
  // background: #1d3445 !important;
  margin-left: -4px;
  border-bottom-right-radius: 10px;
  border-top-right-radius: 10px;
  box-shadow: 0px 2px 9px #e6e6e6;
}

.Menu-config {
  // background: #1d3445;
  //winter
  // background: #1d3445;
  border: none;
  li.ant-menu-submenu.ant-menu-submenu-vertical.ant-menu-submenu-active:hover {
    // background: #1d3445 !important;
  }
  span.ant-menu-title-content {
    color: #fff !important;
    font-size: large;
    font-weight: 400;
  }
}

li.ant-menu-submenu.ant-menu-submenu-vertical.subMenu-konfig {
  height: 50px;
  .ant-menu-submenu-title {
    align-items: center;
    display: flex;
    height: 50px;
  }
}
i.ant-menu-submenu-arrow {
  color: #fff !important;
}

.ant-menu-submenu-inline
  > .ant-menu-submenu-title
  .ant-menu-submenu-arrow::after,
.ant-menu-submenu-vertical-left
  > .ant-menu-submenu-title
  .ant-menu-submenu-arrow::after,
.ant-menu-submenu-vertical-right
  > .ant-menu-submenu-title
  .ant-menu-submenu-arrow::after,
.ant-menu-submenu-vertical
  > .ant-menu-submenu-title
  .ant-menu-submenu-arrow::after {
  content: NONE;
}

.ant-menu-submenu > .ant-menu-submenu-title .ant-menu-submenu-arrow::before {
  width: 0;
  height: 0;
}

.ant-menu-inline .ant-menu-item,
.ant-menu-inline .ant-menu-submenu-title,
.ant-menu-vertical-left .ant-menu-item,
.ant-menu-vertical-left .ant-menu-submenu-title,
.ant-menu-vertical-right .ant-menu-item,
.ant-menu-vertical-right .ant-menu-submenu-title,
.ant-menu-vertical .ant-menu-item,
.ant-menu-vertical .ant-menu-submenu-title {
  margin-top: 2px;
  margin-bottom: 2px;
}

li.ant-menu-submenu.ant-menu-submenu-vertical.subMenu-konfig.ant-menu-submenu-selected {
  background: #1d3445;
  i.ant-menu-submenu-arrow {
    width: 0;
    height: 0;
    margin-right: -16px;
    border-top: 12px solid transparent !important;
    border-right: 13px solid #f6f6f6 !important;
    border-bottom: 12px solid transparent !important;
  }
}

.ant-menu-submenu.ant-menu-submenu-vertical.subMenu-konfig.ant-menu-submenu-open.ant-menu-submenu-active {
  background: #1d3445;
  i.ant-menu-submenu-arrow {
    width: 0;
    height: 0;
    margin-right: -16px;
    border-top: 12px solid transparent !important;
    border-right: 13px solid #f6f6f6 !important;
    border-bottom: 12px solid transparent !important;
  }
}

.ktheu-btn-div {
  width: 100%;
  justify-content: center;
  display: flex;
  align-items: center;
  z-index: 1000;
  button.ant-btn.ktheu-btn {
    border-radius: 5px;
    justify-content: center;
    color: #1d3445;
    font-size: small;
    font-weight: 500;
    align-items: center;
    width: 70px;
  }
  .icon {
    background: #f6f6f6;
    width: 40px;
    height: 40px;
    border-radius: 5px;
    padding: 5px;
    z-index: 3;
    fill: #1d3445;
  }
}

.subMenu-konfig:hover {
  background: #1d3445;
}

li.ant-menu-item.searching-menu {
  color: #fff !important;
  span.ant-menu-title-content {
    color: #fff !important;
    font-size: medium;
  }
  span.ant-menu-title-content a {
    color: #fff !important;
    font-size: medium;
  }
  path#Path_6858_00000086666139294581268680000013212754614146113725_ {
    fill: #fff !important;
  }
}

li.ant-menu-item.searching-menu:hover {
  background: #1d3445 !important;
}

// .ant-menu-item-active.ant-menu-item-selected.subMenu-konfig {
//   background: #1d3445 !important;
// }

li.ant-menu-item.ant-menu-item-selected.subMenu-konfig {
  background: #1d3445 !important;
}

// .ant-menu-inline .ant-menu-item:not(:last-child),
// .ant-menu-vertical-left .ant-menu-item:not(:last-child),
// .ant-menu-vertical-right .ant-menu-item:not(:last-child),
// .ant-menu-vertical .ant-menu-item:not(:last-child) {
//   margin: 2px 0;
// }
.ant-menu-inline .ant-menu-item,
.ant-menu-vertical-left .ant-menu-item,
.ant-menu-vertical-right .ant-menu-item,
.ant-menu-vertical .ant-menu-item {
  border-radius: 0;
  margin: 3px 0;
}
