.menaxhimi-orarit {
  padding-top: 10px;
  height: 100%;
  width: 100%;
  path#Path_7433 {
    fill: #323338;
  }
}

button.ant-btn.krijo-orar-ri-btn {
  background: #1da193;
  color: #fff;
  border-radius: 5px;
  margin-right: 30px;
}

.column-paisjet-orarit {
  display: flex;
  justify-content: center;
  align-items: center;
  .icon-ndrysho {
    margin-top: 4px;
    width: 32px;
    height: 32px;
    background: #1da193;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    cursor: pointer;
  }
}
// modal scss
.ant-modal.paisjet-orarit-modal {
  .paisje-orarit-content {
    display: flex;
  }
  .logo-container {
    width: 180px;
    height: 200px;
    justify-content: center;
    align-items: center;
    display: flex;
  }
  .grid-container {
    margin-left: 40px;
    display: block;
    width: 100%;
  }
  textarea {
    resize: none;
  }
  .ant-row.ant-form-item {
    margin: 0;
  }
}

.quill.note_input.quillTextEditor.paisjet-orarit-quill {
  height: 170px;
  border-radius: 5px !important;
  background-color: #f1f7fb;
  .ql-container.ql-snow {
    border: none !important;
  }
  .ql-toolbar.ql-snow {
    border-top: none !important;
    border-left: none !important;
    border-right: none !important;
    border-bottom: 1px solid #fff;
  }
}

.ant-picker.konfigurimet-shift-picker {
  .ant-picker-content {
    width: 200px !important;
  }
}

.sistemi-paisjet-orarit {
  width: 100%;
  height: 100%;
}

//modal
.ant-modal.paisjet-per-orarin-modal {
  .ant-modal-content {
    .ant-form-item-control-input-content {
      display: flex;
      justify-content: center;
    }
  }
}

.iconat-paisjet {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 4px;
  .icon-delete {
    background: #ea3943;
    width: 32px;
    height: 32px;
    justify-content: center;
    align-items: center;
    display: flex;
    border-radius: 5px;
    align-self: center;
    cursor: pointer;
    margin-left: 15px;
    // margin-top: 4px;
    path#Path_7433 {
      fill: #fff;
    }
  }
  .icon-edit {
    background: #1da193;
    cursor: pointer;
    width: 32px;
    height: 32px;
    justify-content: center;
    align-items: center;
    display: flex;
    border-radius: 5px;
    align-self: center;
    margin-left: 15px;
    // margin-top: 4px;
    path#Path_7431_00000060022719262200020110000009623688766172120195_ {
      fill: #fff;
    }
  }
}

.sistemi-paisjet-orarit {
  .Print-Punojsit {
    visibility: collapse;
  }
}
