@import "./assets/globalVariables.scss";
/* global css for scroller */
/* width */
.app {
  overflow: hidden !important;
}
.App.dark {
  .ant-layout-content {
    color: $white;
    background: $d-black2;
  }
}

::-webkit-scrollbar {
  width: 10px;
  height: 10px;
  cursor: pointer !important;
}

/* Track */
::-webkit-scrollbar-track {
  background: transparent;
  cursor: pointer;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #1d3445;
  border-radius: 5px;
  cursor: pointer;
}
::-webkit-scrollbar-thumb:hover {
  background-color: #35415e;
}

/* background of main page  */
.ant-layout-content {
  background: #f1f7fb;
  //winter
  // background-image: url("https://i.ibb.co/ZVh0JVn/Beige-and-Green-Watercolor-Happy-Holidays-Merry-Christmas-Card-2.png");
  // background-size: cover;
  // background-position: top;
}
.loading-spinner-global {
  display: flex;
  justify-content: center;
  align-items: center;
  /* background-color: lightgray; */
  height: 100%;
}

/* .ant-layout-content {
  background: #f1f7fb;
  height: 100%;
} */
//  .ant-layout{
// 	height: 100dvh !important;
// 	 .ant-layout-content {
// 		display: flex;
// 		flex-direction: column;
// 		width: 100%;
// 		 height: 100vh !important;
// 		}
// 	}

.ant-layout.ant-layout-has-sider {
  overflow: hidden;
  height: 100dvh !important;
}

// global css for mobile hide scroll
@media (max-width: 450px) {
  .App {
    width: 100% !important;
    height: 100vh !important;
    overflow: hidden;
  }
  .ant-layout {
    width: 100% !important;
    height: 100vh !important;
    overflow: hidden;
  }
  .ant-layout.ant-layout-has-sider {
    width: 100% !important;
    height: 100vh !important;
    overflow: hidden;
  }
  aside.ant-layout-sider.ant-layout-sider-dark.ant-layout-sider-collapsed.ant-layout-sider-has-trigger.ant-layout-sider-below {
    height: 100vh !important;
    display: none !important;
  }
  .idleModal {
    width: 95% !important;
  }
  .idleModal .idleText {
    padding-left: 10px;
    padding-right: 10px;
  }
  .idleModal .logoIdle {
    width: 100% !important;
  }
  .idleModal .confirmPin {
    top: 150px !important;
  }
}

@media (max-width: 390px) {
  .App {
    width: 100% !important;
    height: 100vh !important;
    overflow: hidden;
  }
  .ant-layout {
    width: 100% !important;
    height: 100vh !important;
    overflow: hidden;
  }
  .ant-layout.ant-layout-has-sider {
    width: 100% !important;
    height: 100vh !important;
    overflow: hidden;
  }
  aside.ant-layout-sider.ant-layout-sider-dark.ant-layout-sider-collapsed.ant-layout-sider-has-trigger.ant-layout-sider-below {
    height: 100vh !important;
  }
  .ant-layout-content {
    height: 100vh !important;
  }
}
