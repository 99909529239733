@import "../../../../index.scss";

.newCustomFieldModal {
  $background: #f5f5f7;

  .closeIcon {
    position: absolute;
    top: 0;
    right: 0;
    padding: 15px;
    color: #323338;

    &:hover {
      color: #232425;
    }
  }

  .mockContent {
    width: 600px;
  }

  //* styles checkboxTitle text font-size:16px, font-weight:400, letter-spacing:0, and text color
  .checkboxTitle {
    @include text(16px, 400, 0, #323338);
  }

  //* styles buttonsContainer justify-content:center, and align-items:center
  .buttonsContainer {
    width: 100%;
    @include flex(center, space-between);
  }

  //* styles modalBodyContainer justify-content:center, align-items:center, and flex-direction:column
  .modalBodyContainer {
    @include flex(center, center, column);
    padding: 0 100px;
    gap: 10px;
  }

  //* styles checkboxesContainer justify-content:center, and align-items:center
  .checkboxesContainer {
    @include flex(center, center);
  }

  //* styles coreInputsContainer justify-content:flex-end, align-items:center, and flex-direction:column
  .coreInputsContainer {
    @include flex(flex-end, center, column);

    .labeledInputLabel {
      margin-right: 15px;
      width: max-content;
    }

    //* styles labeledInputContainer justify-content:baseline, and align-items:flex-end
    .labeledInputContainer {
      @include flex(baseline, flex-end);
    }
    .ant-form-item {
      min-width: 200px;
    }

    .ant-input {
      border: 0 !important;
      border-radius: 5px !important;
      margin-bottom: 10px;
    }

    .ant-select-selector {
      @extend .ant-input;
    }

    .ant-input-affix-wrapper,
    .ant-select-selector,
    .ant-picker,
    .ant-input {
      border: 0;
      border-radius: 5px;
      background-color: $background;
    }
  }
}

//* responsive NewCustomFieldModal for monitor Pc
@media (max-width: 1025px) {
  .newCustomFieldModal .coreInputsContainer .labeledInputLabel {
    font-size: 18px;
  }
}

//* responsive NewCustomFieldModal for iPhone 12 Pro
@media (max-width: 391px) {
  .newCustomFieldModal .coreInputsContainer .labeledInputLabel {
    font-size: 16px;
  }
  input#customFieldsLabel {
    width: 185px;
  }
}
