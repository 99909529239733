@import "../../../../../../../index.scss";

//* styles docViewActionButtons align-items:center, justify-content:space-evenly
.docViewActionButtons {
  width: 100%;
  @include flex(center, space-evenly);

//* styles leftButtonSection align-items:center, justify-content:center
  .leftButtonSection {
    @include flex(center, center);
  }

  .deleteButton {
    @include mondayButton(#fe4c4a, #eb4345);
    .mondayButtonIcon {
      width: 23px;
      filter: brightness(10);
    }
  }

  .logsButton {
    @include mondayButton(#1264a3, #0f5c97);
    .mondayButtonIcon {
      width: 18px;
    }
  }

  .cancelButton {
    @include mondayButton(#f5f5f7, #e6e9ef, #323338);
  }

  .saveButton {
    @include mondayButton(#71cf48, #68c142);
  }
}

.noEditing {
  justify-content: flex-end;
  .cancelButto {
    margin-right: 20px;
  }
}
