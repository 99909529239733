@import "../../../../../../index.scss";

//* styles transferLabelContainer justify-content:center, and align-items:flex-start
.transferLabelContainer {
  @include flex(center, flex-start);
  min-width: 200px;
  height: 35px;
  min-height: 35px;
  background-color: #eceff4;
  margin-bottom: 5px;
  cursor: pointer;

  //* styles marker justify-content;center, and align-items:center,and text margin-right:5px, height:100%, border-radius:0, and background-color
  .marker {
    @include flex(center, center);
    @include square(5px, 100%, 0, #fe4c4a);
    margin-right: 5px;
    transition: width 0.2s ease-in-out;
    overflow: hidden;
  }

  //* styles innerMarker border-radius:0px, height:0px, width:0, and-background-color
  .innerMarker {
    @include square(0px, 0px, 0, #eb4345);
    transition: width 0.2s ease-in-out;
  }

  //* styles titleContainer justify-content:center, align-items:center,and text font-size:14px, font-weight:600, letter-spacing:0, and text color
  .titleContainer {
    @include flex(center, flex-start);
    // @include text(14px, 600, 0, #323338);

    flex: 1;
    align-self: stretch;
  }

  .transferred {
    background-color: #71cf48;

    .innerMarker {
      background-color: #68c142;
    }
  }

  .checked {
    width: 35px;
  }

  &:hover {
    .marker {
      width: 35px;
    }

    .innerMarker {
      width: 16px;
      height: 16px;
    }
  }
  &.dark {
    color: $white;
    background: $d-black5;
  }
}

.disabledTransferLabel {
  pointer-events: none;
}

//* responsive TransferLabel for Pc monitor
@media (max-width: 1025px) {
  .transferLabelContainer .titleContainer {
    font-size: 16px;
  }
}

//* responsive TransferLabel for iPhone 12 Pro
@media (max-width: 391px) {
  .transferLabelContainer .titleContainer {
    font-size: 14px;
  }
}
