.emailTemplateModal {
  width: calc(100vw - 20px) !important;
  width: calc(100dvw - 20px) !important;
  height: calc(100vh - 20px) !important;
  height: calc(100dvh - 20px) !important;
  max-width: 810px !important;
  max-height: 700px !important;
  .ant-modal-content {
    // height: calc(100vh - 20px) !important;
    // height: calc(100dvh - 20px) !important;
    max-height: 700px;
    .ant-modal-close {
      height: 35px;
      width: 35px;
      margin-right: 12px;
      .ant-modal-close-x {
        height: 35px;
        width: 35px;
        display: flex;
        align-items: center;
        justify-content: center;
        svg {
          fill: #323338;
        }
      }
    }
    .ant-modal-header {
      height: 35px;
      display: flex;
      align-items: center;
      background-color: #e7eef2;
      border-radius: 10px 10px 0px 0px;
      .ant-modal-title {
        color: #323338;
        font-size: 16px;
        font-weight: 600;
      }
    }
    .ant-modal-body {
      height: calc(100vh - 97px);
      max-height: 600px;
      // overflow-x: hidden;
      // overflow-y: auto;
      .selectUserFormItem {
        width: 100%;
      }
      .ant-form-item {
        margin-bottom: 15px;
      }
      .ant-select:not(.ant-select-customize-input) .ant-select-selector {
        border: none;
        background-color: #f1f1f5 !important;
        border-radius: 5px;
        margin: 0 !important;
        height: 32px !important;
      }
      .ant-select:not(.ant-select-customize-input) .ant-select-selector {
        background-color: #f1f1f5;
      }

      .textAreaFormItem {
        width: 100% !important;
        height: calc(100% - 148px);

        min-height: 200px;
        .ant-form-item-row {
          height: 100%;
          .ant-col {
            height: 100%;
            .ant-form-item-control-input {
              height: 100%;
              .ant-form-item-control-input-content {
                height: 100%;
              }
            }
          }
          .lajmerime-quill {
            height: 100%;
            border-radius: 5px !important;
            background-color: #f1f1f5;

            .ql-container.ql-snow {
              border: none !important;
              .ql-tooltip {
                box-shadow: none !important;
                border-radius: 5px;
                border: 1px solid black;
              }
            }
            .ql-disabled {
              .ql-editor {
                > * {
                  cursor: not-allowed !important;
                }
              }
            }
            .ql-toolbar.ql-snow {
              border-top: none !important;
              border-left: none !important;
              border-right: none !important;
              border-bottom: 1px solid #fff;
              width: 100%;
              .ql-image {
                display: inline-block !important;
              }
              input.ql-image {
                display: none !important;
              }
            }
            .ql-editor {
              height: calc(100% - 64px);
            }
            .ql-editor > p > img {
              width: fit-content !important;
              height: 100% !important;
            }
          }
        }
      }
    }
    .ant-modal-footer {
      height: 65px;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .send-request {
        width: 150px;
        height: 32px;
        background-color: #1da193;
        color: #fbfbfb;
        border-radius: 5px;
        font-family: Open Sans;
        font-size: 14px;
        font-weight: 400;
        line-height: 19px;
        letter-spacing: 0em;
        text-align: center;
        border: none;
      }
      .decline {
        width: 150px;
        height: 32px;
        border-radius: 5px;
        background-color: #ea3943;
        border: none;
        font-family: Open Sans;
        font-size: 14px;
        font-weight: 400;
        line-height: 19px;
        letter-spacing: 0em;
        color: #fbfbfb;
      }
      .decline:disabled {
        opacity: 0.3;
      }
    }
  }
}
