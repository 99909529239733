@import "../../../index.scss";

.quillTextEditor {
  @include flex(flex-start, flex-start, column);
  width: inherit;

  .ql-container {
    width: 100%;
  }
}

// hide image upload option
button.ql-image {
  display: none;
}
