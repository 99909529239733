@import "../../../../assets/globalVariables.scss";

.infoOvertimeModal {
  .lejeRequestOvertime {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
    color: #323338;
    text-align: center;
    .questionRequest {
      font: normal normal 600 16px/19px Open Sans;
    }
    .greetRequest {
      font: normal normal normal 14px/19px Open Sans;
    }
  }
  &.dark .lejeRequestOvertime {
    color: $white;
  }
}
