@import "../../../../../assets/globalVariables.scss";

.dayPayHeader {
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 16px 20px;
  .headerItem {
    display: flex;
    align-items: center;
    gap: 5px;
    width: 100%;
    font-weight: 600;
    white-space: nowrap;
    .disabledInput {
      font-weight: 400;
      background: #f1f7fb;
      height: 30px;
      width: 130px;
      border-radius: 5px;
      display: flex;
      align-items: center;
      padding-left: 10px;
      cursor: not-allowed;
      color: rgba(0, 0, 0, 0.25);
    }
  }
  &.dark .headerItem .disabledInput {
    background: none;
    color: $grey-2;
    // border: 1px solid $d-black1;
    box-shadow: 0px 0px 2px $white;
  }
}
