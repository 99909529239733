.no-results-wrapper {
  //   padding: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  span {
    display: flex;
    gap: 5px;
    color: red;
  }
}
