@import "../../../../../index.scss";
@import "../../../../../assets/globalVariables.scss";

.documentationTemplatesModal {
  .ant-modal-body {
    padding: 0;
  }

  //* styles documentationTemplatesModalContainer align-items:center, justify-content:flex--start, and flex-direction:column
  .documentationTemplatesModalContainer {
    @include flex(center, flex-start, column);
    gap: 20px;
    padding: 25px 120px;

    //* styles docsLengthLabel font-size:18px, font-weight:700, letter-spacing:0, and texxt color
    .docsLengthLabel {
      @include text(18px, 700, 0, #1264a3);
    }

    .checkboxLabel {
      display: flex;
    }

    .templateCheckbox:not(.dark) {
      width: 450px;
      background-color: #f7f8fa;
    }

    //* styles templateLabel text-overflow:ellipsis
    .templateLabel {
      width: 320px;
      display: inline-block;
      @include textEllipsis();
    }

    //* styles tooltipContent align-items:center, justify-content:center, and flex-direction:column
    .tooltipContent {
      @include flex(center, center, column);
    }

    //* styles templateActionButtons align-items:center, justify-content;center
    .templateModalActionButtons {
      @include flex(center, center);
    }

    .rejectButton {
      @include mondayButton(#f5f5f7, #e6e9ef, #000);
      margin-right: 30px;
    }

    .submitButton {
      @include mondayButton(#71cf48, #68c142);
    }
  }
}
