@import "../../../../../index.scss";

.documentationListCard {
  border-radius: 20px;
  overflow-x: hidden;
  overflow-y: auto;
  flex-grow: 1;
  margin-right: 0px !important;

  .documentationListContainer {
    display: grid;
    grid-template-columns: 50% 50%;
    grid-auto-rows: min-content;
    grid-gap: 16px;
    animation: 0.8s ease-in-out 0s 1 loadAnimation;
  }
  //* styles formCardTitle align-items:center, justify-content:center,and font-size:16px, font-weight:600, letter-spacing:0, and text color
  .formCardTitle {
    @include flex(center, center);
    @include text(16px, 600, 0, #323338);
    width: stretch;
    height: 45px;
    background-color: #f7f8fa;
    margin: -15px -30px 25px;
    padding: 0 20px;
  }
}
@keyframes loadAnimation {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@media (max-width: 1366px) {
  .childrenContainer.documentationListContainer {
    display: grid;
    grid-template-columns: 1fr;
  }
}

//* styles DocumentationFileTree for  Pc monitor
@media (max-width: 1025px) {
  .documentationListCard {
    .formCardTitle {
      height: auto;
      padding: 10px;
      .documentationListCardHeader {
        display: flex;
        flex-direction: column;
        gap: 5px;
        .documentationListCardHeaderTitle {
          width: 100%;
          margin: 0;
        }
        .leftSection {
          width: 100%;
          justify-content: flex-end;
          gap: 10px;
          .employeeName {
            margin: 0;
          }
        }
        .newButton {
          right: 0;
        }
      }
    }
  }
  .childrenContainer.documentationListContainer {
    .documentationViewCard {
      display: flex;
      flex-direction: column;
      height: auto;
      width: 100%;
      gap: 10px;
      padding: 10px 15px;
      .docTitle {
        width: 100%;
        max-width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
      }
      .leftSection {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        .docStatusLabel {
          margin: 0;
        }
      }
    }
  }
  .documentationListCardHeader .newButton {
    right: 20px;
  }
  .antCustomStepper .ant-steps-navigation .ant-steps-item-process::after,
  .antCustomStepper .ant-steps-navigation .ant-steps-item-active::after {
    left: 99%;
  }
}
