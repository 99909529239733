@import "../../../../../assets/globalVariables.scss";

$card_blue: #1d3445;
$input_background: #f5f5f7;
$checkbox_color: #1da193;
.inner-modal {
  // width: 1095px !important;
  .ant-modal-body {
    display: flex;
    flex-direction: column;
    gap: 5px;
    overflow-y: auto;
    .employee-name {
      // margin: 0px 122px 0px;
      margin-top: -10px;
      text-align: center;
      justify-content: center;
      align-items: center;
      font: normal normal 600 14px/22px "Open Sans", sans-serif;
    }
    .shift-time {
      justify-content: center;
      font: normal normal 600 14px/22px "Open Sans", sans-serif;
      #red {
        color: #ea3943;
        margin-right: 5px;
      }
      #green {
        color: #1da193;
        margin-left: 5px;
      }
    }
    .note-container {
      background: #f1f7fb 0% 0% no-repeat padding-box; //   height: auto;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 5px;
      border-radius: 5px;
      padding: 0 16px 16px;
      .note-title {
        display: flex;
        justify-content: center;
        padding: 2px 30px;
        border-radius: 5px;
        background-color: #1d3445;
        color: white;
      }
      .note {
        display: flex;
        justify-content: center;
      }
    }
  }
  &.dark {
    .note-container {
      background: none;
      padding: 0;
      .note-title {
        background: $d-black5;
      }
    }
  }
}
