@import "../../../../index.scss";

.docCategoryCard {
  width: fit-content;
  position: relative;
  background-color: #fff;
  border-radius: 5px;
  transition: all 0.3s ease-in-out;
  margin: 0 25px 25px 0;
  color: #323338;

  //* styles docCategoryContainer justify-content:center, align-items:center, flex-direction:column
  .docCategoryContainer {
    @include flex(center, center, column);
  }
  //* styles categoryTitle text font-size:20px, font-weight:bold, letter-spacing:0, and text color
  .categoryTitle {
    width: 100%;
    text-align: center;
    @include text(20px, bold, 0, inherit);
    margin-bottom: 10px;
  }

  .configButton {
    @include mondayButton(#71cf48, #68c142);
  }
  .deleteIcon {
    position: absolute;
    top: -6px;
    right: -20px;
    height: 18px;
    cursor: pointer;
    &:hover {
      filter: brightness(0.9);
    }
  }

  &:hover {
    transform: scale(108%, 108%);
  }
}
