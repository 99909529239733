@import "../../../../../index.scss";

.filePreviewModal {
  .ant-modal-body {
    padding: 45px;
  }

//* styles bodyContent align-items:center, justify-content:center, and flex-direction:column
  .bodyContent {
    min-width: 30vw;
    min-height: 30vh;
    @include flex(center, center, column);
  }

  .displayedFileFrame {
    height: 85vh;
    width: 90vw;
    border: 0;
  }

  .displayedImage {
    max-height: 85vh;
    max-width: 90vw;
    overflow: auto;
  }

  .closeIcon {
    position: absolute;
    top: 15px;
    right: 15px;
    font-size: 20px;
  }

  .gDriveButton {
    @include mondayButton(#f5f5f7, #e6e9ef, #000);
    margin-top: 20px;
    position: absolute;
    bottom: 60px;
    right: 80px;
    .mondayButton {
      width: 25px;
    }
  }

  .gDriveIcon {
    width: 20px;
    height: 18px;
    margin-right: 25px;
  }
}
