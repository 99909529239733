@import "../../../assets/globalVariables.scss";

.intervistatMainTabs {
  // min-width: 1630px;
  // width: fit-content;
  display: flex;
  flex-direction: column;
  gap: 10px;
  height: 100%;
  border-radius: 10px;
  //winter
  // background-color: transparent;
  display: flex;
  justify-content: space-between;
  animation: intervistatMainTabsAnim 1s;
  // overflow-y: auto;
  .intervistatTabs,
  .kandidadiTabs {
    height: 100%;
    gap: 15px;
    //winter
    // background-color: transparent;

    .ant-tabs-nav {
      margin: 0 !important;
      background: #fff;
      border: none !important;
      border-radius: 10px;
      overflow: hidden;
      padding: 5px;
      .ant-tabs-nav-list {
        gap: 24px;
        overflow: hidden;
        .ant-tabs-tab {
          position: relative;
          top: 2px;
          font-size: medium;
          font-weight: 400;
          font-size: 16px;
          align-self: center !important;
          justify-self: center !important;
          display: flex;
          padding-right: 10px;
          padding-left: 10px;
          width: 100%;
          animation: animateTab 0.5s;
          margin: 0;
        }
      }
      &::before {
        border: none;
      }
    }
    .ant-tabs-content {
      height: 100%;
      > :nth-child(1) {
        height: 100%;
      }
    }

    .ant-tabs-card > .ant-tabs-nav .ant-tabs-tab,
    .ant-tabs-card > div > .ant-tabs-nav .ant-tabs-tab {
      border: 0;
    }

    .ant-tabs > .ant-tabs-nav .ant-tabs-nav-list,
    .ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-list {
      display: flex;
      gap: 30px;
      width: 100%;
      overflow-y: hidden;
    }
    .ant-tabs-tab:hover {
      color: #58647a;
    }

    .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
      color: #323338;
    }

    .ant-tabs-ink-bar {
      background: #323338;
      padding-bottom: 5px;
      width: 100%;
      animation: inkBar 0.5s;
    }
  }
  .kandidadiTabs {
    width: 100%;
  }
  .buttons {
    padding-right: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    animation: buttonAnimate 0.5s;
    .ant-btn-icon {
      display: flex;
      align-items: center;
    }
    .applicantButtons {
      display: flex;
      gap: 10px;
    }
    .shtoKandidadBtnContainer {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 5px;
    }
    .exportKandidateBtn {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 32px;
      border: none;
      padding: 0;
      background-color: #1da193;
      border-radius: 5px;
      * {
        border: none;
      }
    }
  }
  .kanbanFilters {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    width: 95%;
    margin: 0;
    animation: filtersContentKanban 0.5s;
    .filterBtnKanban {
      width: 98px;
      height: 32px;
      border: none;
      border-radius: 5px;
      background-color: #2e5d9e;
      color: #ffffff;
    }
    .clearFilterBtn {
      height: 32px;
      width: 32px;
      border-radius: 5px;
      background-color: #ea3943;
      border: none;
      .centerIcon {
        display: flex;
      }
    }
  }
  &.dark {
    color: $white;
    .ant-tabs {
      color: $white;
    }
    .intervistatTabs {
      .kanbanFilters .inputSearchFilter {
        border: 1px solid $white;
      }
      .ant-tabs-nav {
        background: $d-black1;
        .ant-tabs-tab {
          color: $white;
          &.ant-tabs-tab-active .ant-tabs-tab-btn {
            color: $white;
          }
          &:hover {
            color: $grey-2;
          }
        }
        .ant-tabs-ink-bar {
          background: $white;
        }
      }
    }
    .noKandidads {
      background: $d-black2;
      box-shadow: 0px 0px 5px #75eade;
    }
  }
}

@media (max-width: 1200px) {
}
@media (max-width: 768px) {
  .intervistatMainTabs {
    .ant-tabs-nav {
      flex-direction: column;
      gap: 5px;
      .ant-tabs-nav-list {
        gap: 5px;
      }
      .ant-tabs-extra-content {
        width: 100%;
        .buttons {
          justify-content: space-evenly;
        }
      }
    }
  }
}

@keyframes animateTab {
  0% {
    opacity: 0;
    transform: translateX(-10px);
  }
  100% {
    opacity: 1;
  }
}

@keyframes buttonAnimate {
  0% {
    opacity: 0;
    transform: translateX(10px);
  }
  100% {
    opacity: 1;
  }
}
@keyframes filtersContentKanban {
  0% {
    opacity: 0;
    transform: translateY(-10px);
  }
  100% {
    opacity: 1;
  }
}

@keyframes intervistatMainTabsAnim {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes inkBar {
  0% {
    opacity: 0;
    transform: translateY(10px);
  }
  100% {
    opacity: 1;
  }
}
