.merge-user-empl {
  .ant-modal-content {
    border-radius: 10px 10px 10px 10px;
  }

  .ant-modal-body {
    background-color: white;
    height: 150px;
    display: flex;
    flex-direction: column;
    // justify-content: center;
    // align-items: center;

    .merge-user-wrapper {
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;
      .title-merge {
        font: normal normal 600 16px/22px Open Sans;
      }
    }
  }
}
