@import "../../../../index.scss";

.documentationFormPreview {
  height: 100%;
  width: 60%;
  background-color: #fff;

  .labeledInputContainer {
    padding-right: 15px;

    //* styles labeledInputLabel text font-size:14px, font-weight:400, letter-spacing:0.21px, and text color
    .labeledInputLabel {
      @include text(14px, 600, 0.21px, inherit);
      margin-bottom: 2px;
    }
  }

  //* styles fieldsContainer justify-content:baseline, and align-items:flex-start
  .fieldsContainer {
    @include flex(baseline, flex-start);
    height: 100%;
    flex-wrap: wrap;
    padding: 10px;
  }

  //* previewHeader justify-content:center, and align-items:center, text font-size:20px, font-weight:600, letter-spacing:0, and text color
  .previewHeader {
    width: 100%;
    background-color: #323338;
    @include flex(center, center);
    @include text(20px, 600, 0, #fff);
  }

  .headerTitleIcon {
    margin: 10px;
  }
  &.dark {
    background: $d-black3;
  }
}

//* responsive FormPreview for iPad Air
@media (max-width: 821px) {
  .documentationFormPreview {
    height: 0;
    width: 100%;
  }
  .documentationFormPreview .fieldsContainer {
    background-color: #fff;
  }
}

//* responsive FormPreview for PC monitor
@media (max-width: 1025px) {
  .documentationFormPreview .fieldsContainer {
    background-color: #fff;
  }
  .documentationFormPreview .previewHeader {
    font-size: 23px;
  }
  .documentationFormPreview .labeledInputContainer .labeledInputLabel {
    font-size: 18px;
  }
}

//* responsive FormPreview for iPhone 12 Pro
@media (max-width: 391px) {
  .documentationFormPreview {
    width: 300px;
    margin-right: 70px;
  }
  .documentationFormPreview .previewHeader {
    display: block;
    font-size: 16px;
  }
  .documentationFormPreview .labeledInputContainer .labeledInputLabel {
    font-size: 14px;
  }
}
