.approvalsAgGrid {
  .ag-cell {
    align-content: center;
  }
  .statusIcon {
    width: 40px;
    padding: 5px;
    cursor: pointer;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    color: white;
  }
  .cellTimeWrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 22px;
    background: #d9f7fa;
    cursor: pointer;
    border-radius: 5px;
    color: #1d3445;
  }
  .icon-ndrysho-modal-cell {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    width: 40px;
    padding: 10px;
    border-radius: 5px;
    cursor: pointer;
    background: #1da193;
  }
  .cellLogWrapper {
    display: flex;
    justify-content: center;
    width: 100%;
    span {
      display: flex;
      justify-content: center;
      align-items: center;
      background: #1da193;
      width: 40px;
      padding: 10px;
      border-radius: 5px;
      cursor: pointer;
    }
  }
  &.dark .cellTimeWrapper{
    background: #abcbce;
  }
}
.approvalsAgGrid.notDesktop {
  .ag-root.ag-layout-normal {
    overflow-x: scroll !important;
    width: fit-content !important;
    .ag-header {
      width: fit-content !important;
    }
    .ag-body {
      width: fit-content !important;
      .ag-cell {
        width: fit-content !important;
        min-width: 100px !important;
      }
    }
    .ag-body-horizontal-scroll {
      display: none;
    }
  }
}
.approveMenu {
  .ant-dropdown-menu-item {
    width: 100%;
    text-align: center;
    span {
      width: 100%;
      font-weight: 600;
    }
  }
}

.shift-approvals {
  // display: flex;
  // margin-left: 22vh;
  // position: absolute;
  // pointer-events: none;

  .ag-grid-shifts {
    display: flex;
    flex-direction: column;
    padding-bottom: 10px;
    width: 100%;
    height: 100%;
    box-shadow: 1px 1px 2px 1px #00000029;
    border-radius: 15px;
    .approve-buttons {
      margin-left: 20px;
      margin-right: 20px;
    }
  }
  .more-shift-filters2 {
    width: 100%;
    width: 100%;
    // height: fit-content;
    box-shadow: 1px 1px 2px 1px #00000029;
    border-radius: 15px;
    height: 70px;
    display: flex;
    align-items: center;
    gap: 20px;
    padding: 20px;
    overflow-y: hidden;

    /* UI Properties */
    background: var(--unnamed-color-ffffff) 0% 0% no-repeat padding-box;
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0px 1px 4px #00000029;
    border-radius: 10px;
    opacity: 1;
    .department-filter {
      width: 205px;
      .ant-select-clear {
        background-color: #f1f7fb !important;
      }
      .ant-select-selector {
        background: unset;
        color: unset;
        // background-color: #f1f7fb !important;
        border: none !important;
        border-radius: 5px !important;
      }
      .ant-select {
        // width: 200px;
        border-radius: 5px !important;
      }
    }

    .employee-filter {
      width: 205px;

      .ant-select-clear {
        background-color: #f1f7fb !important;
      }
      .ant-select-selector {
        background-color: #f1f7fb !important;
        border: none !important;
        border-radius: 5px !important;
      }
      .ant-select {
        // width: 200px;
        border-radius: 5px !important;
      }
    }
  }
}

.new-shift {
  // margin-left: 180px;
  button {
    width: 150px;
    height: 32px;
    /* UI Properties */
    background: #1da193 0% 0% no-repeat padding-box;
    border: none;
    border-radius: 5px;
    color: #fff;
    font: normal normal normal 14px/19px "Open Sans", sans-serif;
    &:hover {
      opacity: 0.8;
      cursor: pointer;
    }
  }
}

.refresh-data {
  button {
    margin-left: 20px;
    width: 150px;
    height: 32px;
    /* UI Properties */
    background: #1da193 0% 0% no-repeat padding-box;
    border: none;
    border-radius: 5px;
    color: #fff;
    font: normal normal normal 14px/19px "Open Sans", sans-serif;
    &:hover {
      opacity: 0.8;
      cursor: pointer;
    }
  }
}

.clear-filters-shift {
  button {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 30px;
    height: 30px;
    /* UI Properties */
    background: #ea3943 0% 0% no-repeat padding-box;
    border: none;
    border-radius: 5px;
    color: #fff;
    font: normal normal normal 14px/19px "Open Sans", sans-serif;
    &:hover {
      opacity: 0.8;
      cursor: pointer;
    }
  }
}
.logs-button {
  button {
    width: 120px;
    height: 32px;
    /* UI Properties */
    background: #ffa630 0% 0% no-repeat padding-box;
    border: none;
    border-radius: 5px;
    color: #fff;
    font: normal normal normal 14px/19px "Open Sans", sans-serif;
    &:hover {
      opacity: 0.8;
      cursor: pointer;
    }
  }
}

.refresh-data-btn {
  background-color: #f1f7fb;
  border: none;
  width: 32px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  cursor: pointer;
}

.ag-theme-alpine
  .ag-header-container
  .ag-wrapper.ag-input-wrapper.ag-checkbox-input-wrapper {
  background: #fff !important;
  border: none;
  opacity: 1;
  z-index: 4;
}

@media (max-width: 1366px) {
  .shift-approvals {
    .more-shift-filters2 {
      overflow-x: auto;
    }
  }
}

@media (max-width: 821px) {
  .shift-approvals .more-shift-filters2 {
    width: 97.5% !important;
    flex-wrap: wrap !important;
    height: 90px !important;
  }
  .shift-approvals {
    padding-left: 0px !important;
    margin: 0px !important;
  }
  .shift-approvals .ag-grid-shifts {
    width: 98% !important;
    .ag-grid-header {
      flex-wrap: wrap !important;
      gap: 10px !important;
    }
  }
}
@media (max-width: 450px) {
  .more-shift-filters2-mobile {
    width: 100%;
    // height: fit-content;
    box-shadow: 1px 1px 2px 1px #00000029;
    border-radius: 15px;
    height: fit-content;
    display: grid;
    gap: 10px;
    // justify-content: baseline;
    // justify-content: space-between;
    // gap: 40px;
    padding: 10px;
    align-items: center;
    margin-bottom: 15px;
    /* UI Properties */
    background: var(--unnamed-color-ffffff) 0% 0% no-repeat padding-box;
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0px 1px 4px #00000029;
    border-radius: 10px;
    grid-template-columns: 49% 49% !important;
    opacity: 1;
    .department-filter {
      // margin-left: 20px;
      width: 100% !important;
      .ant-select-clear {
        background-color: #f1f7fb !important;
      }
      .ant-select-selector {
        background-color: #f1f7fb !important;
        border: none !important;
        border-radius: 5px !important;
      }
      .ant-select {
        width: 100% !important;
        border-radius: 5px !important;
      }
    }
    .filter-item {
      width: 100%;

      .ant-select-clear {
        background-color: #f1f7fb !important;
      }
      .ant-select-selector {
        background-color: #f1f7fb !important;
        border: none !important;
        border-radius: 5px !important;
      }
      .ant-select {
        width: 100% !important;
        border-radius: 5px !important;
      }
    }
    .month-shift-picker.ant-picker {
      width: 100% !important;
    }
  }
  .shift-approvals .more-shift-filters2 {
    height: 160px !important;
    width: 100% !important;
  }
  .shift-approvals .ag-grid-shifts {
    width: 100% !important;
    .ag-grid-header {
      flex-wrap: wrap !important;
      gap: 10px !important;
    }
    .new-shift {
      button {
        width: 120px !important;
      }
    }
  }
  .ag-grid-shifts .ag-grid-header input.ant-input {
    width: 100px !important;
  }
}

@media (max-width: 391px) {
  .shift-approvals .more-shift-filters2 {
    height: 160px !important;
    width: 90% !important;
  }
  .shift-approvals .ag-grid-shifts {
    width: 90% !important;
    .ag-grid-header {
      flex-wrap: wrap !important;
      gap: 10px !important;
      .header-icons {
        margin-left: 0px !important;
      }
    }
    .new-shift {
      button {
        width: 90px !important;
        font-size: 11px !important;
      }
    }
  }

  .shift-approvals .ag-grid-shifts .ag-grid-header input.ant-input {
    width: 90px !important;
  }
  .shift-approvals {
    height: 1190px !important;
  }
}

.ant-btn-group.ant-dropdown-button.approve-buttons {
  margin-left: 10px;
  margin-right: 10px;
  button.ant-btn {
    background: #1da193;
    color: #fff;
    border: none;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
  }
  button.ant-btn.ant-btn-icon-only.ant-dropdown-trigger {
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
  }
}
