@import "../../../assets/globalVariables.scss";

.add-permission {
  display: flex;
  gap: 5px;
  width: 100%;
  form {
    display: flex;
    flex-direction: row;
    gap: 5px;
    width: 100%;
    .ant-form-item {
      width: 100%;
    }
  }
  &.dark {
    .ant-form-item-has-error .ant-select-status-error {
      box-shadow: 0px 0px 2px $red;
      border: 1px solid $red;
      input {
        border: none !important;
        box-shadow: none !important;
      }
    }
  }
}
.dropdown {
  background: #f5f5f7;
  border: 0;
  border-radius: 5px;
  width: 100%;
  height: 100%;
}
