$green: #1da193;
$red: #ea3943;
$blue: #2e5d9e;
$white: #e6e6e6;
$grey-1: #f5f5f7;
$grey-2: #d7d7d7;
$grey-3: #787677;
$yellow: #f5a623;

$d-black1: #121212;
$d-black2: #1a1a1a;
$d-black3: #282828;
$d-black4: #2a2a2a;
$d-black5: #383838;
$d-black6: #424242;
$d-yellow: #faad14;
$d-blue: #719bd6;
