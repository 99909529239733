.mobileAgGridWrapper {
  .rowWrapper {
    position: relative;
    width: 100%;
    border: 1px solid;
    border-radius: 10px;
    animation: appearSoftly 300ms;
    display: flex;
    gap: 5px;
    max-height: 42px;
    transition: max-height 500ms ease-in-out;
    font-weight: 600;
    .anticon {
      display: flex;
      justify-content: center;
      width: 40px;
      height: 40px;
      &.rotate180 {
        animation: rotate180 300ms ease-in-out forwards;
      }
      &.rotate-180 {
        animation: rotate-180 300ms ease-in-out forwards;
      }
    }
    .drawerWrapper {
      display: flex;
      .drawerContent {
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 5px;
        width: 30px;
        .ant-checkbox-wrapper {
          width: 10px;
          height: 10px;
        }
      }
    }
    .contentWrapper {
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 5px;
      padding: 10px 0px;
      width: 100%;
      overflow: hidden;
      .row {
        opacity: 0;
      }
      &.appearSoftly {
        gap: 10px;
        flex-direction: column !important;
        .row {
          animation: appearSoftly 200ms forwards;
          animation-delay: 100ms;
          display: grid;
          grid-template-columns: minmax(0, 3fr) minmax(0, 5fr);
          gap: 15px;
          width: 100%;
          p {
            margin: 0px;
          }
          span:first-child {
            text-align: right;
            align-content: center;
          }
          span:last-child {
            display: flex;
            align-items: center;
          }
          .link {
            text-decoration: underline;
            letter-spacing: 0.5px;
            &:active {
              color: magenta;
            }
          }
        }
      }
    }
    &.show {
      max-height: 100%;
    }
    &.hide {
      max-height: 42px;
    }
  }
}

@keyframes rotate180 {
  from {
    transform: rotate(0deg);
    transform-origin: center;
  }
  to {
    transform: rotate(180deg);
    transform-origin: center;
  }
}
@keyframes rotate-180 {
  from {
    transform: rotate(180deg);
    transform-origin: center;
  }
  to {
    transform: rotate(0deg);
    transform-origin: center;
  }
}

@keyframes appearSoftly {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
