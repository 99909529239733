.shift-modal {
  height: auto !important;
  width: auto !important;
  min-width: 400px !important;
  min-height: 400px !important;
}

@media (max-width: 450px) {
  .shift-modal {
    width: 100% !important;
    .ant-modal-body {
      min-width: unset !important;
      min-height: unset !important;
      .shiftGridContent {
        flex-direction: column;
        .mobileAgContent{
          max-height: 400px;
          overflow-x: hidden;
        }
      }
    }
  }
}
