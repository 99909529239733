.edit-qotd-modal {
  .ant-modal-content {
    width: 100% !important;
    height: 100% !important;
    overflow: hidden;
  }
  .ant-modal-body {
    .edit-qotd-wrapper {
      display: flex;
      flex-direction: column;
      gap: 20px;
      .input-holder {
        display: flex;
        gap: 5px;
        .ant-input {
          border: none;
          background-color: #f5f5f7;
        }
      }
    }
  }
}
