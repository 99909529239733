@import "../../../../assets/globalVariables.scss";

.workBeforeShiftData {
  display: flex;
  // align-items: center;
  justify-content: space-between;
  flex-direction: row;
  // gap: 3rem;
  padding-left: 20px;
  padding-right: 20px;
  height: 35px;
  width: 100%;
  .header-item-tip {
    font-weight: 600;
    font-size: 15px;
    height: 35px;
    width: 16%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.buttonFooterOvertime {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding-left: 15px;
  padding-right: 15px;
}

.containerOvertime {
  overflow: auto;
  height: calc(100% - 72px);
  padding-bottom: 10px;
  display: flex;
  flex-direction: column;
  .item-overtime {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 5px 20px;
    &:nth-child(odd) {
      background-color: #f5f5f7;
    }
  }
  &.dark .item-overtime:nth-child(odd) {
    background-color: $d-black2;
  }
}
.overtimePeriod {
  display: flex;
  flex-direction: column;
}

.overtimePeriodContainer {
  display: flex;
  flex-direction: row;
  gap: 1rem;
}

.workBeforeShiftModal {
  .ant-modal-content {
    width: 100%;
    // height: 457px;
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0px 1px 4px #00000029;
    border-radius: 10px;
    opacity: 1;
    overflow: auto;
    overflow-x: hidden;
    form {
      .ant-select:not(.ant-select-customize-input) .ant-select-selector {
        width: 100%;
        height: 32px;
        background: #f5f5f7 0% 0% no-repeat padding-box;
        border-radius: 5px;
        opacity: 1;
        border: none;
      }
      .ant-form-item-row {
        display: grid;
        grid-template-columns: 2fr 5fr;
      }
      .formOverwork {
        .ant-form-item-row {
          grid-template-columns: 2fr 3fr;
        }
      }
    }
  }
}

.noOvertimeUser {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding-top: 45px;
  color: #323338;
  .noOvertime {
    display: flex;
    justify-content: center;
    width: 284px;
    height: 22px;
    font: normal normal 600 16px/19px Open Sans;
    letter-spacing: 0px;
    opacity: 1;
    margin-top: 10px;
  }
  .noOvertime-greet {
    width: 400px;
    height: 19px;
    text-align: center;
    font: normal normal normal 14px/19px Open Sans;
    letter-spacing: 0px;
    margin-top: 5px;
  }
  .noOvertime-actionButtons {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    gap: 1rem;
    margin-top: 35px;
    .infoBtn {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: row;
      gap: 0.8rem;
      width: 221px;
      height: 32px;
      background: #1da193 0% 0% no-repeat padding-box;
      border-radius: 5px;
      opacity: 1;
      color: #fff;
    }
    .createBtn {
      width: 224px;
      height: 32px;
      background: #1da193 0% 0% no-repeat padding-box;
      border-radius: 5px;
      opacity: 1;
      color: #fff;
    }
  }
  &.dark {
    color: $white;
  }
}

.formOverwork {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 10px;

  .formFields {
    width: 100%;
    height: 32px;
    background: #f5f5f7 0% 0% no-repeat padding-box;
    border-radius: 5px;
    opacity: 1;
    border: none;
  }
}
@media (max-width: 450px) {
  .noOvertimeUser {
    .noOvertime-actionButtons {
      flex-direction: column;
      gap: 5px;
      margin-top: 25px;
    }
    .noOvertime-greet {
      width: 100%;
      padding: 0 10px;
    }
  }
  .workBeforeShiftModal {
    .ant-form-item-row {
      grid-template-columns: 1fr 2fr !important;
    }
  }
}
