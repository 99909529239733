@import "../../../../../index.scss";
@import "../../../../../assets/globalVariables.scss";

.dynamicDocumentationModal {
  .ant-modal-body {
    padding: 34px;
  }

  //* styles docContent align-items:flex-start, justify-content:center
  .docContent {
    @include flex(flex-start, center);
  }

  .docStatusStepper {
    margin-bottom: 30px;
  }

  //* styles formContainer align-items:baseline, justify-content:flex-start
  .formContainer {
    width: 44vw;
    height: 100%;
    @include flex(baseline, flex-start);
    flex-wrap: wrap;

    .labeledInputContainer {
      padding-right: 15px;

      .labeledInputLabel {
        font-size: 14px;
        font-weight: 600;
        letter-spacing: 0.21px;
        margin-bottom: 2px;
      }
    }
  }

  //* styles formSection align-items:center, justify-content:space-betweeen, and flex-direction:column
  .formSection {
    @include flex(center, space-between, column);
    min-height: 586px;
    flex-grow: 1;
  }

  .ant-select-auto-complete {
    width: 350px !important;
  }
  .gradientDivider {
    width: 7px;
    background-image: linear-gradient(to bottom, #ffffff, #f1f3f4, #ffffff);
    margin: 0 25px;
    align-self: stretch;
  }
  .dark .gradientDivider {
    background: $grey-3;
  }
}

//* responsive for iPad Air
@media (max-width: 821px) {
  .dynamicDocumentationModal .formSection {
    width: 100%;
  }
}
//* styles DocumentationFileTree for monitor Pc
@media (max-width: 1025px) {
  .dynamicDocumentationModal .docContent {
    display: block;
  }
}
//* styles DocumentationFileTree for iPhone 13 Pro
@media (max-width: 391px) {
  .dynamicDocumentationModal .formSection {
    min-height: 375px;
  }
  .dynamicDocumentationModal .formContainer {
    width: 73vw;
  }
}
