.authWrapper {
  display: flex;
  flex-direction: column;
  width: 100dvw;
  height: 100dvh;
  .authHeader {
    display: flex;
    justify-content: center;
    width: 100%;
    padding: 10px 0;
  }
  .authContent {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    position: relative;
    .backgroundImg {
      position: absolute;
      width: 100%;
      height: 100%;
      background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
        url(../../assets/images/background.png);
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
      z-index: 1;
    }
    .dynamicInterface {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      gap: 30px;
      border-radius: 15px;
      box-shadow: 1px 1px 1px 1px rgba(0, 0, 0, 0.1);
      width: 550px;
      min-height: 350px;
      background-color: #fff;
      text-align: center;
      padding: 40px 20px;
      margin: 0 10px;
      z-index: 2;
      .authTitle {
        font-size: x-large;
        font-weight: 600;
      }
    }
  }
}
