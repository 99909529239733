@import "../../../assets/globalVariables.scss";

.ant-breadcrumb {
  .ant-breadcrumb-link {
    cursor: pointer;
  }
}
.dynamic-punojsit-tabs {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  .punojsit-tab {
    overflow: hidden;
    height: 100%;
    .ant-tabs-content-holder {
      height: 100% !important;
      .ant-tabs-content {
        height: 100% !important;
        .ant-tabs-tabpane {
          height: 100% !important;
        }
      }
    }
  }
  .ant-tabs-nav-wrap {
    background: #fff;
  }

  .ant-tabs-nav {
    border: none !important;
  }
  .ant-tabs-nav-wrap {
    padding-left: 20px;
    padding-right: 20px;
  }

  .ant-tabs-tab {
    font-size: medium;
    font-weight: 500;
    align-self: center !important;
    justify-self: center !important;
    display: flex;
    justify-content: center;
    width: 100%;
  }
  .ant-tabs-nav-list {
    width: 100%;
  }
  .ant-tabs-tab:hover {
    color: #58647a;
  }

  .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: #323338;
  }

  .ant-tabs-ink-bar {
    background: #323338;
    padding-bottom: 5px;
    border-radius: 50px;
    width: 100%;
  }

  div#tabBody {
    // justify-content: flex-start;
    // align-items: center;
    // display: flex;
    width: 100%;
  }
  &.dark {
    color: $white;
    .ant-tabs-nav-wrap {
      background: none;
    }
    .ant-tabs-nav {
      background: none;
      // box-shadow: 0px 0px 5px #75eade;
      .ant-tabs-tab {
        color: $white;
        &.ant-tabs-tab-active .ant-tabs-tab-btn {
          color: $white;
        }
        &:hover {
          color: $grey-2;
        }
      }
      .ant-tabs-ink-bar {
        background: $white;
      }
    }
  }
}
.employee {
  font-size: 28px;
  font-weight: 600;
  font-family: "Open Sans", sans-serif !important;
  position: absolute;
  z-index: 1000;
  margin-left: 100px;
}

@media (max-width: 450px) {
  .ant-tabs-tab + .ant-tabs-tab {
    margin: 0px !important;
  }
  .employee {
    font-size: 13px !important;
    margin-left: 65px;
  }
}

@media (max-width: 391px) {
  .dynamic-punojsit-tabs .ant-tabs-tabpane {
    padding: 0 10px 10px;
  }
  .dynamic-punojsit-tabs .ant-tabs-tabpane {
    overflow-x: hidden;
  }
}
