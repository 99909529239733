@import "../../../../../../index.scss";

.docOptionsContainer {
  .optionField {
    position: relative;
    margin-right: -21px !important;
  }

//* styles fieldOptionButton justify-content:center, align-items:center
  .fieldOptionButton {
    @include flex(center, center);
    position: absolute;
    height: 30px;
    padding: 5px;
    width: 30px;
    fill: #fff;
    border-radius: 5px;
    cursor: pointer;
    text-align: center;
  }

  .closeSvgIcon {
    width: 18px;
  }

  .added {
    background-color: #ff4d4f;
    top: 0;
    right: -35px;
    &:hover {
      background-color: #eb4345;
    }
  }

  .notAdded {
    top: 0;
    right: -70px;
    background-color: #71cf48;
    &:hover {
      background-color: #68c142;
    }
  }
}
