// Christmas Lights Styles

$globe-width: 12px;
$globe-height: 28px;
$globe-spacing: 40px;
$globe-spread: 3px;
$light-off-opacity: 0.4;

.lightrope {
	height: 100%;
	width: 100%;
	text-align: center;
	white-space: nowrap;
	overflow: hidden;
	position: absolute;
	z-index: 1;
	margin: -15px 0 0 0;
	padding: 0;
	pointer-events: none;
	width: 100%;
	z-index: 1000000;
	margin-top: -20px;

	li {
		position: relative;
		animation-fill-mode: both;
		animation-iteration-count: infinite;
		list-style: none;
		margin: 0;
		padding: 0;
		display: block;
		width: $globe-width;
		height: $globe-height;
		border-radius: 50%;
		margin: $globe-spacing / 2;
		display: inline-block;

		background: #ffdf00; // Gold
		box-shadow: 0px $globe-height / 6 $globe-width * 2 $globe-spread #ffdf00;
		animation-name: flash-3;
		animation-duration: 1.1s;

		&:before {
			content: "";
			position: absolute;
			background: #222;
			width: ($globe-width - 2);
			height: $globe-height / 3;
			border-radius: 3px;
			top: (0 - ($globe-height / 6));
			left: 1px;
		}

		&:after {
			content: "";
			top: (0 - $globe-height / 2);
			left: $globe-width - 3;
			position: absolute;
			width: $globe-spacing + 12;
			height: ($globe-height / 3 * 2);
			border-bottom: solid #222 2px;
			border-radius: 50%;
		}

		&:last-child:after {
			content: none;
		}

		&:first-child {
			margin-left: -$globe-spacing;
		}
	}

	&.down {
		transform: rotate(180deg);
		margin-top: 0px !important;
	}

	&.up {
		transform: rotate(0deg);
	}

	&.right {
		transform: rotate(-90deg);
	}
}

@keyframes flash-3 {
	0%,
	100% {
		background: #ffdf00;
		box-shadow: 0px $globe-height / 6 $globe-width * 2 $globe-spread #ffdf00;
	}
	50% {
		background: rgba(255, 223, 0, $light-off-opacity);
		box-shadow: 0px $globe-height / 6 $globe-width * 2 $globe-spread rgba(255, 223, 0, 0.2);
	}
}
