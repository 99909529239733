@import "../../../assets/globalVariables.scss";

.footerCounterWrapper {
  display: flex;
  justify-content: flex-end;
  padding: 15px 30px;
  border-radius: 0 0 10px 10px;
  overflow: hidden;
  .footerCounter {
    display: flex;
    gap: 10px;
    width: max-content;
    align-items: center;
    span {
      width: 100%;
      text-align: center;
      font: normal normal 600 14px Open Sans;
    }
  }
  .footerCounter.rowFlex {
    flex-direction: row;
    span {
      width: max-content;
    }
  }
  .footerCounter.columnFlex {
    flex-direction: column;
  }
  &.dark {
    color: $white;
  }
}
