@import "../../../../index.scss";

//* styles documentationPreviewContainer justify-content:center, and align-items:center
.documentationPreviewContainer {
  @include flex(center, center);
  flex-wrap: wrap;
  width: auto;
  height: 100%;

  //* styles header justify-content:center, and align-items:center
  .header {
    @include flex(center, center);
    flex-wrap: wrap;
    min-height: 120px;
    margin-bottom: 60px;
    padding: 0;
  }

  .docConfigLogoHeader .labelContainer {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    margin-left: 10px;
  }

  .docFieldOption {
    margin-bottom: 3px;
  }

  .mondayButtonGreen {
    position: absolute;
    bottom: 90px;
    right: 35px;
  }

  .backButton {
    font-size: 14px;
    padding: 8px;
    margin-right: 20px;
  }

  .previewLogoHeader {
    align-items: center;
    .logo {
      height: 60px;
    }
    .title {
      font-size: 20px;
    }
    .subtitle {
      font-size: 16px;
      max-width: 315px;
    }
  }

  .headerDivider {
    height: 100px;
    margin: 0 25px;
    border: 0;
  }

  //* styles categoryTitle text font-size:50px, font-weight:600, letter-spacing:0, and text-color,and text-overflow: ellipsis
  .categoryTitle {
    @include text(50px, 600, 0, inherit);
    max-width: 600px;
    @include textEllipsis();
  }

  //* styles coreInfoContainer justify-content:center, align-items:unset, and flex-direction:column
  .coreInfoContainer {
    width: 40%;
    height: 100%;
    gap: 10px;
    @include flex(center, unset, column);
  }

  .docStatusesBtn {
    margin-top: 40px;
  }

  //* styles inputs justify-content;flex-end, and align-items:center
  .inputs {
    @include flex(flex-end, flex-start);
    .labeledInputContainer {
      margin-right: 20px;
      width: 250px;

      .ant-input,
      .ant-select-selector {
        border-radius: 5px;
        border: 1px solid #707070;
      }
    }
  }

  .datasetsContainer {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    column-gap: 40px;
    row-gap: 5px;
    width: max-content;
    margin-bottom: 20px;

    .labeledInputContainer {
      width: fit-content;
    }

    .configElementContainer {
      margin: 0;
    }
  }

  //* styles dataSetLabel justify-content:center, and align-items:flex-start,and text font-size:16px, font-weight:400, letter-spacing:0, and text color
  .dataSetLabel {
    @include flex(center, flex-start);
    @include text(16px, 400, 0, #323338);
  }
  .dataSetLabelTag {
    background-color: #797f8f;
    color: #fff;
    border-radius: 5px;
    margin-left: 20px;
  }
}

.cardLayoutModal {
  color: #323338;

  .ant-modal {
    width: unset !important;
  }

  .ant-modal-content {
    border-radius: 10px;
    width: fit-content;
    height: fit-content;
    margin: auto;
  }

  //* styles ant-modal-header justify-content:center, align-items:flex-start
  .ant-modal-header {
    @include flex(center, flex-start);
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    background-color: #f8f8fa;
    padding: 0 20px;
    height: 45px;
  }

  //* styles ant-modal-title text font-size:16px, font-weight:700, letter-spacing:0, and text color
  .ant-modal-title {
    @include text(16px, 700, 0, #323338);
  }

  .ant-modal-body {
    padding: 25px;
  }

  //* styles footerContainer justify-content:center, and align-items:flex-end
  .footerContainer {
    @include flex(center, flex-end);
  }
}

//* responsive DocumentationPreview for iPad Air
@media (max-width: 821px) {
  .cardLayoutModal .ant-modal-content {
    width: 100%;
  }
  .dynamicDocumentationModal .docContent {
    display: block;
    align-items: flex-start;
    justify-content: center;
    flex-direction: row;
  }
  .documentationPreviewContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    height: 60%;
  }
  .documentationPreviewContainer .coreInfoContainer {
    align-items: center;
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: normal;
    width: 40%;
  }
}

//* responsive DocumentationPreview for Pc monitor
@media (max-width: 1025px) {
  .documentationPreviewContainer {
    height: 550px;
  }
}

//* responsive DocumentationPreview for iPhone 12 Pro
@media (max-width: 391px) {
  .konfigurimet {
    overflow-x: hidden;
  }
  .documentationPreviewContainer {
    width: 380px;
  }
  .documentationPreviewContainer .coreInfoContainer {
    width: 350px;
  }
  .docViewActionButtons {
    display: flex;
    flex-direction: column;
    gap: 6px;
  }
  .documentationPreviewContainer .header {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: row;
    flex-wrap: wrap;
    margin-left: 0;
  }
  .documentationPreviewContainer .headerDivider {
    display: none;
  }
  .documentationPreviewContainer .previewLogoHeader {
    margin-left: -5px;
  }
  .documentationPreviewContainer .backButton {
    margin-top: 35px;
  }
  .documentationPreviewContainer .categoryTitle {
    font-size: 20px;
    font-weight: 700;
    margin-top: 15px;
    margin-left: 50px;
  }
  .documentationPreviewContainer .docStatusesBtn {
    margin-top: 55px;
    right: 50px;
  }
  button.ant-btn.ant-btn-default.mondayButtonStyle.discardButton:first-child {
    width: 110px;
  }
  button.ant-btn.ant-btn-default.mondayButtonStyle.submitButton:first-child {
    width: 110px;
  }
  .documentationPreviewContainer .saveButton {
    position: relative;
    bottom: 110px !important;
    right: 50px;
    background-color: #71cf48 !important;
  }
}
