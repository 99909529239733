@import "../../../../../assets/globalVariables.scss";

.active-users-wrapper {
  height: calc(100% - 10px);
  overflow-y: scroll;
  padding: 10px 10px 5px 10px;
  margin-bottom: 10px;
  color: red;
  &.dark {
    h4, a {
      color: $white !important;
    }
    .ant-list-item {
      border-block-end: 1px solid rgba(251, 251, 251, 0.06);
    }
    .ant-list-item-meta-description {
      color: rgba(251, 251, 251, 0.45) !important;
    }
  }
}
