.dashboard {
  display: flex;
  flex-direction: column;
  .dashboard-first-row {
    display: flex;
    flex-direction: row;
    // gap: 40px;
    // margin-left: 40px;
    margin-top: 10px;
    margin-right: 20px;
    margin-bottom: 20px;
  }
  .dashboard-second-row {
    display: flex;
    margin-top: 20px;
    flex-wrap: nowrap;
  }
  .logo-spinner {
    animation: rotation 5s infinite linear;
  }

  //Admin card scroll
  //Leave Request
  .requests {
    overflow-y: scroll;
    height: 180px;
    white-space: nowrap;
  }
  //Work Events
  .events {
    overflow-y: scroll;
    height: 160px;
    white-space: nowrap;
  }
  //Employees names card
  .content {
    overflow-y: scroll;
    height: 160px;
    white-space: nowrap;
  }
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}

@media (max-width: 950px) {
  .dashboard .dashboard-first-row {
    grid-template-columns: auto auto;
    gap: 20px;
    display: grid;
    padding-right: 20px;
  }
  .dashboard-second-row {
    grid-template-columns: auto auto;
    gap: 20px;
    display: grid;
  }
  .ant-col.ant-col-16.col-header-name {
    justify-content: flex-start !important;
    padding-left: 30px;
  }
  .small-card {
    margin-left: 14px;
  }
  .notification-container .notification {
    font-size: 10px !important;
  }
}

@media (max-width: 930px) {
  .ant-col.ant-col-16.konfig-header {
    .user-title {
      h1 {
        font-size: small !important;
        margin-left: 30px;
      }
    }
  }
}

@media (max-width: 820px) {
  .ant-col.ant-col-6.calendar-col {
    max-width: 42%;
  }
  // .punonjesit-cards {
  //   grid-template-columns: auto auto;
  // }
  .pagat-fitimet-setings .ant-col {
    width: 50% !important;
  }
  // .ant-layout-sider-trigger {
  //   display: none !important;
  // }
  .pay {
    .ag-grid-punojsit {
      margin-left: 0px;
      width: 99% !important;
    }
  }
  .admin-dashboard {
    .drag-card-wrapper {
      width: 100% !important;
    }
    // .total-small-card{
    //   width: 340px !important;
    // }
    .birthday-card-kryefaqja {
      // width: 340px !important;
      span.title-text {
        margin-right: 50px !important;
      }
    }
    // .blured-card-wrapper{
    //   // width: 340px !important;
    // }
    .wrap {
      // width: 340px !important;
      .add-request {
        margin-top: 3px;
        width: 304px;
      }
    }
    .ant-row.leave-request-row {
      width: 99% !important;
    }
    .clock-in-dashboard-card {
      width: 340px;
    }
  }
}

// @media only screen and (min-width: 745px) and (max-width: 851px) {
//   .sortable-list-container {
//     display: grid;
//     justify-items: center;
//   }
//   .drag-card-wrapper {
//     width: 100%;
//     .user-request {
//       text-align: center;
//       svg {
//         margin-left: 0px !important;
//       }
//     }
//   }
//   .sortable-item-style {
//     width: 550px !important;
//   }
//   .timesheet-wrapper {
//     display: grid;
//   }
//   .timesheet-wrapper .timesheet-statistics {
//     width: 100%;
//     margin-top: 0px !important;
//     height: 300px !important;
//   }
//   .timesheet-wrapper .timesheet-calculations {
//     width: 650px !important;
//     height: 590px !important;
//     overflow: auto;
//   }
// }

@media (max-width: 768px) {
  .ant-row.lajmerime-post-row {
    display: grid !important;
    .permbajtja-lajmerimit {
      width: 220px !important;
    }
    input.ant-input {
      width: 200px !important;
    }
  }
  .notification-post-container .notification-post-header {
    justify-content: flex-start !important;
    width: 220px !important;
  }
  .notification-post-container {
    padding-left: 10px;
  }
  .dashboard .dashboard-first-row {
    display: grid;
    grid-template-columns: auto;
  }
  .ant-col.ant-col-6.calendar-col {
    max-width: 45%;
  }
  .dashboard .content {
    text-align: center;
  }
  // .punonjesit-cards {
  //   grid-template-columns: auto auto;
  // }
  .ant-modal.perdoruesit-filter .ant-modal-content {
    width: 768px;
    margin-left: -16px;
  }
}
@media (max-width: 440px) {
  .dashboard .dashboard-first-row {
    grid-template-columns: auto;
  }
  .ag-grid-punojsit .ag-grid-header input.ant-input {
    width: 120px !important;
  }
  .ag-grid-punojsit {
    margin-left: 10px;
  }
  .ant-tabs > .ant-tabs-nav .ant-tabs-nav-list,
  .ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-list {
    overflow: auto;
  }
}

@media (max-width: 450px) {
  .weeks-info {
    font-size: 14px;
    span.dashes {
      display: none !important;
    }
    .small-circle {
      margin-right: 10px !important;
    }
  }
  .ant-popover.ant-popconfirm.popup-logout-modal.ant-popover-placement-left {
    width: 350px !important;
    height: 70px;
  }
  .cart-responsive {
    width: 300px !important;
  }
  .drag-card-wrapper {
    width: 100% !important;
  }
  .sortable-item-style {
    width: 95% !important;
  }
  .clock-in .clock-in-active {
    width: 100%;
  }
  .clock-in .clock-in-active .clock-in-content {
    display: grid;
    align-items: center;
    justify-items: center;
    margin-top: 0px !important;
    gap: 14px;
  }
  .weekly-monthly .weekly-picker {
    width: 260px !important;
  }
  .calculations-container {
    width: 100% !important;
    display: flex;
    justify-content: center;
  }
  .info-actions {
    span {
      font-size: 14px !important;
      font-weight: 400;
    }
  }
  button.ant-btn.ant-btn-default.add-request {
    width: 100% !important;
  }
  .weekly-monthly .weekly-header {
    display: grid;
    justify-items: center;
    gap: 10px;
    margin-top: 10px !important;
  }
  .user-request {
    text-align: center;
    svg {
      margin-top: 30px !important;
      margin-left: 0px !important;
      text-align: center;
    }
  }
  .weekly-monthly .monthly-picker {
    width: 250px !important;
  }
  .weeks-info {
    font-size: 14px;
    // span.dashes{
    //   display: none;
    // }
    .small-circle {
      margin-right: 10px !important;
    }
  }
  // .sortable-item-style {
  //   margin-left: 10px !important;
  // }
  .calculations-container .calculation-card {
    width: 80px !important;
    display: flex;
    justify-content: center;
    height: 50px;
    margin-top: 0px;
    font-size: 13px !important;
  }
  .Grafike {
    .date-buttons-container {
      width: 270px;
    }
    .chart-div {
      width: 280px !important;
    }
    .Grafike-chart {
      width: 100% !important;
      div {
        width: 100% !important;
      }
      svg {
        width: 100% !important;
      }
    }
  }
  .clock-in .clock-out-active {
    width: 280px !important;
  }
  .clock-in .clock-out-active .clock-in-info {
    width: 280px !important;
  }
  .ant-row.lajmerime-post-row .permbajtja-lajmerimit {
    width: 100% !important;
    .ant-col-12 {
      max-width: 100% !important;
    }
  }
}

@media (max-width: 391px) {
  .clock-in .clock-in-active {
    width: 100%;
  }
  .cart-responsive {
    width: 260px !important;
  }
  .clock-in .clock-in-active .clock-in-content {
    display: grid;
    align-items: center;
    justify-items: center;
    margin-top: 0px !important;
    gap: 14px;
  }
  .weekly-monthly .weekly-picker {
    width: 260px !important;
  }
  .calculations-container {
    width: 100% !important;
    display: flex;
    justify-content: center;
  }
  .info-actions {
    span {
      font-size: 14px !important;
      font-weight: 400;
    }
  }
  button.ant-btn.ant-btn-default.add-request {
    width: 235px !important;
  }
  .weekly-monthly .weekly-header {
    display: grid;
    justify-items: center;
    gap: 10px;
    margin-top: 10px !important;
  }
  .weekly-monthly .monthly-picker {
    width: 250px !important;
  }
  .weeks-info {
    font-size: 14px;
    span.dashes {
      display: none;
    }
    .small-circle {
      margin-right: 10px !important;
    }
  }
  .sortable-item-style {
    margin-left: 10px !important;
  }
  .calculations-container .calculation-card {
    width: 70px !important;
    display: flex;
    justify-content: center;
    height: 50px;
    margin-top: 0px;
    font-size: 13px !important;
  }
  .Grafike {
    .date-buttons-container {
      width: 270px;
    }
    .chart-div {
      width: 280px !important;
    }
    .Grafike-chart {
      width: 280px !important;
      div {
        width: 280px !important;
      }
      svg {
        width: 280px !important;
      }
    }
  }
  .clock-in .clock-out-active {
    width: 280px !important;
  }
  .clock-in .clock-out-active .clock-in-info {
    width: 280px !important;
  }
  .clock-in .clock-out-active .clock-out-action .clock-out .clock-out-btn {
    width: 110px !important;
  }
}

@media screen and (min-device-width: 900px) and (max-device-width: 1181px) and (orientation: landscape) {
  .ant-row.lajmerime-post-row {
    display: grid !important;
    .permbajtja-lajmerimit {
      width: 400px !important;
    }
  }
  .notification-post-container .notification-post-header {
    justify-content: flex-start !important;
    width: 400px !important;
  }
  .notification-container .notification {
    font-size: 14px;
  }
  .notification-container .notification-open {
    margin-left: 0px;
  }
  .notification-post-container .lajmerimet-conent textarea.ant-input {
    height: 170px;
  }
  .calendar-container {
    margin: 0px 0px 0px 0px !important;
  }
  .ant-col.muaj-button {
    margin-left: 10px;
  }
  .ant-col.ant-col-6.calendar-col {
    margin-left: 10px;
  }
  .ditlindjet-container .ditlindjet-card-content .ditlindjet-card-info {
    margin-left: 0px !important;
  }
  .ditlindjet-container .ditlindjet-card-content .bold {
    font-size: small !important;
  }
  .ditlindjet-container .ditlindjet-card-content .ditlindjet-card-info {
    font-size: small !important;
  }
  .dashboard .dashboard-first-row {
    display: grid;
    grid-template-columns: auto auto !important;
  }
  // .punonjesit-cards {
  //   display: grid;
  //   grid-template-columns: 510px 520px;
  //   gap: 20px;
  //   padding-right: 20px;
  // }
  // .ant-layout-sider-trigger {
  //   display: none !important;
  // }
  .ant-row.employe-view-row {
    display: grid;
    grid-template-columns: 540px 540px;
    .ant-col.ant-col-6 {
      max-width: 100%;
      margin-bottom: 10px;
    }
  }
  .pay {
    .punonjesit {
      .ag-grid-punojsit {
        margin-left: 0px;
      }
    }
  }
}
@media screen and (min-device-width: 900px) and (max-device-width: 1024px) and (orientation: landscape) {
  .ant-row.lajmerime-post-row {
    display: grid !important;
    .permbajtja-lajmerimit {
      width: 330px !important;
    }
  }
  .notification-post-container .notification-post-header {
    justify-content: flex-start !important;
    width: 330px !important;
  }
  .notification-container .notification {
    font-size: 14px;
  }
  .notification-container .notification-open {
    margin-left: 0px;
  }
  .calendar-container {
    margin: 0px 0px 0px 0px !important;
  }
  .ant-col.muaj-button {
    margin-left: 10px !important;
  }
  .ant-col.ant-col-6.calendar-col {
    margin-left: 10px !important;
  }
  .ditlindjet-container .ditlindjet-card-content .ditlindjet-card-info {
    margin-left: 0px !important;
  }
  .ditlindjet-container .ditlindjet-card-content .bold {
    font-size: small !important;
  }
  .ditlindjet-container .ditlindjet-card-content .ditlindjet-card-info {
    font-size: small !important;
  }
  .ant-col.ant-col-6.calendar-col {
    max-width: 28%;
  }
  .dashboard .dashboard-first-row {
    display: grid;
    grid-template-columns: auto auto !important;
  }
  // .punonjesit-cards {
  //   display: grid;
  //   grid-template-columns: 440px 440px;
  //   gap: 20px;
  //   padding-right: 20px;
  // }
  .ag-grid-punojsit .ag-grid-header input.ant-input {
    width: 120px !important;
  }
  .ag-grid-punojsit button.ant-btn.pastro-btn {
    margin-left: 0px;
    margin-right: 10px;
    width: 70px;
  }
  // .ant-layout-sider-trigger {
  //   display: none;
  // }
  .ant-row.employe-view-row {
    display: grid !important;
    grid-template-columns: 460px 460px !important;
    .ant-col.ant-col-6 {
      max-width: 100% !important;
      margin-bottom: 10px;
    }
  }
  .container {
    display: grid;
    .position-item {
      width: 96%;
    }
    div#supervisor {
      margin-left: 30px;
    }
  }
  .ant-modal.perdoruesit-filter .ant-modal-content {
    margin-left: -116px;
  }
}

@media (max-width: 450px) {
  .admin-dashboard .drag-card-wrapper {
    overflow: auto;
  }
}
