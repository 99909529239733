@import "../../assets/globalVariables.scss";

.menu-drawer,
aside.ant-layout-sider.ant-layout-sider-dark {
  // Autumn theme
  background: #fbfbfb;
  //comment on winter ^^
  width: 270px;
  max-width: 270px;
  &:not(.ant-drawer-content-wrapper) {
    width: 100% !important;
    max-width: unset;
  }
  min-width: 71px;
  display: flex;
  max-height: -webkit-fill-available;
  min-height: 100vh;
  box-shadow: 0px 1px 4px 0px #00000029;
  //winter
  // background: radial-gradient(ellipse at bottom, #1b2735 0%, #1d3445 100%);
  .ant-layout-sider-children {
    width: 100%;
  }
  .main-sidebar {
    li,
    .Focus-Show {
      background: #fbfbfb;
      //winter
      // background: transparent;
      display: flex;
      flex-direction: row;
      justify-content: center;
      width: 100%;
      padding-left: 10px !important;
      font-family: "Open Sans", sans-serif;
      font-size: 14px;
      line-height: 19px;
      font-weight: 400;
      color: #1d3445;
      //winter
      // color: #dce4e9;
      a {
        color: #1d3445 !important;
        //winter
        // color: #dce4e9 !important;
      }
      &:hover {
        background-color: #dce4e9 !important;
        a {
          color: #1d3445 !important;
        }
        svg {
          fill: #1d3445 !important;
        }
      }
      .iconWrapper {
        display: flex;
        justify-content: center;
        align-items: center;
        svg {
          width: 20px;
          height: 20px;
          fill: #1d3445 !important;
          //winter
          // fill: #dce4e9;
        }
      }
      &.ant-menu-item-selected,
      &.Focus-Show.sigurt {
        background-color: #dce4e9 !important;
        //winter
        color: #1d3445 !important;
        a {
          color: #1d3445 !important;
        }
        svg {
          fill: #1d3445;
        }
      }
    }
  }
  .dark {
    .ant-menu-light.mobile-menu,
    .main-sidebar {
      background: none;
      li,
      .Focus-Show {
        background: $d-black1 !important;
        color: $white;
        a {
          color: $white !important;
        }
        &:hover {
          color: $white;
          background-color: $d-black2 !important;
          a {
            color: $white !important;
          }
          svg {
            fill: $white !important;
          }
        }
        .iconWrapper svg {
          fill: $white !important;
        }
        &.ant-menu-item-selected,
        &.Focus-Show.sigurt {
          background-color: $d-black2 !important;
          //winter
          color: $white !important;
          a {
            color: $white !important;
          }
          svg {
            fill: $white;
          }
        }
      }
    }
  }
}

.ant-layout-sider-trigger {
  background: #1d3943 !important;
  // Autumn theme
  // background: #1d3445 !important;
  transition: width 1s;
  position: absolute !important;
}
.ant-layout-sider {
  transition: width 1.8s !important;
}

.sidebar-logo {
  width: 160px;
  height: 100px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  // border-radius: 50%;
  margin-left: 50px;
  justify-content: center;
  align-items: center;
  display: flex;
  margin-top: 30px;
  margin-bottom: 30px;
}
// .sidebar-holidayLogo {
//   width: 250px;
//   height: 200px;
//   position: absolute;
//   top: 35%;
//   left: 0;
//   z-index: 10000;
//   animation: fly 12s linear 1 forwards;
//   border: 0;
//   svg#Layer_1 {
//     position: absolute;
//     z-index: 99999;
//   }
//   svg#redAndBlackLogo {
//     position: absolute;
//     bottom: -58px;
//     right: 0px;
//     z-index: 1;
//     width: 160px;
//     transform-origin: bottom right;
//     transform: rotate(10deg);
//   }
// }
// @keyframes drop {
//   0% {
//     transform: translateX(0%) translateY(-150px) rotate(10deg);
//     transform-origin: bottom right;
//   }
//   80% {
//     transform: translateX(0%) translateY(0px) rotate(10deg);
//     transform-origin: bottom right;
//   }
//   100% {
//     transform-origin: bottom right;
//     transform: rotate(0deg);
//   }
// }
// @keyframes fly {
//   0% {
//     transform: translateX(-150%) translateY(-100px);
//   }
//   10% {
//     transform: translateX(-80%) translateY(-150px);
//   }
//   25% {
//     transform: translateX(-16%) translateY(-512px);
//   }
//   27% {
//     transform: translateX(-16%) translateY(-512px);
//   }
//   50% {
//     transform: translateX(300%) translateY(-250px);
//   }
//   75% {
//     transform: translateX(550%) translateY(-425px);
//   }
//   100% {
//     // transform: translateX(-16%) translateY(-512px);
//     transform: translateX(800%) translateY(-700px);
//   }
// }
.sidebar-logo img {
  border-radius: 50%;
}

.side-bar .sidebar-icona {
  padding-right: 10px;
}

.side-bar ul.ant-menu.ant-menu-root.ant-menu-inline.ant-menu-light {
  // background: #1D3445;
  //Autunm theme
  // background: #fbfbfb !important;

  background: transparent;
  //comment on winter ^^
  border: none;
  font-size: larger;
  transition: width 1.8s;
}

.side-bar {
  .ant-menu-item::after {
    width: 0;
    height: 0;
    margin-top: auto;
    margin-bottom: auto;
    border-top: 10px solid transparent !important;
    border-right: 10px solid #1d3445 !important;
    border-bottom: 10px solid transparent !important;
  }
  &.dark .ant-menu-item::after {
    border-right: 10px solid #fbfbfb !important;
  }
}
aside.ant-layout-sider.ant-layout-sider-dark.ant-layout-sider-collapsed.ant-layout-sider-has-trigger {
  .sidebar-logo {
    width: 80px;
    height: 180px;
    padding: 0px;
    margin: 0px;
    border-radius: 0px;
    // background: url(../../assets/images//flexicon-07.png);
    // background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    svg#Group_6130 {
      display: none;
    }
  }
  .sidebar-user-name {
    width: 100%;
    display: flex;
    padding-left: 0px;
    justify-content: center;
    align-items: center;
    h3 {
      display: none;
    }
  }
}

aside.ant-layout-sider.ant-layout-sider-dark.ant-layout-sider-has-trigger {
  .ant-layout-sider-trigger {
    width: 100% !important;
    height: 70px !important;
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;
    margin-left: 15px;
    background: transparent !important;
    span.anticon.anticon-left {
      background: #fff;
      border-radius: 5px;
      width: 32px;
      height: 32px;
      display: flex;
      justify-content: center;
      align-items: center;
      box-shadow: 0px 1px 4px #00000029;
      svg {
        background: url("../../assets/icons/sidebar_iconClose.svg") no-repeat;
        background-position: center;
        background-size: contain;
        width: 14px !important;
        height: 14px !important;
        z-index: 200;
      }
    }
    span.anticon.anticon-right {
      background: #fff;
      border-radius: 5px;
      width: 32px;
      height: 32px;
      display: flex;
      justify-content: center;
      align-items: center;
      box-shadow: 0px 1px 4px #00000029;
      padding: 0px !important;
      svg {
        background: url("../../assets/icons/sidebar_iconOpen.svg") no-repeat;
        background-position: center;
        background-size: contain;
        width: 14px !important;
        height: 14px !important;
        z-index: 200;
      }
    }
  }
}

// aside.ant-layout-sider.ant-layout-sider-dark.ant-layout-sider-collapsed.ant-layout-sider-has-trigger {
//   li.ant-menu-item.sigurt {
//     // padding-left: 32px !important;
//   }
// }

.ant-menu.ant-menu-inline-collapsed > .ant-menu-item,
.ant-menu.ant-menu-inline-collapsed {
  width: 79px;
  flex: 0 0 80px;
  max-width: 80px;
  min-width: 80px;
  width: 80px;
  height: 50px;
  padding-top: 5px !important;
  margin-top: 3px !important;
  margin-bottom: 3px !important;
}
.side-bar {
  .ant-menu.ant-menu-inline-collapsed {
    .ant-menu-item {
      padding: 0 !important;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .iconWrapper > * {
      display: flex;
      align-items: center;
    }
  }
}

// .ant-tooltip.ant-menu-inline-collapsed-tooltip.ant-tooltip-placement-right {
//   .ant-tooltip-inner {
//     background: #1d3445 !important;
//     height: 50px;
//     justify-content: flex-start !important;
//     padding-right: 15px !important;
//     align-items: center;
//     display: flex;
//     font-size: medium;
//     margin-left: -15px;
//     border-bottom-right-radius: 5px !important;
//     border-top-right-radius: 5px !important;
//     box-shadow: none !important;
//   }
//   .ant-tooltip-arrow {
//     display: none !important;
//   }
// }

.side-bar {
  svg#Layer_1 {
    fill: #1da193 !important;
  }
}
.pagatPopconfirm {
  .ant-popconfirm-inner-content {
    padding: 5px 10px;
    gap: 10px;
    .ant-popconfirm-message {
      padding: 0 !important;
    }
    .ant-popconfirm-buttons {
      display: flex;
      gap: 5px;
      .ant-btn {
        height: 28px;
        width: 60px;
      }
      .ant-btn-variant-outlined {
        color: #ea3943;
        background-color: #fff;
        border-color: #ea3943;
        &:hover {
          color: #fff;
          background-color: #ea3943;
          border-color: #ea3943;
        }
      }
    }
  }
}

.sidebar-user-name {
  // width: 100%;
  height: 50px;
  h3 {
    margin: 0px;
    font-size: 16px;
    font-weight: 400;
    color: #fff;
    font-family: "Open Sans", sans-serif;
    margin-left: 5px;
    font-variant: tabular-nums;
    font-feature-settings: "tnum";
  }
  display: flex;
  justify-content: flex-start;
  padding-left: 23px;
  align-items: center;
}
// .sidebar-user-name:hover {
//   background: #1d3445;
// }

.ant-tooltip.ant-menu-inline-collapsed-tooltip.ant-tooltip-placement-right {
  .ant-tooltip-content {
    margin-left: -4px;
  }
}
// .Focus-Show {
//   color: #f5f5f7;
//   background: #1d3445;
// }

//winter
@function random_range($min, $max) {
  $rand: random();
  $random_range: $min + floor($rand * (($max - $min) + 1));
  @return $random_range;
}
.snowflake {
  $total: 100;
  position: absolute;
  width: 20px;
  height: 20px;
  background-image: url("../../assets/holidays/icons/snowflake.svg");

  @for $i from 1 through $total {
    $random-x: random(2700000) * 0.0001px;
    $random-offset: random_range(-100000, 100000) * 0.0001px;
    $random-x-end: $random-x + $random-offset;
    $random-x-end-yoyo: $random-x + ($random-offset / 2);
    $random-yoyo-time: random_range(30000, 80000) / 100000;
    $random-yoyo-y: $random-yoyo-time * 100vh;
    $random-scale: random(10000) * 0.0001;
    $fall-duration: random_range(60, 80) * 1s;
    $fall-delay: random(100) * -1s;

    &:nth-child(#{$i}) {
      opacity: random(10000) * 0.0001;
      transform: translate($random-x, -10px) scale($random-scale);
      animation: fall-#{$i} $fall-duration $fall-delay linear infinite;
    }

    @keyframes fall-#{$i} {
      #{percentage($random-yoyo-time)} {
        transform: translate($random-x-end, $random-yoyo-y) scale($random-scale);
      }

      to {
        transform: translate($random-x-end-yoyo, 200vh) scale($random-scale);
      }
    }
  }
}
