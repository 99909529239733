.header-filter-buttons {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  gap: 10px;
}

.active-filter {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 135px;
  height: 32px;
  background: #f1f7fb 0% 0% no-repeat padding-box;
  border-radius: 5px;
  opacity: 1;
  // color: white;
  border: none;
  text-decoration: none;
  &:hover {
    cursor: pointer;
  }
}

.inactive-filter {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 135px;
  height: 32px;
  background: #1da193 0% 0% no-repeat padding-box;
  color: white;
  border-radius: 5px;
  opacity: 1;
  border: none;
  text-decoration: none;
  &:hover {
    cursor: pointer;
  }
}
