@import "../../../../assets/globalVariables.scss";

.new-shift-wrapper {
  display: flex;
  flex-direction: column;
  gap: 10px;
  .warning-title {
    display: flex;
    align-items: center;
    gap: 10px;
    width: 100%;
    font: normal normal normal 14px/19px Open Sans;
    letter-spacing: 0px;
    color: #ea3943;
    .warn-icon {
      margin-right: 10px;
      margin-top: 5px;
    }
  }
  // Shortcuts
  .shortcuts {
    display: flex;
    justify-content: space-between;
    .shortcut-btn {
      display: flex;
      justify-content: center;
      padding: 0 10px 0 10px;
      border-radius: 5px;
      height: 25px;
      align-items: center;
      /* UI Properties */
      color: white;
      background: #7284a8 0% 0% no-repeat padding-box;
      &:hover {
        cursor: pointer;
        opacity: 0.9;
      }
    }
  }
  .to-edit-fields {
    display: flex;
    flex-direction: row;
    gap: 20px;
    align-items: center;
    label {
      margin-left: 5px;
    }
    .ant-select {
      width: 100%;
    }
    .ant-picker {
      width: 100%;
      // background-color: #f1f7fb;
      // border: none;
    }
    .ant-select-clear {
      background-color: #f1f7fb !important;
    }
    .shift-field-start {
      flex: 1 1 0 !important;
      display: flex;
      flex-direction: column;
      margin: 0 !important;
    }
    .shift-field-end {
      flex: 1 1 0 !important;
      margin: 0 !important;
      display: flex;
      flex-direction: column;
      .multiple-pick {
        width: 140px;
        height: 32px;
        /* UI Properties */
        background: #1da193 0% 0% no-repeat padding-box;
        margin-top: 20px;
        color: white;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 5px;
        cursor: pointer;
      }
      .rmdp-input {
        height: 30px;
        width: 100%;
      }
    }
    .to-note {
      margin-top: 30px;
      display: flex;
      flex-direction: column;
      margin-left: 0px;
      margin-bottom: 20px;
      // justify-content: center;

      textarea.ant-input.to-note-textarea {
        margin-left: 0px;
        width: 780px;
        height: 81px;
        /* UI Properties */
        background-color: #f1f7fb;
        border-radius: 5px;
        border: none;
        opacity: 1;
      }
    }
  }
  .animated-hours {
    width: 100%;
    flex: 1;
    background-color: #f1f7fb;
    border-radius: 5px;
    height: 32px;
    .calculated-hours {
      background-color: #1da193 !important;
      // background: linear-gradient(90deg, hsla(175, 79%, 63%, 1) 0%, hsla(82, 96%, 57%, 1) 100%);
      border-radius: 5px;
      height: 100%;
      animation: chartAnimation 1.9s;
    }
  }
  &.dark {
    color: $white;
    .warning-title {
      color: #f0747b;
      svg {
        fill: currentColor;
      }
    }
    .animated-hours {
      background-color: transparent;
      // border: 1px solid $d-black1;
      box-shadow: 0px 0px 2px $white;
    }
  }
}
@keyframes chartAnimation {
  from {
    width: 0;
  }
  to {
    width: 1;
  }
}

@media (max-width: 450px) {
  .new-shift-wrapper {
    .ant-modal-body {
      height: fit-content !important;
      .shortcuts {
        display: grid;
        grid-template-columns: 1fr 1fr;
      }
      .to-edit-fields {
        display: grid !important;
        gap: 5px !important;
        margin-left: -10px !important;
        .shift-field-start {
          margin-left: 20px !important;
        }
      }
    }
  }
  .new-shift-wrapper .new-shift .new-shift-wrapper .warning-title {
    width: 100% !important;
  }
}
@media (max-width: 390px) {
  .new-shift-wrapper .to-edit-fields {
    display: grid !important;
    gap: 5px !important;
    margin-left: -10px !important;
    .shift-field-start {
      margin-left: 20px !important;
    }
  }
  .new-shift-container .ant-modal-body {
    height: fit-content !important;
  }
}
