.menyrat-pageses {
  width: 100%;
  height: 100%;
}

@media (max-width: 391px) {
  .ant-col.responsive-col-grid-pagesa {
    flex-wrap: wrap;
    gap: 6px;
  }
}
