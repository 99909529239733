@import "../../../../../assets/globalVariables.scss";

.user-action-card {
  // margin-left: 20px;
  // margin-top: 20px;
  // top: 139px;
  // left: 694px;
  width: 100%;
  height: 470px;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 1px 4px #00000029;
  border-radius: 10px;
  opacity: 1;
  // margin-left: -10px;
  .user-action-header {
    width: 100%;
    height: 45px;
    /* UI Properties */
    background: #1d3445 0% 0% no-repeat padding-box;
    border-radius: 10px 10px 0px 0px;
    opacity: 1;
    display: flex;
    align-items: center;
    .user-header-title {
      color: white;
      margin-left: 20px;
      font: normal normal 600 18px/24px Open Sans;
    }
  }
  &.dark {
    color: $white;
    background: $d-black3;
    box-shadow: 0px 0px 5px #75eade;
    .user-action-header {
      background: $d-black1;
    }
  }
}

.ant-card.ant-card-bordered.userPersonalInfo {
  border-top-left-radius: 0px !important;
  border-top-right-radius: 0px !important;
}

.ant-card.ant-card-bordered.contactInfoCard {
  border-top-left-radius: 0px !important;
  border-top-right-radius: 0px !important;
}

.ant-card.ant-card-bordered.employeePersonalInfoCard {
  border-top-left-radius: 0px !important;
  border-top-right-radius: 0px !important;
}

@media (max-width: 391px) {
  .user-action-card {
    width: 290px;
    margin-left: -3px;
  }
}
