.employeeAccesses {
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;
  height: 100%;
  .accessesHeader {
    display: flex;
    width: 100%;
    background-color: #fff;
    //winter
    // background-color: rgba(251, 251, 251, 0.6);
    border: 1px solid #a0a0a0;
    border-radius: 10px;
    box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.15);
    padding: 10px;
    .employeePfp {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 200px;
      height: 200px;
      min-width: 200px;
      div {
        width: inherit;
        height: inherit;
        align-content: center;
        text-align: center;
      }
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border: #1d3445 solid 2px;
        padding: 3px;
        border-radius: 10px;
      }
    }
    .employeeData {
      display: flex;
      gap: 10px;
      width: 100%;
      .dataBlock {
        flex: 1 1 0;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 10px;
        text-align: center;
        .ant-select {
          padding: 0 10px;
          width: 200px;
          //winter
          // background: rgba(245, 245, 247, 0.5);
        }
        .enable2FaSwitch {
          display: flex;
          justify-content: center;
          align-items: center;
          width: max-content;
          white-space: nowrap;
          font-weight: 600;
          gap: 10px;
          .ant-form-item {
            margin: 0 20px;
          }
        }
        .flexButtons {
          display: flex;
          gap: 10px;
        }
        .employeeName {
          text-align: center;
        }
        h1 {
          font-size: medium;
          font-weight: 400;
        }
        h3 {
          margin: 0px;
          font-size: large;
        }
        a {
          color: #1da193;
        }
      }
    }
  }
  .accessesData {
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 100%;
    height: 100%;
    border-radius: 10px;
    overflow-y: hidden;
  }
  &.dark {
    .accessesHeader,
    .accessesData {
      background: none;
      border: none;
      box-shadow: 0 0 5px #75eade;
    }
  }
}

@media (max-width: 1366px) {
  .employeeAccesses {
    .accessesHeader {
      gap: 20px;
      .flexButtons {
        flex-direction: column;
        align-items: center;
      }
    }
  }
}
@media (max-width: 850px) {
  .employeeAccesses {
    h1 {
      font-size: medium;
      font-weight: 400;
    }
    h3 {
      margin: 0px;
      font-size: larger;
    }
    .accessesHeader {
      gap: 10px;
      .employeePfp {
        width: auto;
        height: auto;
        flex-direction: column;
        gap: 10px;
        min-width: 100px;
        text-align: center;
        img {
          width: 100px;
          height: 100px;
        }
      }
      .employeeData {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-template-rows: repeat(2, 1fr);
        grid-gap: 10px;
        h3 {
          font-size: medium !important;
        }
        .dataBlock:nth-child(1) {
          grid-area: 1 / 2 / 3 / 3;
        }
        .dataBlock:nth-child(2) {
          grid-area: 1 / 3 / 3 / 4;
          div {
            flex-direction: column;
            //working here
          }
        }
        .dataBlock:nth-child(3) {
          grid-area: 1 / 1 / 2 / 2;
        }
        .dataBlock:nth-child(4) {
          grid-area: 2 / 1 / 3 / 2;
        }
      }
    }
  }
}
@media (max-width: 450px) {
  .employeeAccesses {
    .accessesHeader {
      flex-direction: column;
      .employeePfp {
        display: grid;
        grid-template-columns: 1fr 3fr;
        .dataBlock:nth-child(3) {
          grid-area: 1 / 2 / 2 / 3;
        }
        .dataBlock:nth-child(4) {
          grid-area: 2 / 2 / 3 / 3;
        }
      }
      .employeeData {
        display: flex;
      }
    }
  }
}
