//* responsive DepartmentEmployeeView for iPad Air
@media (max-width: 820px) {
  .ant-modal.departmentEmployeeViewModal {
    width: 790px !important;
    span.employeeRoleLabel {
      margin-right: 140px;
      margin-left: 11px;
    }
    span.dateOfEmployeement {
      margin-right: 30px;
    }
  }
  .ant-modal.departmentEmployeeViewModal .ant-modal-content {
    right: 0 !important;
    width: 100% !important;
  }
}

//* responsive DepartmentEmployeeView for iPhone
@media (max-width: 450px) {
  .ant-modal.departmentEmployeeViewModal {
    width: 400px !important;
    span.employeeRoleLabel {
      margin-right: 10px;
      margin-left: 10px;
    }
    span.dateOfEmployeement {
      margin-right: 10px;
    }
    .employeeNameDiv .nameDiv {
      display: grid;
      width: 100%;
      justify-content: center;
      border-bottom: 1px solid #1d3445;
    }
    .dateOfEmployeementDiv {
      display: flex !important;
      justify-content: flex-end !important;
    }
    .employeeRoleDiv .employeeRoleName {
      display: flex !important;
      justify-content: center !important;
    }
    span.dateOfEmployeement {
      font-size: 12px !important;
    }
  }
  .ant-modal.departmentEmployeeViewModal .ant-modal-content {
    right: 0 !important;
    width: 100% !important;
  }
}

//* responsive DepartmentEmployeeView for iPhone 12 Pro
@media (max-width: 391px) {
  .ant-modal.departmentEmployeeViewModal {
    width: 100% !important;
    .departmentEmployeesName {
      padding: 0px !important;
      width: 105% !important;
    }
    .dateOfEmployeementDiv {
      margin-right: 0px !important;
    }
    span.dateOfEmployeement {
      font-size: 12px !important;
    }
  }
}
