.mobileAgGridWrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
  flex: 1;
  overflow-y: auto;
  .ant-checkbox-wrapper {
    display: flex;
    align-items: center;
    padding-left: 5px;
    .ant-checkbox-inner {
      padding: 10px;
      border-radius: 5px;
      border-color: #19354d;
    }
    .ant-checkbox-checked {
      .ant-checkbox-inner {
        //winter
        background-color: #19354d !important;
        border-color: #19354d !important;
      }
    }
  }
  .mondayButtonContainer {
    margin: 5px 15px 10px;
  }
  .mobileAgContent {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 5px;
    width: 100%;
    height: 100%;
    padding-right: 2px;
    overflow-y: auto;
    overflow-x: hidden;
    .ant-alert {
      text-align: center;
      padding: 10px 30px;
      width: max-content;
    }
  }
}
