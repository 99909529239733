.ant-modal-wrap.sistmei-perdoruesi-modal.ant-modal-centered {
  label.ant-form-item-required {
    font-size: medium;
    font-weight: 500;
  }
  .ant-modal-content {
    border-radius: 10px;
  }
  input[type="number"]::-webkit-inner-spin-button,
  input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  .ant-col.ant-form-item-label {
    margin-right: 10px;
    width: 200px;
    label.ant-form-item-required {
      width: 200px;
      float: right;
      display: flex;
      justify-content: flex-end;
    }
  }
  .modal-logo {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
  }
  .react-tel-input.number-input {
    background: #f5f5f7;
    border-radius: 5px;
    input.form-control {
      background: transparent;
      border: none;
    }
    .flag-dropdown {
      border-top-left-radius: 5px;
      border-bottom-left-radius: 5px;
    }
  }
  .ant-form-item-control-input-content {
    justify-content: space-between;
    display: flex;
  }
  .ant-modal-footer{
    .ant-form-item{
      width: 100%;
      margin: 0;
      .mondayButtonGreen{
        min-width: 120px;
      }
    }
  }
}

.ant-popover.ant-popconfirm.popup-perdoruesit-modal.ant-popover-placement-top {
  // width: 829px !important;
  height: 70px;
  top: 35px !important ;
  left: 540px !important;
  .ant-popover-inner-content {
    height: 70px !important;
    display: flex;
    padding: 0;
    justify-content: center;
    align-items: center;
    padding: 0px;
  }
  .ant-popover-inner {
    border-radius: 10px !important;
  }
  .ant-popover-message {
    display: flex;
    justify-content: center;
    align-items: center;
    // width: 86%;
    gap: 14px;
    padding: 0px 20px;
    .ant-popover-message-icon {
      margin: 0;
    }
    .ant-popover-message-title {
      font: normal normal normal 16px "Open Sans";
      letter-spacing: 0;
      color: #323338;
      opacity: 1;
    }
  }
  .ant-popover-buttons {
    height: 70px;
    margin: 0px;
    // padding-left: 8.2px;
    button.ant-btn.ant-btn-sm {
      width: 50px;
      height: 70px;
      background: #ff4e00;
      border: none;
      color: #fff;
      border-radius: 0px;
      cursor: pointer;
    }
    button.ant-btn.ant-btn-primary.ant-btn-sm {
      width: 50px;
      height: 70px;
      border: none;
      background: #1da193;
      margin: 0px;
      color: #fff;
      border-radius: 0px 8px 8px 0px;
      cursor: pointer;
    }
  }
}

.perdoruesit {
  padding-top: 20px;
  width: 100%;
  height: 100%;
  path#Path_7433 {
    fill: #1d3445;
  }
}

button.ant-btn.perdorues-te-ri-btn {
  background: #1da193 !important;
  color: #fff;
  border-radius: 5px;
  margin-right: 30px;
}

.ant-modal.perdoruesit-filter {
  top: 10px;
  .ant-modal-header {
    background: #1d3445;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    height: 45px;
    display: flex;
    align-items: center;
    .ant-modal-title {
      color: #fff;
    }
  }
  .ant-modal-body {
    padding: 20px;
  }
  .ant-modal-close {
    height: 45px;
    width: 45px;
  }
  span.ant-modal-close-x {
    justify-content: center;
    align-items: center;
    display: flex;
    color: #fff;
    height: 45px;
  }
  .ant-modal-content {
    border-radius: 5px;
    width: 800px;
  }
  .ant-modal-footer {
    height: 45px;
    align-items: center;
    justify-content: flex-end;
    display: flex;
  }
  button.ant-btn.charge-search-btn {
    background: #1da193;
    color: #fff;
    border-radius: 5px;
    width: 90px;
  }
  button.ant-btn.charge-close-btn {
    background: #ea3943;
    color: #fff;
    border-radius: 5px;
  }

  .filter-input {
    background: #f5f5f7;
    border-radius: 5px;
    border: none;
  }
}

.navigate-row {
  color: #1da193;
  font-weight: 600;
  cursor: pointer;
}

.perdoruesit {
  .Print-Punojsit {
    visibility: collapse;
  }
}

.perdoruesit {
  svg#Layer_1 {
    fill: transparent;
  }
  path#Path_6861_00000147205192370109036890000003473032641409739905_ {
    fill: #323338;
  }
}

.Print-Punojsit {
  margin-left: 30px !important;
  // padding-top: 20px;

  table {
    width: 100%;
  }
  thead {
    width: 50%;
  }
  th {
    justify-content: flex-start;
    text-align: start;
    width: 100px !important;
  }

  td {
    width: 200px;
  }

  tr {
    width: 90%;
  }
}

@media (max-width: 830px) {
  .ant-modal.perdoruesit-filter {
    width: auto !important;
  }
  .ant-popover.ant-popconfirm.popup-perdoruesit-modal.ant-popover-placement-top {
    width: 780px !important;
    left: 20px !important;
    top: 950px !important;
    right: 0 !important;
    .ant-popover-message {
      width: 84%;
    }
  }

  .header-first-col {
    form.ant-form.ant-form-horizontal {
      .ant-select.ant-select-borderless.ant-select-single.ant-select-show-arrow {
        width: 100px !important;
      }
    }
  }
  .te-dhenat-akses .header-first-col button.ant-btn.konfirmo-button {
    width: 85px !important;
  }
  .switch-div {
    height: 40px;
  }
  .body-kryefaqja {
    display: block !important;
    h3 {
      width: fit-content !important;
    }
  }
  .header-first-col {
    h3 {
      font-size: 14px !important;
      font-weight: 500;
    }
  }
  .hedaer-second-col {
    h3 {
      font-size: 14px !important;
      font-weight: 500;
    }
  }
  .hedaer-third-col {
    h3 {
      font-size: 14px !important;
    }
  }
  .hedaer-forth-col {
    h3 {
      font-size: 14px !important;
    }
  }
  .te-dhenat-akses .hedaer-forth-col button.ant-btn.te-gjith-btn {
    margin-top: 20px !important;
    width: 130px !important;
    font-size: 12px;
  }
  .card-te-dhenat {
    .card {
      height: 100% !important;
    }
    .ant-card {
      height: 100% !important;
    }
  }
}

@media (max-width: 769px) {
  .te-dhenat-akses .hedaer-forth-col button.ant-btn.te-gjith-btn {
    width: 115px !important;
    font-size: 10px;
  }

  .te-dhenat-akses .hedaer-forth-col button.ant-btn.fshij-btn {
    width: 115px !important;
    font-size: 10px;
    margin-top: 5px;
  }
  .konfigurimet-detjat {
    .ant-row.route-acces-konfigurimet {
      height: 950px !important;
    }
  }
  .konfigurimet-detjat {
    .ant-row.konfigurimet-details-card-row {
      height: 600px;
    }
  }
}

@media (max-width: 390px) {
  .ant-modal-wrap.sistmei-perdoruesi-modal.ant-modal-centered {
    width: fit-content !important;
  }
  .ag-grid-punojsit .ag-grid-header input.ant-input {
    width: 100px !important;
  }
  .ant-row.harder-row {
    button.ant-btn.perdorues-te-ri-btn {
      margin-top: 0px !important;
    }
  }
  .ag-grid-punojsit .header-search {
    margin-left: 7px !important;
    width: 247px;
  }
  .ag-grid-punojsit {
    width: 85%;
    margin-left: 7px;
  }
  .ant-modal.perdoruesit-filter {
    .ant-row {
      display: grid !important;
      grid-template-columns: auto !important;
      .ant-col.ant-col-6 {
        max-width: 100% !important;
      }
    }
    .ant-modal-content {
      width: 292px !important;
    }
  }
  .te-dhenat-akses {
    padding-left: 10px;
    margin-left: 0px !important;
  }

  .te-dhenat-akses .te-dhenat-detajet-header {
    margin: 20px 10px 20px 0px !important;
  }
  .te-dhenat-akses .card-te-dhenat {
    margin-right: 10px !important;
  }
  .konfigurimet-detjat-fotter
    button.ant-btn.ant-btn-default.te-gjith-perdorusit {
    width: 110px;
    font-size: 11px;
    text-align: center;
    padding: 0px;
  }

  button.ant-btn.ant-btn-default.ruaj-ndrryshimet-btn {
    width: 110px;
    font-size: 11px;
    padding: 0px;
    text-align: center;
  }
}
