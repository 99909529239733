@import "../../../../../../assets/globalVariables.scss";

.actions-wrapper {
  background-color: #f1f7fb;
  padding: 20px;
  border-radius: 5px;
  .action-btns {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    .first {
      display: flex;
      flex-direction: row;
      gap: 20px;

      .action-btn {
        width: 115px;
        height: 32px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 5px;
        cursor: pointer;
        /* UI Properties */
        // color: white;
        background: #fff 0% 0% no-repeat padding-box;
        box-shadow: 0px 1px 4px #00000029;
        &:hover {
          border: 1px solid #1da193a5;
          &:last-child {
            border: 1px solid #ea3943a5;
          }
        }
      }
    }

    .finalize {
      display: flex;
      gap: 20px;
      .action-btn {
        width: 155px;
        height: 32px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 5px;
        cursor: pointer;
        color: white;
        background: #1da193 0% 0% no-repeat padding-box;
      }
      .action-btn-delete {
        // width: 115px;
        height: 32px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 5px;
        border: none;
        cursor: pointer;
        color: white;
        width: 89px;
        background: #ea3943 0% 0% no-repeat padding-box;
        button.ant-btn.ant-btn-default {
          width: 89px;
          background: #ea3943 0% 0% no-repeat padding-box;
          color: white;
          opacity: 0.9;
          border-radius: 5px;
        }
      }

      .change-shifts-btn {
        height: 32px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 5px;
        border: none;
        cursor: pointer;
        color: white;
        width: 189px;
        background: #1da193 0% 0% no-repeat padding-box;
      }
    }
  }
  .edit-wage-row {
    display: flex;
    justify-content: space-between;
    gap: 40px;
    border-radius: 5px;
    padding: 20px;
    .editWageItem {
      display: flex;
      flex-direction: column;
      justify-content: center;
      gap: 5px;
      width: 100%;
      label {
        font: normal normal 600 16px/22px Open Sans;
        text-align: left;
        margin-left: 5px;
      }
      .ant-select:not(.ant-select-customize-input) .ant-select-selector {
        position: relative;
        background-color: white;
        border: none;
        border-radius: 5px;
      }
      .ant-input-number-input,
      .ant-input-outlined {
        background-color: white;
      }
      .ant-input,
      .ant-input-number {
        border: none;
        border-radius: 5px;
      }
      .ant-picker,
      .ant-select-selector,
      .ant-input-number {
        width: 100%;
      }
    }
  }
  .add-icon {
    width: 32px;
    height: 32px;
    background: #1da193 0% 0% no-repeat padding-box;
    border-radius: 5px;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }
  &.dark {
    background-color: $d-black2;
    .edit-wage-row .editWageItem {
      > input,
      .ant-input-number-input {
        background: none;
        border: 1px solid $d-black1;
      }
    }
    .action-btns .first .action-btn {
      background: $d-black6;
    }
  }
}
