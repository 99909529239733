@import "../../../../assets/globalVariables.scss";

.dashboard-notifications {
  //   margin: 20px;

  .notifications-title {
    background-color: #f7f8fa;
    // border-radius: 7px;
    display: flex;
    .title {
      margin-top: 10px;
      margin-left: 20px;
      font: normal normal 700 22px/30px Open Sans;
    }
  }
}

.ant-drawer-content.notification-drawer {
  .ant-drawer-header {
    display: none;
    background: #1d3445;
  }
  .ant-drawer-title {
    text-align: center;
    color: #1d3445;
    font-family: Open Sans;
    font-size: 16px;
    font-weight: 600;
    line-height: 22px;
    letter-spacing: 0em;
  }
  .ant-tabs-content-holder {
    padding: 0px 5px;
  }
  .ant-tabs.ant-tabs-top.notification-tabs {
    .ant-tabs-ink-bar {
      background: #323338;
      padding-bottom: 5px;
      border-radius: 5px 5px 0px 0px;
      width: 100%;
      animation: inkBar 0.5s;
    }
    .ant-tabs-nav {
      margin: 0px !important;
      display: flex;
      flex-direction: row-reverse;
      &::before{
        display: none !important;
      }
    }
    .ant-tabs-extra-content {
      height: 100%;
      width: auto;
      .extraContentContainer {
        // width: 300px;
        height: 45px;
        background-color: #e7eef2;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        gap: 20px;
        padding-left: 20px;
        .extraContentTitle {
          font-family: Open Sans;
          font-size: 16px;
          font-weight: 600;
          line-height: 21.79px;
        }
      }
    }
    .ant-tabs-nav-wrap {
      background: #e7eef2;
      justify-content: right;
      padding-right: 35px;
    }
    .ant-tabs > .ant-tabs-nav .ant-tabs-nav-list,
    .ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-list {
      background: #1d3445;
      margin-bottom: -2px;
    }
    span.ant-badge {
      color: #1d3445;
      font-size: medium;
    }
    .ant-tabs-tab.ant-tabs-tab-active {
      // background-color: #ffffff;
      border-top-left-radius: 5px;
      border-top-right-radius: 5px;
      padding-left: 10px;
      padding-right: 10px;
      border-bottom: none !important;
      span.ant-badge {
        color: #323338;
        text-align: center;
      }
    }
  }
  .ant-drawer-body {
    padding: 0px 0px 15px 0px;
    margin-top: -1px !important;
  }
  &.dark {
    .ant-tabs {
      color: $white;
      &.ant-tabs-top.notification-tabs {
        .ant-tabs-nav-wrap {
          background: $d-black1;
        }
        .ant-tabs-extra-content .extraContentContainer {
          background: $d-black1;
        }
        .ant-tabs-ink-bar {
          background: $white;
        }
        .ant-tabs-tab {
          span.ant-badge {
            color: $white;
          }
        }
      }
    }
  }
}

.njoftime-drawer {
  .ant-drawer-body {
    flex-grow: 1;
    padding: 0 !important;
    overflow: auto;
    font-size: 14px;
    // line-height: 1.5715;
    word-wrap: break-word;
    margin-top: -10px;
  }
  .ant-drawer-header {
    display: flex;
    background: #e7eef2 0% 0% no-repeat padding-box;

    .ant-drawer-header-title {
      display: flex;
      justify-content: center;
    }
    .ant-drawer-title {
      color: #1d3445;
      font: normal normal 600 18px/24px Open Sans;
      color: #1d3445 !important;
      text-align: center;
    }
  }
}

@media (max-width: 450px) {
  .single-notif .notif-content .relative-time {
    margin-left: 0px !important;
    font-size: 10px;
  }

  .ant-drawer.ant-drawer-right.ant-drawer-open.notification-drawer
    .single-notif
    .notif-actions {
    width: 50px !important;
    display: grid;
    justify-items: center;
    gap: 5px;
    min-width: 50px !important;
    margin-left: 0px !important;
  }

  .ant-drawer.ant-drawer-right.ant-drawer-open.notification-drawer {
    width: 100% !important;
  }
  .ant-drawer.ant-drawer-right.ant-drawer-open.notification-drawer
    .ant-drawer-content-wrapper {
    width: 100% !important;
  }
  .notifs-list-container .notifs-extra-filters .filter-btn {
    margin-left: 5px !important;
    margin-right: 5px !important;
    width: fit-content !important;
    padding-left: 10px !important;
    padding-right: 10px !important;
    font: normal normal normal 10px/11px Open Sans !important;
    height: 30px;
  }
  .notifs-list-container .selection-options-notifs .selection-btn {
    height: 45px;
  }
  .single-notif .notif-content .notif-body {
    min-width: 87% !important;
  }

  .ant-drawer.ant-drawer-right.ant-drawer-open.req-drawer {
    .ant-drawer-content-wrapper {
      height: 100% !important;
    }
    form#filter-requests {
      display: grid !important;
      button.ant-btn.ant-btn-default.filter-button {
        margin-bottom: 10px !important;
      }
    }
  }
}

@media (max-width: 390px) {
  .ant-typography.ant-typography-ellipsis.ant-typography-single-line.ant-typography-ellipsis-single-line {
    display: flex;
    flex-wrap: wrap;
    width: 200px !important;
    text-overflow: ellipsis;
    min-width: 200px !important;
    overflow: hidden;
    white-space: nowrap;
  }
  // .notifs-list-container{
  //   .notif-time{
  //     span.relative-time{
  //       writing-mode: vertical-rl;
  //     }
  //   }
  // }
  .ant-typography {
    width: 200px !important;
  }
}
