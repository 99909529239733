@import "../../../index.scss";

.mondayButtonContainer {
  width: max-content;
  .mondayButtonDivider {
    z-index: 2;
    height: stretch;
    width: 1px;
    background-color: #1264a3;
    margin: 0 10px;
  }

  .mondayButtonText {
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 1;
    .ant-spin-dot-holder {
      color: #fff !important;
    }
  }
  .documentsMondayButton {
    width: 117px;
    height: 32px;
    border-radius: 5px;
    font-family: Open Sans;
    font-size: 14px;
    font-weight: 400;
    line-height: 19px;
    letter-spacing: 0em;
  }
  .uploaderProblemsButton {
    height: 26px;
  }
  .uploaderContactButton {
    background-color: #1da193 !important;
    height: 26px;
    font-family: Open Sans;
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0em;
    text-align: center;
  }
  .mondayButtonStyle {
    background-color: #0f5c97;
    border-radius: 5px;
    @include flex(center, center);
    @include text(14px, 400, 0, #fff);
    overflow: hidden;
    border: 0;
    padding: 0 10px;
    &:hover,
    &:active,
    &:visited,
    &:focus {
      color: #fff;
      background-color: #0f5c97;
      border-color: #0f5c97;
      .mondayButtonDivider {
        background-color: #0f5c97;
      }
    }
  }

  .mondayButtonIcon {
    fill: #fff;
    z-index: 2;
    margin: 0;
    font-size: 20px;
    @include flex(center, unset);
  }
}
.mondayButtonCreate {
  width: 138px;
  height: 32px;
  background-color: #1da193 !important;
  border-radius: 5px;
}
