@import "../../index.scss";

.cofirmationModalContainer {
  .ant-modal-header {
    height: 45px;
    padding: 0;
 
  //* font-size:16px, font-weight:600, letter-spacing:0, and text color
    .ant-modal-title {
      @include text(16px, 600, 0, #323338);
    }
  }

  .ant-modal-body {
    padding: 20px;
  }

  //* styles question text font-size:6px, font-weight:400, letter-spacing:0, and text color
  .question {
    @include text(6px, 400, 0, #1f2a44);
    margin-bottom: 20px;
  }

  //* styles buttonContainer align-items:center, justify-content:center
  .buttonContainer {
    width: 100%;
    @include flex(center, center);

  //* except last element of the div give every other element margin-rigth:15px
    .ant-btn {
      border-radius: 5px;
      &:not(:last-child) {
        margin-right: 15px;
      }
    }
  }
}
