.calculations-container {
  width: 344px;
  height: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  margin-top: 10px;
  gap: 10px;
  .calculation-card {
    background: #23928100 0% 0% no-repeat padding-box;
    width: 81px;
    height: 86px;
    border-radius: 5px;
    color: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}
