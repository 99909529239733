@import "../../../../../assets/globalVariables.scss";

$card_blue: #e7eef2;
$input_background: #f5f5f7;
$checkbox_color: #1da193;
.activity-modal {
  width: 1095px !important;
  color: #323338;
  .changes-container {
    display: flex;
    flex-direction: column;
    height: 370px;
    overflow-x: hidden;
    .table-header {
      .ant-col {
        font: normal normal 600 18px/39px "Open Sans";
      }
    }
    .data-rows {
      .ant-col {
        font: normal normal normal 16px / 25px Open Sans;
      }
    }
    .table-body {
      display: flex;
      flex-direction: column;
      overflow-x: hidden;
      overflow-y: auto;
      // background: #fff;
      > div {
        padding: 5px 10px;
        &:nth-child(odd) {
          background: #f5f5f7;
        }
      }
      p {
        margin: 0;
      }
    }
    .exportButtons {
      display: flex;
      gap: 10px;
      .exportKandidateBtn {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 32px;
        border: none;
        padding: 0;
        border-radius: 5px;
        cursor: pointer;
      }
    }
    &.dark {
      color: $white;
      .table-body {
        background: $d-black3;
        > div:nth-child(odd) {
          background: $d-black2;
        }
        > div:nth-child(even) {
          background: $d-black4;
        }
      }
    }
  }
}

@media (max-width: 1200px) {
  .activity-modal .ant-modal-content {
    width: 100%;
    overflow: hidden;
    .changes-container {
      width: 100%;
      overflow-x: scroll;
      .table-header,
      .table-body {
        width: 1095px;
      }
    }
  }
}
