.announcementsWrapper {
  width: 100%;
  height: 100%;
  .userResponses {
    display: flex;
    align-items: center;
    gap: 10px;
    height: 100%;
    span {
      width: 32px;
      height: 32px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 5px;
      cursor: pointer;
    }
  }
}
.announcemenet-container {
  width: 100%;
  height: 100%;
  overflow-y: auto;
  .announcemenet-header {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 30px;
    width: 100%;
    .new-announcement-btn-lajmerim {
      width: 150px;
      height: 32px;
      color: #fff;
      cursor: pointer;
      background: #1da193;
      border-radius: 5px;
      border: none;
      line-height: 22px;
      /* identical to box height */
      text-align: center;
      /* Gray 3 */
      &:hover {
        opacity: 0.8;
      }
      &:disabled {
        background: #d9d9d9;
        cursor: not-allowed;
      }
    }
  }
  .ag-grid-punojsit {
    .ag-grid-header {
      margin-top: 20px;
    }
    .ag-root-wrapper.ag-layout-normal.ag-ltr {
      border-radius: 10px;
      box-shadow: 0;
      // background-color: #f5f5f7;
    }
    .announcements-actions {
      // background-color: red;
      display: flex;
      gap: 10px;
      height: 100%;
      align-items: center;
    }
  }
}
