@import "../../../../../index.scss";
@import "../../../../../assets/globalVariables.scss";

.notifs-list-container {
  margin-left: 10px;
  .no-notifs {
    display: flex;
    justify-content: center;
    color: red;
    font-size: 19px;
  }
  .notification-headr-container {
    display: flex;
    justify-content: center;
    align-items: center;
    .notif-search-headr {
      width: 50%;
      max-width: 310px;
      display: flex;
      align-items: center;
      justify-content: center;
      // margin: 10px 15px 0px 15px;
    }
  }

  .selection-options-notifs {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 15px;
    width: 100%;
    background-color: #dce4e9;
    height: 39px;
    border-radius: 5px;

    .selection-btn {
      font: normal normal 600 15px/30px Open Sans;
      margin-left: 5px;
      margin-right: 5px;
      background-color: transparent;
      font-family: Open Sans;
      font-size: 14px;
      font-weight: 600;
      line-height: 19.07px;
      text-align: center;

      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      height: 27px;
      // gap: 5px;
      color: #2e5d9e;
      // fill: white;
      border: none;
    }
    span:last-child .selection-btn {
      color: #ea3943;
    }
  }
  .notifs-extra-filters {
    min-height: 50px;
    width: 100%;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    display: flex;
    color: #959ca7a1;

    .filter-btn {
      font: normal normal 600 15px/30px Open Sans;
      // margin-left: 15px;
      // margin-right: 15px;
      width: -webkit-fill-available;
      border-radius: 5px;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      height: 27px;
      gap: 5px;
      border: none;
      color: #2e5d9e;
    }

    .importance-button {
      background-color: antiquewhite;
      font: normal normal 600 15px/30px Open Sans;
      margin-left: 20px;
      border: 1px solid black;
      border-radius: 20px;
      width: 100px;
      display: flex;
      justify-content: center;
      cursor: pointer;
    }
    .critical-button {
      margin-left: 20px;
      background-color: antiquewhite;
      font: normal normal 600 15px/30px Open Sans;
      margin-left: 20px;
      border: 1px solid black;
      border-radius: 20px;
      width: 100px;
      display: flex;
      justify-content: center;
      cursor: pointer;
    }
    .info-button {
      background-color: antiquewhite;
      font: normal normal 600 15px/30px Open Sans;
      margin-left: 20px;
      border: 1px solid black;
      border-radius: 20px;
      width: 100px;
      display: flex;
      justify-content: center;
      cursor: pointer;
    }
    .unread-button {
      background-color: antiquewhite;
      font: normal normal 600 15px/30px Open Sans;
      margin-left: 20px;
      border: 1px solid black;
      border-radius: 20px;
      width: 100px;
      display: flex;
      justify-content: center;
      cursor: pointer;
    }
  }

  .notifs-list {
    list-style-type: none;
    margin-left: 0;
    display: flex;
    flex-direction: column;
    padding: 0;
    max-height: 100%;
    display: flex;
    flex-direction: column;
    gap: 5px;
    overflow-y: scroll;
    overflow-x: hidden !important;
    .list-item {
      display: flex;
      width: 100%;
      align-items: center;
      flex-direction: row;
      gap: 5px;

      .ant-checkbox {
        // .ant-checkbox-checked .ant-checkbox-inner:after {
        // 	// border-color: red !important;
        // }
        .ant-checkbox-inner {
          // width: 25px;
          // height: 25px;
          background-color: #323338;
          // border-color: red;
          border: none;
        }
        span.ant-checkbox-inner {
          // background-color: rgba(255, 0, 0, 0);
          // border: 0.5px black;
          border-color: #323338;
        }
      }

      .marker {
        height: 30px;
        border-radius: 5px;
        @include flex(center, center);
        @include square(5px, 30px, 0, #efefef);
        transition: width 0.2s ease-in-out;
        overflow: hidden;
      }
      .innerMarker {
        @include square(0px, 0px, 0, red);
        transition: width 0.2s ease-in-out;
      }
      .checked {
        width: 35px;
      }
      &:hover {
        .marker {
          width: 35px;
        }

        .innerMarker {
          width: 16px;
          height: 16px;
        }
      }
    }
  }
  &.dark {
    .no-notifs {
      color: #f0747b;
    }
    .selection-options-notifs {
      background: $d-black5;
      .selection-btn {
        color: inherit;
      }
      span:last-child .selection-btn {
        color: #f0747b;
      }
    }
    .notifs-extra-filters .filter-btn {
      color: $white;
    }
    .notifs-list .list-item {
      .marker {
        background: $d-black5;
      }
      .single-notif {
        background: $d-black5;
        color: unset;
        &:hover {
          background: $d-black6;
        }
      }
    }
  }
}

.loading-notifications-skeleton {
  margin: 20px;
}
