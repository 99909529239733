@import "../../../../../assets/globalVariables.scss";

.request-status-modal {
  .label-text {
    text-align: left;
    font: normal normal 600 16px/22px "Open Sans";
    color: #323338;
  }
  .modal-status-text {
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 1px 1px;
    margin: 5px 10px;
    border-radius: 5px;
    color: #fff;
  }
  &.dark {
    .label-text {
      color: $white;
    }
  }
}
