.react-tel-input {
  .form-control {
    height: 32px;
  }

  .country-list .search-emoji {
    display: none;
  }
}
.customTagsSelect {
  .ant-select-selection-overflow {
    .ant-select-selection-overflow-item {
      .ant-select-selection-item {
        background-color: #fff;
        border-radius: 5px;
        .ant-select-selection-item-remove {
          display: flex;
          align-items: center;
          justify-content: center;
          .anticon-close {
            height: 14px;
            width: 14px;
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: #ff4d4f;
            border-radius: 3px;
            svg {
              height: 12px;
              width: 12px;
              fill: #fff;
            }
          }
        }
      }
    }
  }
}
// .labeledInputContainer {
//   .ant-input-affix-wrapper,
//   .ant-select-selector,
//   .ant-picker,
//   .ant-input {
//     border: 0 !important;
//     border-radius: 5px !important;
//     background-color: #f4f5f6 !important;
//   }
// }
