@import "../../../../assets/globalVariables.scss";

.konfigurimi-body {
  .kanbanContainer {
    display: flex;
    justify-content: center;
    gap: 30px;
    padding: 10px;
    height: 100%;
    .kanbanColumn {
      display: flex;
      flex-direction: column;
      width: 500px;
      height: 100%;
      max-height: 720px;
      background-color: #f5f5f7;
      border-radius: 10px;
      overflow: hidden;
      .kanbanColHeader {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 45px;
        color: #fff;
        background-color: #1d3445;
        font-size: 16px;
        font-weight: 600;
        .kanbanEditColBtns {
          display: flex;
          gap: 10px;
          position: absolute;
          right: 10px;
        }
      }
      .kanbanColContent {
        display: flex;
        flex-direction: column;
        padding: 20px;
        height: 100%;
        // max-height: calc(100% - 45px);
        overflow-y: auto;
        .kanbanItem {
          display: flex;
          justify-content: space-between;
          width: 100%;
          height: 42px;
          min-height: 42px;
          border-radius: 5px;
          margin: 5px 0;
          background-color: #dbdbdd;
          &:hover {
            background-color: #d8d8d8;
          }
          &:active {
            background-color: #d8d8d8;
          }
          .deleteItem {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 100%;
            width: 42px;
            &:hover {
              cursor: pointer;
            }
          }
          .itemLabels {
            display: flex;
            align-items: flex-end;
            gap: 5px;
            width: 100%;
            .itemLabel {
              font-weight: 600;
              font-size: 16px;
              margin-top: auto;
              margin-bottom: auto;
            }
            .itemFormName {
              font-size: 12px;
              color: #818080;
              padding-bottom: 5px;
            }
          }
          .ant-ribbon {
            font-size: 12px;
            line-height: 20px;
          }
        }
      }
    }
    &.dark {
      .kanbanColumn {
        background-color: $d-black3;
        .kanbanColHeader {
          background-color: $d-black1;
        }
        .kanbanColContent .kanbanItem {
          background: $d-black3;
          // border: 1px solid $d-black1;
          box-shadow: 0px 0px 2px $white;
        }
      }
    }
  }
}

@media (max-width: 850px) {
  .konfigurimi-body {
    .kanbanContainer {
      .kanbanColumn {
        .kanbanColContent {
          .kanbanItem {
            .itemLabels {
              flex-direction: column;
              align-items: flex-start;
              .itemLabel {
                font-size: 14px;
              }
            }
          }
        }
      }
    }
  }
}
@media (max-width: 450px) {
  .konfigurimi-body {
    .kanbanContainer {
      flex-direction: column;
      overflow-y: auto;
      .kanbanColumn {
        width: 100%;
        .kanbanColContent {
          .kanbanItem {
            .itemLabels {
              flex-direction: column;
              align-items: flex-start;
              .itemLabel {
                font-size: 14px;
              }
            }
          }
        }
      }
    }
  }
}
