@import "../../../../../index.scss";

.documentationFileTree {
  width: fit-content;
  border-radius: 20px;
  overflow: hidden;
  overflow-y: auto;

  .callback-title {
    @include text(14px, 600, 0, #111);
    width: 100%;
    text-align: center;
  }

  //*  justify-content:center, align-items:center, and styles text font-size:16px, font-weight:600, letter-spacing:0, and text color
  .folder {
    @include flex(center, center);
    @include text(16px, 600, 0, #323338);
    border-radius: 2px;
    padding: 0 12px;
    gap: 10px;
  }
  //* justify-content:center, align-items:center, and styles text font-size:14px, font-weight:400, letter-spacing:0, and text color
  .file {
    @include flex(center, center);
    @include text(14px, 400, 0, #1264a3);
    gap: 10px;
    padding: 0 12px;
    svg {
      fill: #1264a3;
    }
  }

  .fileIcon {
    display: flex;
    justify-content: center;
    width: 16px;
    svg {
      width: 16px;
    }
  }

  .ant-tree-node-selected {
    background-color: #fff !important;
    //* styles formCardTitle justify-content:center, align-items:center
    .formCardTitle {
      @include flex(center, center);
      width: -webkit-fill-available;
      width: stretch;
      width: -moz-available;
      height: 45px;
      background-color: #f7f8fa;
      margin: -15px -30px 25px;
      padding: 0 20px;
    }
  }
}

//* styles DocumentationFileTree for  Pc monitor
@media (max-width: 1025px) {
  .documentationFileTree {
    width: fit-content;
    min-width: 0px;
    border-radius: 20px;
    overflow: hidden;
    padding-bottom: 25px;
    height: 100%;
    max-width: 527px;
    overflow-y: auto;
  }
}
//* styles DocumentationFileTree for iPhone 13 Pro
@media (max-width: 391px) {
  .documentationFileTree .folder {
    font-size: 14px;
  }
}
