.forgot-password {
  text-align: center;
  path#Path_41_00000082349036615797965340000012167224591291587971_ {
    fill: #b9bfc2;
  }
  path#Path_45_00000070827678955372675450000004029467003067521975_ {
    fill: #b9bfc2;
  }

  .login-header {
    height: 119px;
    background: #fff;
    justify-content: center;
    align-items: center;
    display: flex;
  }

  .background {
    // background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    //   url(../../../assets/images/background.png);
    background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
      url(../../../assets/images/fallBackgroundWelcome.jpg);
    background-size: cover;
    height: calc(100vh - 119px);
    justify-content: center;
    align-items: center;
    display: flex;
    background-position: center;
    background-repeat: no-repeat;
  }

  .login-grid {
    background: #fff;
    width: 513px;
    // height: 333px;
    border-radius: 15px;
    box-shadow: 1px 1px 1px 1px rgba(0, 0, 0, 0.1);
  }

  .login-grid-final {
    background: #fff;
    width: 513px;
    height: 533px;
    border-radius: 15px;
    box-shadow: 1px 1px 1px 1px rgba(0, 0, 0, 0.1);
    display: flex;
    justify-content: center;
    flex-direction: column;
  }

  .form-header {
    font-size: x-large;
    font-weight: 600;
    margin-top: 40px;
    margin-bottom: 40px;
  }

  .ant-form-item-control-input-content {
    margin-right: 70px;
    margin-left: 70px;
  }

  span.ant-input-affix-wrapper {
    background-color: #f5f5f7;
    border: none;
    input.ant-input {
      background: #f5f5f7 !important;
      color: #0288d2;
    }
  }

  .ant-row.ant-form-item.ant-form-item-with-help.ant-form-item-has-error {
    span.ant-input-affix-wrapper {
      background-color: #ea3943 !important;
      border: none;
      ::placeholder {
        color: #fff !important;
      }
      input.ant-input {
        background: #ea3943 !important;
        color: #fff !important;
      }
      span.ant-input-suffix {
        color: #fff;
      }
      path#Path_41_00000082349036615797965340000012167224591291587971_ {
        fill: #fbfeff;
      }
      path#Path_45_00000070827678955372675450000004029467003067521975_ {
        fill: #ffffff;
      }
    }
  }

  button.ant-btn.ant-btn-primary.back-form-button {
    margin-right: 30px;
    height: 32px;
    background: #0288d2;
    width: 135px;
    border: none;
    border-radius: 5px;
    margin-top: 12px;
  }

  button.ant-btn.ant-btn-primary.login-form-button {
    background: #f5f5f7;
    color: #323338;
    border: none;
    border-radius: 5px;
    width: 203px;
    height: 32px;
  }
  button.ant-btn.ant-btn-primary.filled-button {
    background: #0288d2;
    color: #fff;
    border: none;
    border-radius: 5px;
    width: 203px;
    height: 32px;
  }
}

.forgot-password .login-grid {
  height: fit-content !important;
}

@media (max-width: 450px) {
  .forgot-password .login-grid {
    height: fit-content !important;
    .ant-alert.ant-alert-warning.ant-alert-banner {
      margin-left: 10px !important;
      margin-right: 10px !important;
      width: fit-content !important;
    }
  }
}
