@import "../../../assets/globalVariables.scss";

.requestsDrawer,
.problemsDrawer {
  .drawerContainer {
    display: flex;
    flex-direction: column;
    gap: 20px;
    height: 100%;
    overflow: hidden;
    .drawerTable {
      display: flex;
      flex-direction: column;
      gap: 10px;
      height: 100%;
      overflow: hidden;
      .tableHeaders {
        display: grid;
        font-size: 14px;
        text-align: center;
      }
      .tableContent {
        display: flex;
        flex-direction: column;
        gap: 5px;
        height: 100%;
        overflow-y: auto;
        .problemsDrawerRow {
          display: grid;
          grid-template-columns: repeat(5, 1fr);
          column-gap: 10px;
          span {
            text-align: center;
            align-content: center;
            * {
              margin: 0;
            }
          }
        }
      }
    }
  }
  &.dark {
    color: $white;
  }
}
