.date-buttons-container {
	display: flex;
	flex-direction: row;
	gap: 10px;

	.active-filter {
		display: flex;
		justify-content: center;
		align-items: center;
		width: 70px;
		height: 20px;
		background: #f1f7fb 0% 0% no-repeat padding-box;
		border-radius: 5px;
		opacity: 1;
		color: black;
		border: none;
		text-decoration: none;
		&:hover {
			cursor: pointer;
		}
	}

	.inactive-filter {
		display: flex;
		justify-content: center;
		align-items: center;
		width: 70px;
		height: 20px;
		//Autumn Theme
		background: #244431 0% 0% no-repeat padding-box;
		color: white;
		border-radius: 5px;
		opacity: 1;
		border: none;
		text-decoration: none;
		&:hover {
			cursor: pointer;
		}
	}
}
