@import "../../../../assets/globalVariables.scss";

.clock-in {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  // margin-top: -70px;
  font-family: "Open Sans", sans-serif;
  animation: appearSoftly 1s;
  .clock-out-icon {
    path#Path_7710 {
      fill: #ea3943;
    }
    path#Path_7711 {
      fill: #ea3943;
    }
    path#Path_7712 {
      fill: #ea3943;
    }
    path#Path_7713 {
      fill: #ea3943;
    }
    path#Path_7714 {
      fill: #ea3943;
    }
    path#Path_7714 {
      fill: #ea3943;
    }
  }
  .clock-in-active {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .justify-note-wrapper {
      width: 344px;
      height: 50px;
      // textarea.ant-input {
      //   height: 70px;
      // }
    }
    .time-indicator {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: row;
      h4 {
        text-align: left;
        font: normal normal 600 16px/56px Open Sans;
        letter-spacing: 0px;
        color: #ea3943;
        opacity: 1;
      }
      .live-icon {
        margin-left: 5px;
        width: 41px;
        height: 34px;
        color: #ea3943;
      }
    }

    .clock-in-content {
      display: flex;
      // margin-top: 24px;
      .clock-in-logo-container {
        display: flex;
        .clock {
          width: 62px;
          height: 62px;
          path#Path_7693 {
            fill: #1da193;
          }
        }
        .edited-warning-cont {
          // display: flex;
          width: 92px !important;
          text-align: center;
          white-space: wrap !important;
        }
      }

      .actionful {
        margin-left: 10px;
        .start-shift-cont {
          display: flex;
          align-items: center;
          // justify-content: center;
          flex-direction: column;
          gap: 10px;
          animation: appearSoftly 500ms;
          .welcomeMessage {
            width: 160px;
            text-align: center;
            font-size: 16px;
            line-height: 24px;
          }
          h1 {
            font-size: 1.17em;
            margin: 0;
          }
          .timepick {
            .ant-picker-input {
              width: 69px !important;
            }
          }
          .start-shift-btn {
            // margin-top: 20px;
            position: relative;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 150px;
            height: 50px;
            //Autumn Theme
            background: #1da193;
            opacity: 1;
            color: white;
            font-family: "Open Sans", sans-serif;
            border-radius: 5px;
            font-size: 14px;
            font-family: "Open Sans", sans-serif;
            user-select: none;
            &:hover {
              cursor: pointer;
            }
            svg {
              position: absolute;
            }
          }
        }
        .currentTime {
          width: 160px;
          background-color: #1d3943;
          height: 30px;
          border-radius: 5px;
          color: white;
          display: flex;
          justify-content: center;
          align-items: center;
          gap: 5px;
        }
        .yesterday-shift-btn {
          //   margin-top: 10px;
          //   display: flex;
          //   justify-content: center;
          //   align-items: center;
          //   width: 253px;
          //   height: 32px;
          //Autumn theme
          color: #2e5d9e;
          //   opacity: 1;
          //   color: white;
          //   border-radius: 5px;
          font-weight: 600;
          font-size: 12px;
          font-family: "Open Sans", sans-serif;

          &:hover {
            cursor: pointer;
          }
        }
      }
    }
  }

  .clock-out-active {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;

    .clockoutBar {
      position: absolute;
      bottom: 10px;
      left: 0;
      background-color: #1da193;
      height: 5px;
    }

    .time-indicator {
      display: flex;
      // justify-content: center;
      align-items: center;
      flex-direction: row;
      h4 {
        text-align: left;
        font: normal normal 600 16px/56px Open Sans;
        letter-spacing: 0px;
        color: #ea3943;
        opacity: 1;
      }
      .live-icon {
        margin-left: 5px;
        width: 41px;
        height: 34px;
        color: #ea3943;
      }
    }
    .clock-in-info {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 5px;
      flex-wrap: nowrap;
      // margin-top: -20px;
      // margin-bottom: -10px;
      .clock-in-icon {
        width: 26.74px;
        height: 26.74px;
        margin-top: -5px;
        path#Path_7693 {
          fill: #1e8273;
        }
      }
      font-size: 16px;
      font-family: "Open Sans", sans-serif;
      letter-spacing: 0px;
      color: #323338;
      min-width: 100px;
    }

    .relativeTime {
      font-family: "Open Sans";
      font-size: 16px;
      margin-left: 10px;
    }

    .clock-out-action {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      .justify-note-wrapper {
        width: 344px;
        height: 50px;
        margin-left: -12px;
        margin-top: 10px;
        // textarea.ant-input {
        //   height: 70px;
        // }
      }
      .warning-clock-out-edited {
        margin-top: -20px;
        margin-bottom: -10px;
        font: normal normal 600 12px/56px Open Sans;
        color: #323338;
        display: flex;
        margin-left: -90px;
        font-weight: 800;
      }
      .clock-out {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        margin-top: 20px;
        // margin-left: -20px;
        .start-shift-cont {
          display: flex;
          flex-direction: row;
          align-items: center;
          .timepick {
            .ant-picker-input {
              width: 79px !important;
              height: 22px !important;
            }
          }
        }

        .clock-out-icon {
          width: 29.74px;
          height: 29.74px;
          fill: #1da193;
          svg {
            fill: #1da193;
          }
          path#Path_7710 {
            fill: #1da193;
          }
          // margin-top: -20px;
        }
        .clock-out-btn {
          position: relative;
          border: none;
          display: flex;
          justify-content: center;
          align-items: center;
          width: 160px;
          height: 50px;
          background: #ea3943 0% 0% no-repeat padding-box;
          border-radius: 5px;
          color: white;
          font-size: 14px;
          font-family: "Open Sans", sans-serif;

          &:hover {
            cursor: pointer;
          }
          svg {
            position: absolute;
          }
        }
        .loading-spinner {
          margin-left: 20px;
          margin-top: -30px;
        }
      }

      h1 {
        // font: normal normal 600 20px/56px Open Sans;
        color: #323338;
        margin-top: 10px;
        font-size: 20px;
        font-family: "Open Sans", sans-serif;
        font-weight: 600;
      }
      .yesterday-shift-btn {
        margin-top: 0px;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 165px;
        height: 32px;
        background: #0288d2 0% 0% no-repeat padding-box;
        opacity: 1;
        color: white;
        border-radius: 6px;
        font-size: 14px;
        font-family: "Open Sans", sans-serif;
        &:hover {
          cursor: pointer;
        }
      }
      span {
        font: 600 normal normal 16px/36px Open Sans;
        // color: #323338;
      }
    }
    .clock-out-action-finish {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      .clock-out-info {
        display: flex;
        flex-direction: row;
        margin-top: 20px;
        // margin-left: 10px;
        // border: 1px solid black;
        .clock-out-icon {
          flex: 1;
          width: 27.7px;
          height: 27.7px;
          // margin-top: 10px;
          margin-top: -5px;

          margin-left: 5px;
          path#Path_7710 {
            fill: #ea3943;
          }
        }

        span {
          // max-width: 200px;
          // min-width: 200px;
          font-family: Open Sans;
          font-size: 16px;
          font-weight: 600;
          line-height: 21.79px;
          text-align: center;
        }
      }
      h1 {
        margin-bottom: 10px;
        font-size: 20px;
        font-family: "Open Sans", sans-serif;
        font-weight: 600;
        margin-top: 10px;
        margin-left: 10px;
      }
      .yesterday-shift-btn {
        // margin-top: 10px;
        margin-left: 10px;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 165px;
        height: 32px;
        background: #0288d2 0% 0% no-repeat padding-box;
        opacity: 1;
        color: white;
        border-radius: 6px;
        font-size: 14px;
        font-family: "Open Sans", sans-serif;
        &:hover {
          cursor: pointer;
        }
      }
    }
  }
  &.dark {
    .yesterday-shift-btn {
      color: #b5d1f7 !important;
    }
    .clock-out-active .clock-in-info {
      color: $white;
    }
  }
}
.clockinBackgroundBlue {
  background: url("../../../../assets/icons/clockin_bckgr_icon_blue.svg");
}
.clockinBackgroundRed {
  background: url("../../../../assets/icons/clockin_bckgr_icon_red.svg");
}
.header-dashbord-card-title-container {
  animation: appearSoftly 1s;
}
//Sweet Alert Custom Style
.swal-modal.clock-in-confirm-alert {
  .swal-footer {
    .swal-button-container {
      .swal-button.swal-button--cancel {
        background-color: rgb(245, 142, 142);
        color: white;
      }
      .swal-button.swal-button--confirm {
        background-color: #1da193;
      }
    }
  }
}

.custum-swal {
  .swal-title {
    font-size: 24px;
    font-family: "Open Sans", sans-serif;
  }
}
.custum-swal-dark,
.custum-swal-loginSuccess-dark,
.custum-swal-loginError-dark {
  ::before {
    display: none;
  }
  background: $d-black2;
}

//skeleton for initial loading!
.skeleton-loading {
  display: flex;
  margin-left: 20px;
  margin-right: 20px;
  margin-top: -10px;
}

.clock-log {
  margin-left: auto;
  position: relative;
  width: 45px !important;
  height: 45px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  // position: absolute;
  // Autumn Theme
  //   background: #e8a668 0% 0% no-repeat padding-box;
  border-radius: 0px 0px 0px 10px;
  cursor: pointer;
}

@keyframes appearSoftly {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
