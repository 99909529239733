.kanbanConfirmModal {
  .ant-modal-content {
    .ant-modal-close {
      .ant-modal-close-x {
        display: none;
      }
    }
    .ant-modal-header {
      background-color: #e7eef2;
      height: 35px;
      padding-top: 6px;
      padding-bottom: 6px;
    }
    .ant-modal-body {
      padding-top: 50px;
      .kanbanConfirmModalBody {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        gap: 20px;
        > h1 {
          font-family: Open Sans;
          font-size: 25px;
          font-weight: 600;
          line-height: 34px;
          letter-spacing: 0em;
          text-align: center;
        }
        .disqualificationButton {
          min-width: 250px;
          height: 32px;
          color: black;
          border-radius: 5px;
          font-family: Open Sans;
          font-size: 14px;
          font-weight: 400;
          line-height: 19px;
          letter-spacing: 0em;
          text-align: center;
        }
      }
    }

    .ant-modal-footer {
      border-top: none;
      padding-top: 30px;
      padding-bottom: 30px;
      .footerContainer {
        display: flex;
        justify-content: center;
        gap: 10px;
        border-top: none;
        .decline {
          width: 150px;
          height: 32px;
          border-radius: 5px;
          background-color: #ea3943;
          border: none;
          font-family: Open Sans;
          font-size: 14px;
          font-weight: 400;
          line-height: 19px;
          letter-spacing: 0em;
          color: #fbfbfb;
        }
        .send-request:disabled {
          opacity: 0.3;
        }
        .send-request {
          transition: background-color 0.3s;
          width: 150px;
          height: 32px;
          background-color: #1da193;
          color: #fbfbfb;
          border-radius: 5px;
          font-family: Open Sans;
          font-size: 14px;
          font-weight: 400;
          line-height: 19px;
          letter-spacing: 0em;
          text-align: center;
          border: none;
        }
      }
    }
  }
}
