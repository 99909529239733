.custom-warnings {
  display: flex;
  flex-direction: column;
  gap: 10px;
  .amount-cards-wrapper {
    display: flex;
    flex-direction: row;
    // flex-wrap: wrap;
    gap: 10px;
  }
}

.amount-card {
  width: 170px;
  height: 70px;
  //black even on darkMode
  color: #121212;
  /* UI Properties */
  background: #e7e7e7 0% 0% no-repeat padding-box;
  border-radius: 5px;
  .amount-card__content {
    padding: 10px;
    animation: appearSoftly 0.8s;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .amount-card_title {
      font: normal normal normal 16px/22px Open Sans;
    }
    .amount-card_amount {
      font: normal normal 600 18px/27px Open Sans;
      animation: appearSoftly 0.8s;
    }
  }
}

@keyframes appearSoftly {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@media (max-width: 450px) {
  .custom-warnings {
    .amount-cards-wrapper {
      display: grid;
      grid-template-columns: 1fr 1fr;
    }
  }
}
