.sign-up {
  .signUp-header {
    height: 119px;
    background: #fff;
    justify-content: center;
    align-items: center;
    display: flex;
  }

  .background {
    background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
      url(../../../assets/images/background.png);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    height: 850px;
    justify-content: center;
    align-items: center;
    display: flex;
  }
  .signUpForm {
    background: #ffffff;
    border-radius: 15px;
    width: 510px;
    padding-right: 20px;
    padding-top: 40px;
    padding-left: 20px;
    justify-content: center;
    align-items: center;
    display: flex;
  }

  .ant-input-group-addon:first-child {
    border-right: 0;
  }
  input[type="number"]::-webkit-inner-spin-button,
  input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  .ant-form-item-explain-error {
    width: 300px;
  }
  input#register_password {
    background: transparent;
  }

  input#register_phone {
    background: #f5f5f7;
  }

  .ant-form-item-control-input::placeholder {
    color: #b9bfc2 !important;
  }
  .ant-select.ant-select-single.ant-select-show-arrow {
    background: #f5f5f7;
  }
  .ant-input-group-addon
    .ant-select.ant-select-single:not(.ant-select-customize-input)
    .ant-select-selector {
    background: transparent;
    border: none;
    box-shadow: none !important;
  }
  .ant-input-group-addon {
    color: #f5f5f7 !important;
  }
  button.ant-btn.ant-btn-primary {
    background: #0288d2;
    color: #fff;
    border: none;
    width: 187px;
    height: 32px;
    margin-top: 10px;
    margin-left: 60px;
    border-radius: 5px;
  }

  .ant-input::placeholder {
    color: #b9bfc2;
  }
}
