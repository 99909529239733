.employee-card {
  display: flex;
  flex-direction: row;
  gap: 140px;
  justify-content: center;

  .title-text {
    font: normal normal 600 16px "Open Sans", sans-serif;
  }
  .report-button {
    width: 116px;
    height: 26px;
    background: #1da193 0% 0% no-repeat padding-box;
    border-radius: 5px;
    border: none;
    color: #fff;
    .report-button-text {
      font: normal normal normal 14px/12px "Open Sans", sans-serif;
    }
    &:hover {
      opacity: 0.8;
    }
  }
}
.drag-card-wrapper {
  .content {
    justify-content: center;
    flex-direction: column;
    align-items: center;
    display: flex;
    .employee-card-content {
      margin-top: 5px;
      margin-right: 5px;
      margin-left: 5px;
      padding-left: 7px;
      justify-content: center;
      align-items: center;
      display: flex;
      .name-badger {
        width: auto;
        color: unset !important;
        .ant-badge-status-text {
          color: unset !important;
        }
      }
    }
  }
  &.dark .employee-card-content {
    color: #fbfbfb !important;
  }
}
.name-em {
  font: normal normal normal 16px/22px "Open Sans", sans-serif;
  margin-right: 5px;
}
.number-em {
  margin-left: 5px;
  font: normal normal normal 16px/22px "Open Sans", sans-serif;
}

.line-card {
  border-bottom: 1px solid rgba(0, 0, 0, 0.06);
  width: 100%;
  margin: 0 5px;
}
