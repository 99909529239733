// .punonjesit-ag-grid {
//   display: flex;
//   flex-direction: column;
//   height: 100%;
//   background-color: #fff;
//   border-radius: 10px;
//   overflow: hidden;
//   .ag-grid-header {
//     display: flex;
//     gap: 20px;
//     background: transparent;
//   }
// }
.request-details-btn.ant-btn {
  background-color: #1da193;
  color: white;
  text-align: center;
  font: normal normal normal 14px/19px "Open Sans";
  border-radius: 6px;
}
.status-column {
  width: 50%;
  height: 100%;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  justify-self: center;
  font-family: "Open Sans";
  span {
    width: 100%;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    color: white;
  }
}

.user-requests-aggrid {
  .Print-Punojsit {
    visibility: collapse;
  }
}
