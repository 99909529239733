@import url("https://fonts.googleapis.com/css?family=Aleo");

/* :root {
	font-family: "Aleo", sans-serif;
} */

/* html,
body {
	width: 100%;
	height: 100%;
	padding: 0;
	margin: 0;
	background: rgb(119, 13, 13);
	background: radial-gradient(circle, rgba(119, 13, 13, 0.92) 64%, rgba(0, 0, 0, 0.6) 100%);
}

canvas {
	width: 100%;
	height: 100%;
} */

/* Thanks Traversy Media for this text background animation tut:
   https://www.youtube.com/watch?v=lX5LZTWc4f8&t
*/
.label {
  font-size: 2.2rem;
  background: url("https://res.cloudinary.com/nikita-rudenko/image/upload/v1544906837/stripe_c982eebe592d6c30f2034f5ce80b1fc6.png");
  background-clip: text;
  -webkit-background-clip: text;
  color: transparent;
  animation: moveBg 30s linear infinite;
  font-family: "Aleo", sans-serif;
}

.middle {
  /* position: absolute; */
  /* top: 50%;
	left: 50%;
	transform: translate(-50%, -50%); */
  /* background: rgb(119, 13, 13); */
  /* background: radial-gradient(circle, rgba(119, 13, 13, 0.92) 64%, rgba(0, 0, 0, 0.6) 100%); */
  text-align: center;
  user-select: none;
  height: 225px;
  font-family: "Aleo", sans-serif;
}

.time {
  color: #d99c3b;
  text-transform: uppercase;
  display: flex;
  justify-content: center;
  font-family: "Aleo", sans-serif;
  animation: moveBg 30s linear infinite;
}

.time span {
  padding: 0 14px;
  font-size: 0.8rem;
  font-family: "Aleo", sans-serif;
  animation: moveBg 30s linear infinite;
}

.time span div {
  font-size: 3rem;
  font-family: "Aleo", sans-serif;
}

@media (max-width: 740px) {
  .label {
    font-size: 1.7rem;
    font-family: "Aleo", sans-serif;
  }
  .time span {
    padding: 0 16px;
    font-size: 0.6rem;
    font-family: "Aleo", sans-serif;
  }
  .time span div {
    font-size: 2rem;
    font-family: "Aleo", sans-serif;
  }
}
@keyframes moveBg {
  0% {
    background-position: 0% 30%;
  }
  100% {
    background-position: 1000% 500%;
  }
}
