@import "../../../assets/globalVariables.scss";

.agGridHeader {
  display: flex;
  gap: 20px;
  width: 100%;
  padding: 10px 20px;
  overflow-x: auto;
  overflow-y: hidden;
  .headerRefresh {
    background-color: #f1f7fb;
    border: none;
    // width: 32px;
    // height: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    cursor: pointer;
  }
  .headerSearch {
    display: flex;
    align-items: center;
    align-self: center;
    gap: 5px;
    border-radius: 5px;
    height: 100%;
    background-color: #f1f7fb;
    padding: 2px 10px;
    .icon {
      display: flex;
      align-items: center;
    }
    .headerInput {
      background-color: #f1f7fb;
      border: none;
      min-width: 150px;
    }
    .headerButton {
      width: 80px;
      height: 26px;
    }
    ::placeholder {
      color: unset;
      opacity: 0.8;
    }
  }
  .headerIcons {
    display: flex;
    align-items: center;
    gap: 15px;
    .headerIcon {
      display: flex;
      background-color: #f1f7fb;
      padding: 7px;
      border-radius: 5px;
      &:hover {
        cursor: pointer;
        background-color: #e6ecf0;
      }
      > div {
        display: flex;
      }
    }
  }
  .headerDropdown {
    display: flex;
    align-items: center;
    gap: 10px;
    background-color: #f1f7fb;
    border-radius: 5px;
    padding-left: 10px;
    min-width: 250px;
    .ant-select {
      width: 100%;
      height: 100%;
      .ant-select-selector {
        width: 100%;
        height: 100%;
        border-radius: 5px;
        background-color: transparent;
        padding: 0;
        border: none;
        color: unset;
        .ant-select-selection-placeholder {
          color: unset;
          opacity: 0.8;
        }
      }
      span.ant-select-arrow {
        color: unset;
      }
    }
  }
  .headerSwitch {
    display: flex;
    align-items: center;
    align-self: center;
    gap: 10px;
    height: 100%;
    cursor: pointer;
    white-space: nowrap;
  }
  .tableHeader {
    background-color: red;
  }
  &.dark {
    .headerSearch,
    .headerDropdown {
      box-shadow: 0px 0px 2px $white;
      .headerInput,
      .ant-select {
        box-shadow: none;
      }
    }
  }
}

@media (max-width: 450px) {
  .mobileAgComponentWrapper {
    .agGridHeader {
      flex-direction: column;
      gap: 10px;
      padding: 5px;
      border-radius: 10px;
      height: fit-content;
      .ant-collapse {
        width: 100%;
        border: none;
        .ant-collapse-header {
          color: unset;
          padding: 8px;
          align-items: center;
          .collapseLabel {
            display: flex;
            gap: 5px;
            align-items: center;
            .mondayButtonContainer {
              border-radius: 5px;
              button {
                background-color: unset;
              }
            }
          }
        }
        .ant-collapse-content {
          background: none;
          border: none;
          .ant-collapse-content-box {
            display: flex;
            flex-direction: column;
            gap: 10px;
            padding: 12px;
          }
        }
      }
      .headerSearch,
      .headerDropdown {
        width: 100%;
        height: 38px;
      }
      .headerIcons {
        overflow-x: auto;
        overflow-y: hidden;
      }
    }
  }
}
