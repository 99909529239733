@import "../../../../assets/globalVariables.scss";

.permissionsDocModal {
  .ant-modal-body {
    height: 420px;
    .content {
      display: flex;
      flex-direction: column;
      gap: 30px;
      .fileCard {
        display: flex;
        gap: 15px;
        width: 100%;
        padding: 10px;
        &:hover {
          background-color: #f7f8fa;
          border-radius: 5px;
          cursor: pointer;
        }
        .uploadedFile {
          display: flex;
          justify-content: space-evenly;
          align-items: flex-start;
          flex-direction: column;
          width: 100%;
          .row {
            display: flex;
            justify-content: space-between;
            width: 100%;
          }
        }
      }
    }
  }
  &.dark .ant-modal-body .content .fileCard {
    &:hover {
      background-color: $d-black5;
    }
    .uploadedFile .row {
      color: $white;
      &:last-child {
        color: $grey-3 !important;
      }
    }
  }
}
