.problemCardWrapper {
  height: 100%;
  width: 100%;
  // max-width: 810px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  .searchInput {
    width: 200px;
    margin-left: 15px;
    border-radius: 5px;
    // color: red;
    input::placeholder {
      color: #1d3445;
    }
  }
  .noProblemsCardContainer {
    padding: 20px 10px 0px 10px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-around;
    flex-direction: column;
    max-width: 465px;
    gap: 10px;

    .noProblemFirstRow {
      margin-top: 30px;
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 20px;
      .noProblemCardText {
        font-family: sans-serif;
        font-size: 14px;
        line-height: 19px;
        letter-spacing: 0em;
        text-align: center;
      }
      .noProblemCardTextTitle {
        font-family: Open Sans;
        font-size: 14px;
        font-weight: 600;
        line-height: 19px;
        letter-spacing: 0em;
        text-align: left;
      }
    }
    .mondayButtonContainer {
      margin-top: 20px;
      margin-bottom: 20px;
    }
  }
  .tableContainerWraper {
    height: 100%;
    // max-width: 810px;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding-bottom: 15px;
    .tableContainer {
      width: 100%;
      height: calc(100% - 42px);
      overflow: auto;
    }
  }
  .problemsDescriptionRow > * {
    padding: 2px !important;
    margin: 0px !important;
    height: 30px !important;

    text-wrap: wrap;
    flex-wrap: wrap;
    overflow: hidden;
  }
  .problemsDescriptionRow > * > img {
    display: none;
  }
  .problemsDescriptionRow > * > * {
    background-color: transparent !important;
  }
  .problemsDescriptionRow {
    max-height: 35px !important;
    flex-wrap: wrap;
    overflow: hidden;
  }
}
