@import "../../../index.scss";

.docEmployeeCreationContainer {
  .border {
    border: 2px dashed #1da193;
    width: 100%;
    height: 350px;
    opacity: 1;
    border-radius: 5px;
    padding: 30px;
  }

  .info {
    display: flex;
    flex-direction: column;
    align-items: center;
    .selectorContainer {
      width: 300px;
      div {
        width: 100%;
      }
    }
  }

  .newButton {
    align-self: center;
    @include mondayButton(#71cf48, #68c142);
    .mondayButtonIcon {
      width: 30px;
    }
  }
  .saveButton {
    @include mondayButton(#71cf48, #68c142);
    min-width: 150px;
  }

  .text {
    font-size: normal normal normal 16px "Open Sans";
    letter-spacing: 0px;
    color: #323338;
    opacity: 1;
  }

  .input-border {
    display: flex;
    flex-direction: column;
    .border {
      border: 2px dashed #1da193;
      width: 823px;
      height: 338px;
      opacity: 1;
      border-radius: 5px;
    }
  }

  .input-border-size {
    display: flex;
    flex-direction: row;
    .files-container {
      width: 100%;
      max-height: 338px;
      overflow-y: auto;
    }
    .input-files {
      display: flex;
      justify-content: space-between;
      padding: 0 10px 0 10px;
      width: 100%;
      .input-img {
        display: flex;
        align-items: center;
        margin-left: 0;
      }
      .input-title {
        display: flex;
        svg {
          width: 32px;
        }
        .file-Name {
          display: flex;
          align-items: center;
          padding: 0px 10px 0px 10px;
          text-align: center;
          font: normal normal normal 16px "Open Sans";
          letter-spacing: 0;
          color: #323338;
          opacity: 1;
        }
      }
    }

    .border {
      border: 2px dashed #1da193;
      width: 400px;
      height: 338px;
      opacity: 1;
      border-radius: 5px;
    }
  }
}
.employeeModal.dark {
  .text,
  .file-Name {
    color: unset !important;
  }
}
@media (max-width: 450px) {
  .docEmployeeCreationContainer {
    .border {
      text-align: justify;
    }
  }
}
