.showContactDetailsSecondModal {
  // width: calc(100vw - 20px) !important;
  // max-width: 580px !important;
  // right: -25%;
  .ant-modal-content {
    // height: calc(100vh - 20px);
    // width: calc(100vw - 20px);
    // max-height: 850px;
    // max-width: 580px;
    box-shadow: none !important;
    .ant-modal-body {
      height: 100%;
      padding: 20px;
      .container {
        height: calc(100% - 5px);
        max-height: 735px;
        .container-content {
          height: calc(100% - 5px);
          width: calc(100% - 5px);
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          .content-first-container {
            height: calc(50% - 5px);
            .quill-header {
              font-size: 2rem;
              font-weight: 700;
              margin: 0;
            }
            .quill-container {
              .tag-container {
                font-family: Open Sans;
                font-size: 14px;
                font-weight: 400;
                line-height: 19px;
                letter-spacing: 0em;
                text-align: center;

                display: flex;
                gap: 10px;
                flex-wrap: wrap;
                padding: 5px;
                overflow-x: auto;
                .ant-tag {
                  border: none;
                  border-radius: 30px;
                  padding: 5px 20px 5px 20px;
                  background-color: #f5f5f7;
                  font-weight: 600;
                  .anticon {
                    position: relative;
                    top: -10px;
                    left: 5px;
                    background-color: #ea3943;
                    border-radius: 50%;
                    padding: 4px;
                    svg {
                      fill: #fff;
                    }
                  }
                }
              }
              .ql-container {
                border: none;
                height: 250px;
                overflow-y: scroll;
                .ql-editor {
                  background-color: #f1f1f1;
                  border-radius: 5px;
                  width: 100%;
                  padding: 5px 10px 5px;
                }
              }
            }
          }
          .content-second-container {
            height: calc(50% - 5px);
            .quill-header {
              font-size: 2rem;
              font-weight: 700;
              margin: 0;
            }
            .quill-container {
              .tag-container {
                display: flex;
                gap: 10px;
                flex-wrap: wrap;
                padding: 5px;
                overflow-x: auto;
                .ant-tag {
                  border: none;
                  border-radius: 30px;
                  padding: 5px 20px 5px 20px;
                  background-color: #f5f5f7;
                  font-weight: 600;
                  .anticon {
                    position: relative;
                    top: -10px;
                    left: 5px;
                    background-color: #ea3943;
                    border-radius: 50%;
                    padding: 4px;
                    svg {
                      fill: #fff;
                    }
                  }
                }
              }

              .ql-container {
                border: none;
                // max-height: 100%;
                height: 250px;
                overflow-y: scroll;
                .ql-editor {
                  background-color: #f1f1f1;
                  border-radius: 5px;
                  width: 100%;
                  padding: 5px 10px 5px;
                }
              }
            }
          }
        }
      }
    }
  }
}
.showContactDetailsSecondModalWrap {
  width: fit-content !important;
  left: calc(50% + 170px) !important;
  right: 50% !important;
}
@media (max-width: 1500px) {
  .showContactDetailsSecondModalWrap {
    width: 100% !important;
    // width: auto !important;
    left: auto !important;
    right: auto !important;
  }
}

@media (max-width: 637px) {
  .thirdModal {
    .ant-modal-content {
      .ant-modal-body {
        padding: 10px;
      }
    }
  }
}
