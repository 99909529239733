.Background-mobile-tabel {
  // background: #fff !important;
  // border-radius: 15px;
  // padding: 10px;
  // box-shadow: 0px 3px 6px rgb(0 0 0 / 16%);
  // margin-bottom: 20px;
  .responsiveTable td.pivoted {
    padding-left: calc(45% + 10px) !important;
    // border: 1px solid  rgb(0 0 0 / 16%) !important;
    min-height: 26px;
  }
  .responsiveTable tbody tr {
    background: #fff !important;
    border-radius: 15px;
    padding: 10px;
    box-shadow: 0px 3px 6px rgb(0 0 0 / 16%);
    margin-bottom: 20px;
    border: none !important;
  }
}

.main-header-div {
  background: #fff;
  border-radius: 15px;
  box-shadow: 0px 3px 6px rgb(0 0 0 / 16%);
  margin-bottom: 15px;
  display: grid;
  .clear-btn {
    background: #ea3943;
    border-radius: 5px;
    width: 35px;
    height: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    font-size: 20px;
    // margin-right: 10px;
    margin-left: 10px;
    path#Path_6864 {
      fill: #fff;
    }
  }
  .search-div {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 10px 10px 5px 10px;
  }
  .item-header {
    margin: 5px;
    justify-content: center;
    align-items: center;
    height: 35px;
    border-radius: 10px;
    color: #fff;
    background: #1d3445;
    display: flex;
  }
  .item-header-active {
    margin: 5px;
    justify-content: center;
    align-items: center;
    height: 35px;
    border-radius: 10px;
    color: #fff;
    background: #1da193;
    display: flex;
  }
}

.Background-mobile-tabel-noData {
  background: #fff !important;
  border-radius: 15px;
  padding: 10px;
  box-shadow: 0px 3px 6px rgb(0 0 0 / 16%);
  height: 72px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.hamburgerMenu {
  padding-left: 20px;
  display: flex;
  align-items: center;
}

.base-mobile-with-header {
  width: 100%;
  height: calc(100vh - 90px);
  margin: 10px;
  .header-div {
    background: #fff;
    box-shadow: 0px 3px 6px rgb(0 0 0 / 16%);
    height: 70px;
    margin-bottom: 15px;
    border-radius: 15px;
    display: flex;
    align-items: center;
    button.ant-btn.ant-btn-default.btn-header {
      background: #1da193;
      margin-left: 20px;
      color: #fff;
      border: 0px;
      border-radius: 5px;
    }
  }
}

.custom-header-div {
  background: #fff;
  height: 70px;
  display: flex;
  align-items: center;
  border-radius: 15px;
  margin-bottom: 20px;
  box-shadow: 0px 3px 6px rgb(0 0 0 / 16%);
  button.ant-btn.ant-btn-default.btn-header {
    background: #1da193;
    margin-left: 20px;
    color: #fff;
    border: 0px;
    border-radius: 5px;
  }
}

.base-mobile-div {
  .header-div {
    background: #fff;
    height: 70px;
    display: flex;
    align-items: center;
    border-radius: 15px;
    margin-bottom: 20px;
    box-shadow: 0px 3px 6px rgb(0 0 0 / 16%);
  }
}
