@import "../../../assets/globalVariables.scss";

.applicantCard {
  display: flex;
  flex-direction: column;
  width: 400px;
  border-radius: 5px;
  background: #f8f8fa;
  padding: 12px;
  animation: applicantReverse 0.3s ease forwards;
  cursor: pointer;
  &:hover {
    background: #f1f5fd;
    // width: calc(100% - 20px);
    animation: applicant 0.3s ease forwards;
  }
  .applicantNameStatus {
    display: flex;
    justify-content: space-between;
    .applName {
      font-weight: 600;
      font-size: 14px;
    }
    .ant-badge {
      color: inherit;
      .ant-badge-status-dot {
        width: 12px !important;
        height: 12px !important;
      }
      .ant-badge-status-text {
        color: inherit;
      }
    }
  }
  .interviewDate {
    display: flex;
    align-items: center;
    gap: 5px;
    .clockIcon {
      position: relative;
      top: 1px;
    }
  }
  .interviewDate div svg {
    padding: 0 !important;
  }
  &.selected {
    background: #f1f5fd;
  }
  &.dark {
    background: $d-black2;
    &.selected {
      border: 1px solid $grey-3;
    }
  }
}
