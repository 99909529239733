@import "../../../../../assets/globalVariables.scss";

.employeeSinceCard {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  overflow: hidden;
  .employeeSinceContainer {
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 100%;
    height: 100%;
    overflow-y: auto;
    padding: 10px;
    .employeeItem {
      display: flex;
      gap: 10px;
      justify-content: space-between;
      width: 100%;
      align-items: center;
      white-space: nowrap;
    }
  }
  .sortIcon {
    display: flex;
    width: 50px;
    height: 50px;
    background-color: transparent;
    padding: 5px;
    border-radius: 50%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) rotate(90deg);
    cursor: pointer;
    svg {
      display: none;
    }
    &:hover {
      width: auto;
      height: auto;
      background-color: #e7eef2;
      animation: appearSoftly 200ms forwards;
      svg {
        display: block;
      }
    }
  }
  &.dark {
    .line-card {
      border-color: $grey-3;
    }
    .sortIcon:hover {
      background-color: $d-black2;
      box-shadow: 0 0 3px 0 $grey-1;
    }
  }
}

@keyframes appearSoftly {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
