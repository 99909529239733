@import "../../../../../assets/globalVariables.scss";

.workShiftAdminCardWrapper {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  height: 100%;
  .headerDataWorkShift {
    display: flex;
    align-items: center;
    flex-direction: row;
    gap: 1.4rem;
    padding-left: 20px;
    padding-right: 20px;
    height: 35px;
    .header-shift-item {
      font-weight: 600;
      font-size: 15px;
      height: 100%;
      display: flex;
      align-items: center;
    }
  }
  .overtimeWorkshift {
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    // height: 235px;
    height: 100%;
  }

  .shift-overtime {
    display: flex;
    align-items: center;
    width: 100%;
    padding: 0px 16px;
    height: 28px;
    &:nth-child(odd) {
      background-color: rgb(245, 245, 247);
    }
  }

  .admin-noOvertime {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding-top: 45px;
    .admin-noOvertimeText {
      display: flex;
      justify-content: center;
      width: 284px;
      height: 22px;
      font: normal normal 600 16px/19px Open Sans;
      letter-spacing: 0px;
      opacity: 1;
      margin-top: 10px;
    }
    .admin-noOvertimeText-text {
      font-size: 15px;
      width: 307px;
      height: 57px;
      text-align: center;
      letter-spacing: 0px;
      opacity: 1;
      margin-top: 5px;
    }
  }
  &.dark {
    .shift-overtime:nth-child(odd) {
      background-color: $d-black2;
    }
  }
}
