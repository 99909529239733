@import url(https://fonts.googleapis.com/css?family=Open+Sans:300italic,400italic,700italic,300,400,600,700);
@import "./assets/globalVariables.scss";

#root {
  height: 100dvh !important;
}

.globalPageWrapper {
  display: flex;
  flex-direction: column;
  gap: 15px;
  padding: 15px;
  width: 100%;
  height: 100%;
}
@media (max-width: 450px) {
  .globalPageWrapper {
    padding: 5px;
    gap: 10px;
    overflow-y: auto;
  }
  ::-webkit-scrollbar {
    width: 5px !important;
    height: 5px !important;
  }
}
.ant-tabs-nav {
  margin: 0 !important;
}

.ag-theme-alpine,
.ag-theme-balham {
  .agGridComponent {
    .ag-root-wrapper {
      border-bottom-left-radius: 15px !important;
      border-bottom-right-radius: 15px !important;
      .ag-header {
        // background: var(--header-background);
        border: none;
        .ag-header-row.ag-header-row-column {
          color: #ffffff !important;
          font-weight: 400;
          font-size: medium;
        }
      }
      .ag-center-cols-viewport {
        height: 100%;
        .ag-center-cols-container {
          height: 100%;
          .ag-row-odd.ag-row.ag-row-position-absolute.ag-after-created.ag-row-no-focus {
            background: #f5f5f7 !important;
            border: none !important;
            &:hover {
              background: #e1e1e6 !important;
            }
          }
          .ag-row-even.ag-row.ag-row-position-absolute.ag-after-created.ag-row-no-focus {
            border: none !important;
            &:hover {
              background: #e1e1e6 !important;
            }
          }
        }
      }
      // .ag-checkbox-input-wrapper {
      //   &::after {
      //     color: #1d3445 !important;
      //   }
      //   &.ag-checked {
      //     &::after {
      //       color: #1d3445 !important;
      //       background: #ffffff !important;
      //     }
      //   }
      // }
      // .ag-row-selected {
      //   background: #cdd3df !important;
      // }
      // .ag-paging-panel {
      //   border: none !important;
      // }
      // a {
      //   color: #323338;
      // }
      // span.ag-header-icon.ag-header-cell-menu-button {
      //   color: #fff !important;
      // }
      // span.ag-icon.ag-icon-asc {
      //   color: #fff !important;
      // }
    }
  }
}

a:hover {
  text-decoration: none;
}
svg > g > g.google-visualization-tooltip {
  pointer-events: none;
}

//SCROLLBAR

/* width */
::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: transparent;
}

/* Handle */
::-webkit-scrollbar-thumb {
  // background: #1f2a44;
  // Autumn
  //winter
  // background: #35415e !important;
  background: #1d3445 !important;
  border-radius: 5px;
  &:hover {
    background-color: #35415e;
  }
}

.ag-center-cols-viewport {
  overflow-x: hidden !important;
}

.pad-0-20 {
  padding: 0 20px;
}

.ft-30 {
  font-size: 30px;
}

.flex {
  display: flex;
}

.flex-end {
  justify-content: flex-end;
}

.dark {
  &.drag-card-wrapper .recharts-wrapper {
    *:not(.recharts-rectangle) {
      background: $d-black3 !important;
    }
  }
  .recharts-wrapper {
    *:not(.recharts-rectangle) {
      stroke: $white;
      fill: $white;
      background: $d-black2;
    }
  }
  .darkChart {
    rect {
      fill: none;
    }
    text {
      fill: $white;
    }
  }
  .antCustomStepper {
    $waiting-inner-triangle-border: 18px solid var($d-black4) !important;
    $outer-triangle-border: 25px solid #fff;
  }

  .ant-input,
  .ant-input-outlined {
    color: $white;
    background: unset;
    box-shadow: 0px 0px 2px $white;
    input {
      box-shadow: none;
    }
    &:hover,
    &:focus,
    &:focus-within {
      background: unset;
    }
    &.ant-input-status-error {
      background: unset;
      &:hover,
      &:focus,
      &:focus-within {
        background: unset;
      }
      input {
        border: none !important;
      }
    }
  }
  .ant-input {
    color: $white;
  }
  ::placeholder {
    color: $white !important;
    opacity: 0.8;
  }
  .ant-empty,
  .ant-empty-image {
    * {
      color: $white;
      fill: none;
    }
  }
  .ant-table-wrapper .ant-table {
    .ant-table-thead {
      * {
        color: $white;
      }
    }
    .ant-table-row:nth-child(odd) {
      background-color: $d-black2 !important;
    }
    tr,
    td,
    .ant-table-cell {
      border: none;
      &.ant-table-cell-row-hover {
        background-color: $d-black2 !important;
      }
      &:hover {
        background: transparent;
      }
    }
  }
  .ant-form {
    color: $white;
    .ant-form-item {
      label {
        color: $white;
      }
      input {
        background: unset;
        .ant-picker-input & {
          box-shadow: 0px 0px 2px $white;
        }
        &.form-control {
          box-shadow: 0px 0px 2px $white;
          border: none;
          color: $white;
        }
        &:disabled {
          color: $grey-2;
        }
      }
      .anticon,
      .ant-input-suffix {
        color: $white;
      }
      &.ant-form-item-has-error {
        .ant-picker {
          background: none;
          box-shadow: 0px 0px 2px $red;
        }
        .ant-picker-status-error input {
          border: none !important;
        }
        input {
          border: 1px solid $red;
          box-shadow: 0px 0px 2px $red;
          background: unset;
        }
      }
      .ant-form-item-explain-error {
        color: #f0747b;
      }
    }
  }
  .ant-select,
  .ant-input-number,
  .rmdp-input {
    background: unset;
    border-radius: 5px;
    color: $white;
    box-shadow: 0px 0px 2px $white;
    &:hover {
      box-shadow: 0px 0px 2px $white;
    }
    &.ant-select-disabled .ant-select-selector {
      color: $grey-2;
    }
  }
  .headerDropdown .ant-select {
    border: none !important;
  }
  .ant-select-selector,
  .ant-input-number-outlined,
  .ant-input-number .ant-input-number-input,
  .ant-select-open .ant-select-selection-item {
    color: $white;
    background: transparent;
    .ant-select-selection-placeholder {
      color: inherit;
      opacity: 0.88;
    }
    .ant-select-selection-item-remove {
      color: inherit;
    }
  }
  .ant-input-number {
    &:not(.customAgPaginator) & {
      box-shadow: 0px 0px 2px $white;
    }
    &.ant-input-number-disabled:hover {
      box-shadow: 0px 0px 2px $white;
    }
  }
  .ant-select-arrow {
    color: $white;
  }
  .ant-picker-calendar,
  .ant-picker-week-panel-container {
    color: $white;
    background: $d-black2;
    .ant-picker-panel {
      color: $white;
      background: $d-black2;
      border-top: 1px dashed $white;
      border-bottom: 1px dashed $white;
    }
    th,
    .ant-picker-cell-in-view {
      color: $white;
    }
    .ant-picker-cell:not(.ant-picker-cell-in-view) {
      color: $d-black6;
      color: $d-black6;
    }
  }
  .react-calendar-timeline {
    color: $white;
    background: $d-black2;
    .rct-calendar-header {
      > div {
        background: inherit;
      }
    }
    .rct-dateHeader {
      background: inherit;
    }
    .rct-dateHeader-primary,
    .rct-dateHeader {
      color: $white;
    }
  }
  .ant-picker {
    box-shadow: 0px 0px 2px $white;
    background: unset;
    color: $white;
    .ant-picker-input {
      input {
        color: $white;
      }
    }
    ::placeholder,
    &::placeholder {
      color: $grey-2;
    }
    &.ant-picker-disabled {
      background: unset;
      color: $grey-2;
      input {
        color: $grey-2;
      }
      &:hover {
        box-shadow: 0px 0px 2px $white;
      }
    }
    &.ant-picker-range .ant-picker-active-bar {
      background: $grey-2;
    }
    &.ant-picker-outlined {
      &:hover,
      &:focus,
      &:focus-within {
        background: unset;
      }
    }
  }
  .ant-alert-warning {
    background: none;
    color: $d-yellow;
    border-color: $d-yellow;
    .ant-alert-message,
    .ant-alert-description {
      color: inherit;
    }
  }
  .ant-alert-info {
    background: none;
    color: $d-blue;
    border-color: $d-blue;
    .ant-alert-message,
    .ant-alert-description {
      color: inherit;
    }
  }
  .ant-alert-success {
    background: none;
    color: #b7eb8f;
  }
  .ant-alert-icon {
    color: inherit;
  }
  .quill {
    box-shadow: 0px 0px 2px $white;
  }
  .ant-typography,
  .ant-checkbox-wrapper {
    color: $white;
  }
  .ant-breadcrumb,
  .ant-breadcrumb-separator {
    color: $grey-3;
    li:last-child {
      color: $white;
    }
  }
  .react-tel-input {
    .flag-dropdown {
      background: none;
      border: none;
      border-right: 1px solid $white;
      &.open,
      .selected-flag {
        background: none;
        &:hover,
        &:focus {
          background: none;
        }
      }
    }
    .country-list {
      background: $d-black2;
      .country {
        &:hover,
        &.highlight {
          background: $d-black3;
        }
        .country-name {
          color: $white;
        }
      }
    }
  }
  .ant-list-item {
    a {
      color: $white !important;
    }
    .ant-list-item-meta-description {
      color: $grey-2 !important;
    }
  }
  .ant-list .ant-list-empty-text {
    color: $grey-2 !important;
  }
  .ant-collapse {
    border: none;
    background: $d-black1;
    .ant-collapse-header {
      color: $white;
    }
    .ant-collapse-content {
      border: none;
      color: $white;
      background: $d-black3;
    }
  }
  .ant-tree {
    color: $white;
    .ant-tree-treenode .ant-tree-node-content-wrapper:hover {
      color: $grey-2 !important;
    }
  }
  .ant-badge,
  .ant-badge .ant-badge-status-text {
    color: inherit;
  }
  .ant-badge-status-default {
    background: $white !important;
  }
  .Grafike {
    svg > rect {
      fill: none;
    }
    text {
      fill: $white;
    }
  }
  .google-visualization-tooltip {
    background: $d-black3;
  }
}
.darkDropdown.rmdp-wrapper,
.darkDropdown.ant-select-dropdown,
.darkDropdown .ant-picker-panel-container {
  background-color: $d-black4;
  .ant-select-item {
    color: $white;
    opacity: 0.88;
  }
  .ant-picker-cell-disabled::before {
    background: $d-black5;
  }
  .ant-select-item-option-selected {
    background-color: $d-black2;
  }
  .ant-picker-now a {
    color: $white;
  }
  .ant-picker-header,
  .rmdp-panel-header,
  .rmdp-header-values {
    color: $white;
    border-bottom: 1px solid $d-black6;
    button {
      color: $white;
    }
  }
  .rmdp-arrow {
    border-color: $white;
  }
  .rmdp-day,
  .rmdp-week-day {
    color: $white;
    span:hover {
      background: $d-black5;
    }
    &.rmdp-today span {
      background: $d-black3;
    }
    &.rmdp-selected span {
      background: $d-black2 !important;
      border: none;
    }
  }
  .rmdp-panel-body li,
  .b-deselect {
    background: $d-black2;
  }
  .ant-picker-content {
    th,
    .ant-picker-cell-in-view {
      color: $white;
    }
    .ant-picker-cell:not(.ant-picker-cell-in-view) {
      color: $d-black6;
      color: $d-black6;
    }
    .ant-picker-cell-selected,
    .ant-picker-cell-range-end,
    .ant-picker-cell-range-start {
      .ant-picker-cell-inner {
        background: $d-black2;
      }
    }
    .ant-picker-cell-range-start,
    .ant-picker-cell-in-range,
    .ant-picker-cell-range-end {
      &::before {
        background: $d-black3;
      }
    }
    .ant-picker-cell-today .ant-picker-cell-inner::before {
      border: 1px solid $white;
    }
  }
}
.darkDropdown {
  color: $white;
  &.ant-select-dropdown {
    box-shadow: 0px 0px 5px $grey-2;
  }
  &.ant-picker-dropdown .ant-picker-panel-container {
    box-shadow: 0px 0px 5px $grey-2;
  }
  .ant-input-outlined {
    color: $white;
    background: none;
    box-shadow: 0px 0px 2px $white;
    &::placeholder {
      color: $white;
      opacity: 0.8;
    }
  }
  .ant-typography {
    color: $white;
  }
  .ant-dropdown-menu {
    background-color: $d-black4;
    .ant-dropdown-menu-item {
      color: $white;
      border-bottom: 2px solid $d-black2;
    }
  }
  .ant-picker-time-panel-cell-inner {
    color: inherit !important;
    background: inherit !important;
  }
  .ant-picker-time-panel-cell {
    color: $white;
    &.ant-picker-time-panel-cell-selected {
      background: $d-black2 !important;
    }
    &.ant-picker-time-panel-cell-disabled {
      color: $grey-3 !important;
    }
  }
  .ant-picker-week-panel-row-selected,
  .ant-picker-week-panel-row-hover {
    td.ant-picker-cell:before {
      background: $d-black2;
    }
  }
  .ant-select-item-option-active {
    background: $d-black5;
  }
}

.ant-modal {
  border-radius: 10px;
  .ant-modal-content {
    box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.65);
    border-radius: 10px;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    padding: 0;
    .ant-modal-close {
      top: 0;
      inset-inline-end: 0;
      width: 45px;
      height: 45px;
      justify-content: center;
      border-radius: 0;
      border-top-right-radius: 10px;
    }
    .ant-modal-header {
      display: flex;
      align-items: center;
      margin: 0;
      padding: 0;
      border-radius: 0;
      height: 45px;
      border-radius: 10px 10px 0 0;
      .ant-modal-title {
        font: normal 600 normal 16px / 25px Open Sans;
        margin-left: 24px;
        //winter
        // margin-left: 48px;
      }
    }
    .ant-modal-body {
      padding: 20px 24px;
      .ant-form-item {
        width: 100%;
      }
    }
    .ant-modal-footer {
      display: flex;
      justify-content: space-between;
      margin: 0;
      padding: 10px 24px;
      border-top: 1px solid #f0f0f0;
      .mondayButtonContainer:only-child {
        margin-left: auto;
      }
    }
  }
}
.lightHeader {
  .ant-modal-close {
    background: #e7eef2 0% 0% no-repeat padding-box;
    //winter
    // background: #1d3445 0% 0% no-repeat padding-box;
    &:hover {
      //winter
      // background: #1d3445;
      background: #d5dbdf;
    }
    .ant-modal-close-x {
      align-items: center;
      height: 100%;
      color: #1d3445;
      //winter
      // color: #fff !important;
      // svg {
      //   fill: #e7eef2;
      // }
    }
  }
  .ant-modal-header {
    background: #e7eef2 0% 0% no-repeat padding-box;
    //winter
    // background: #1d3445 0% 0% no-repeat padding-box;
    // background-image: url("../src/assets/holidays/icons/snowflake.svg");
    // background-size: auto 80%;
    // background-position-y: center;
    // background-position-x: 5px;
    .ant-modal-title {
      //winter
      // color: #e7eef2;
      color: #1d3445;
    }
  }
}
.darkHeader {
  color: $white;
  box-shadow: 0px 0px 5px #fbfbfb;
  .ant-modal-close {
    color: $white;
    background: $d-black1 0% 0% no-repeat padding-box;
    &:hover {
      background: $d-black2;
    }
    .ant-modal-close-x {
      align-items: center;
      height: 100%;
      color: #e7eef2;
      svg {
        fill: $white;
      }
    }
  }
  .ant-modal-header {
    background: $d-black1 0% 0% no-repeat padding-box;
    .ant-modal-title {
      color: #fff;
    }
  }
  .ant-modal-body {
    background: $d-black3;
  }
  .ant-modal-content .ant-modal-footer {
    background: $d-black3;
    border-top: 2px solid $d-black1;
  }
}
.ant-table-wrapper .ant-table {
  background: inherit;
  color: inherit;
  .ant-table-thead {
    color: inherit;
    height: 35px !important;
  }
  tr,
  th {
    background: unset !important;
    color: unset;
  }
  // .ant-table-row {
  //   cursor: pointer;
  // }
  .ant-table-cell {
    padding: 0px 16px !important;
    height: 35px;

    //   border-right: 1px solid #acacac;
  }
  .ant-table-row:nth-child(odd) {
    background-color: $grey-1 !important;
  }
  .ant-table-cell {
    border-bottom: none;
  }
  .ant-table-cell:last-child {
    border-right: none;
  }
}
.ant-picker-calendar {
  background: #f1f7fb;
  .ant-picker-panel {
    background: #f1f7fb;
    border-top: 1px dashed #232323;
    border-bottom: 1px dashed #232323;
  }
}
.react-calendar-timeline {
  background: #f1f7fb;
  .rct-header-root {
    background: inherit;
  }
  .rct-dateHeader-primary,
  .rct-dateHeader {
    color: #1d3445;
  }
}
.ant-dropdown-menu {
  background: #f5f5f7;
  border-radius: 8px;
  padding: 0px 0px;
}
.ant-popover {
  .ant-popover-inner {
    padding: 0;
    .ant-popconfirm-inner-content {
      display: flex;
      align-items: center;
      // padding: 10px;
      .ant-popconfirm-message {
        margin: 0;
        align-items: center;
        padding: 0 15px;
        .ant-popconfirm-title {
          color: #323338;
          font: normal normal normal 16px Open Sans;
        }
      }
      .ant-popconfirm-buttons {
        button {
          margin-inline-start: 0;
        }
      }
    }
  }
}
.ant-form {
  display: flex;
  flex-direction: column;
  gap: 10px;
  .ant-form-item {
    margin: 0;
    input {
      background: #f1f7fb;
    }
  }
}
.ant-select {
  background: #f1f7fb;
  border-radius: 5px;
}
.ant-select-selector {
  background: transparent !important;
  border: none !important;
}
.ant-color-picker-trigger {
  background: unset;
}
.ant-picker {
  background: #f1f7fb;
  border: none;
  width: 100%;
  &.ant-picker-outlined {
    &:hover,
    &:focus,
    &:focus-within {
      background: #f1f7fb;
    }
  }
  .ant-picker-input {
    color: inherit;
  }
  .ant-picker-suffix,
  .ant-picker-clear {
    color: inherit;
  }
  &.ant-picker-disabled {
    background: #f1f7fb;
  }
}
.ant-input,
.ant-input-outlined {
  background: #f1f7fb;
  box-shadow: none;
  border: none;
  &:hover,
  &:focus,
  &:focus-within {
    background: #f1f7fb;
  }
}
.ant-switch {
  height: 1px;
  background-color: #dedede;
  width: 60px;
  .ant-switch-handle {
    margin-top: -15px;
  }
  .ant-switch-handle::before {
    margin-top: 3px;
    width: 20px;
    height: 20px;
    background-position: center;
    border-radius: 50%;
    z-index: 3;
    background-color: red !important;
  }
}
.ant-switch.ant-switch-checked {
  .ant-switch-handle::before {
    margin-top: 3px;
    width: 20px;
    height: 20px;
    background-position: center;
    border-radius: 50%;
    z-index: 3;
    background-color: $green !important;
  }
}
.ant-drawer {
  .ant-drawer-header {
    height: 45px;
    padding: 16px;
    background-color: #e7eef2;
    .ant-drawer-title {
      color: #1d3445;
      font-family: Open Sans;
      font-size: 16px;
      font-weight: 600;
      line-height: 22px;
      letter-spacing: 0em;
      text-align: left;
    }
  }
  .darkDrawer {
    .ant-drawer-header {
      background-color: $d-black1;
      .ant-drawer-title {
        color: $white;
      }
    }
    .ant-drawer-body {
      background: $d-black3;
      color: $white;
    }
  }
}
.quillTextEditor {
  background: #f1f7fb;
  .ql-container.ql-snow {
    border: none !important;
  }
  .ql-toolbar.ql-snow {
    border-top: none !important;
    border-left: none !important;
    border-right: none !important;
    border-bottom: 1px solid #fff;
    width: 100%;
  }
  &.dark {
    background: unset;
    box-shadow: 0px 0px 2px $white;
    border-radius: 5px;
    .ql-toolbar.ql-snow {
      border-bottom: 1px solid $grey-3 !important;
    }

    * {
      color: $white;
      stroke: $white;
      :hover {
        color: $white;
        stroke: $white;
      }
    }
    ::before {
      color: inherit;
    }
  }
}
.ant-tree {
  background: none;
  color: #1d3445;
  .ant-tree-treenode .ant-tree-node-content-wrapper {
    .file,
    .folder {
      display: flex;
      align-items: center;
      gap: 5px;
    }
    &:hover {
      color: lighten(#1d3445, 20%) !important;
    }
  }
}
.globalPopover {
  .ant-popconfirm-inner-content {
    padding: 5px 10px;
    gap: 10px;
    .ant-popconfirm-message {
      padding: 0 !important;
    }
    .ant-popconfirm-buttons {
      display: flex;
      gap: 5px;
      .ant-btn {
        height: 28px;
        width: max-content;
      }
    }
    .ant-btn-variant-outlined {
      color: #ea3943;
      background: none;
      border-color: #ea3943;
      &:hover {
        color: #fff;
        background-color: #ea3943;
        border-color: #ea3943;
      }
    }
  }
  .ant-popover-title {
    margin: 0;
    padding: 10px 0 0 10px;
  }
  .drag-pop-over {
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 0;
    padding: 5px 0;
    .action-label {
      justify-content: center;
      color: #323338;
      cursor: pointer;
      height: 25px;
      padding: 2px 0 0 10px;
      &:hover {
        background: #1d3445;
        color: white;
      }
    }
  }
  &.dark {
    .ant-popconfirm-inner-content {
      .ant-popconfirm-message .ant-popconfirm-title {
        color: $grey-2;
      }
      .ant-btn-variant-outlined {
        color: #f0747b;
        border-color: #f0747b;
        &:hover {
          color: #fff;
        }
      }
    }

    .ant-popover-inner,
    .ant-popover-arrow:before {
      background: $d-black2;
    }
    .ant-popover-title {
      color: $white;
    }
    .drag-pop-over .action-label {
      color: $grey-2;
      &:hover {
        background: $d-black3;
        color: $white;
      }
    }
  }
}
.ant-card.dark {
  background: $d-black2;
  box-shadow: 0px 0px 5px #75eade;
  border: none;
  color: $white;
  .ant-card-head {
    border: none;
    color: $white;
    background: $d-black1;
  }
}
.rmdp-input {
  background-color: #f1f7fb;
  border: none;
}
.react-tel-input {
  .flag-dropdown {
    background-color: #f1f7fb;
    border: none;
  }
  .form-control {
    border: none;
    width: 100%;
  }
  .country-list {
    width: 180px;
    overflow-y: auto;
  }
}
.newFeatureTour {
  .ant-tour-arrow::after {
    background: #75eade;
  }
  .ant-tour-inner {
    box-shadow: 0 0px 30px $green;
    border: 2px solid #75eade;
    color: #1d3445;
  }
  .ant-tour-buttons {
    display: none;
  }
}
.cancelModal,
.confirmModal {
  .ant-modal-content {
    .ant-modal-confirm-body {
      display: flex;
      align-items: center;
      flex-direction: column;
    }
    .ant-modal-confirm-title {
      text-align: center;
      padding-top: 10px;
      color: red;
      font-size: 25px;
    }
    .ant-modal-confirm-content {
      text-align: center;
      .text-bold {
        font-weight: 700;
      }
    }
    .ant-modal-confirm-btns {
      display: flex;
      justify-content: center;
    }
    .ant-btn-confirm {
      border-radius: 5px;
      border-color: #20ac9d;
      background-color: #20ac9d;
    }
    .ant-btn-confirm:hover {
      background-color: #20ac9ecc;
      border-color: #20ac9ecc;
    }
  }
  &.dark {
    box-shadow: 0px 0px 5px #fbfbfb;
    color: $white;
    .ant-modal-content {
      background: $d-black3;
      .ant-modal-confirm-title {
        color: #f0747b;
      }
    }
  }
}
.custom-swal-dark.swal2-popup {
  background: $d-black2 !important;
  color: $white !important;
  box-shadow: 0px 0px 5px #fbfbfb;
}
.custom-swal-dark,
.custum-swal-dark,
.custum-swal-loginError-dark,
.custum-swal-loginSuccess-dark {
  &:not(:has(.swal2-toast)) {
    box-shadow: 0px 0px 5px #fbfbfb;
  }
  .swal2-toast {
    background: $d-black2 !important;
    color: $white !important;
    box-shadow: 0px 0px 5px #fbfbfb;
  }
  ::after,
  ::before,
  .swal2-success-fix,
  .swal-icon--success__hide-corners,
  .swal2-success-circular-line-left,
  .swal2-success-circular-line-right {
    display: none;
  }
  .swal-title {
    color: $white;
  }
  input {
    color-scheme: dark;
  }
  .swal-button {
    background: none;
    border: 1px solid $white;
    &:hover {
      background: $d-black3;
    }
  }
}
@media (max-width: 450px) {
  .ant-modal {
    .ant-modal-content {
      .ant-modal-header {
        width: calc(100% - 45px);
        border-radius: 10px 0 0 0;
      }
      .ant-modal-body {
        max-height: calc(100dvh - 150px);
        // max-width: calc(100dvw - 50px);
        padding: 10px 14px;
        overflow-y: auto;
      }
    }
  }
  .mondayButtonContainer {
    .mondayButtonText {
      min-width: max-content !important;
    }
  }
}
.ant-btn.mondayButtonGreen {
  &:hover {
    border-color: $green !important;
  }
}

@mixin textEllipsis() {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

@mixin cardLayout() {
  background-color: #fff;
  box-shadow: 0px 1px 4px #00000029;
  border-radius: 10px;

  .cardHeader {
    @include text(16px, 700, 0, #323338);
    @include flex(center, flex-start);
    background-color: #f8f8fa;
    padding: 0 20px;
    height: 45px;
    margin: -20px -20px 20px;
    // border-radius: 20px 20px 0 0;
  }
}

@mixin text(
  $font-size,
  $font-weight,
  $letter-spacing,
  $color,
  $text-transform: none,
  $opacity: 1,
  $important: false
) {
  @if $important == true {
    font-size: $font-size !important;
    font-weight: $font-weight !important;
    opacity: $opacity !important;
    text-transform: $text-transform !important;
    letter-spacing: $letter-spacing !important;
    color: $color !important;
  }
  @if $important == false {
    font-size: $font-size;
    font-weight: $font-weight;
    opacity: $opacity;
    text-transform: $text-transform;
    letter-spacing: $letter-spacing;
    color: $color;
  }
}

@mixin flex($align-items, $justify-content, $direction: row) {
  display: flex;
  flex-direction: $direction;
  align-items: $align-items;
  justify-content: $justify-content;
}

@mixin square($width, $height, $radius, $background: #fff, $opacity: 1) {
  width: $width;
  height: $height;
  border-radius: $radius;
  opacity: $opacity;
  background-color: $background;
}

@mixin circle($size, $color, $opacity: 1) {
  @include square($size, $size, 50%, $color, $opacity);
}

@mixin transition($time) {
  transition: all $time ease-in-out;
}

@mixin absolute(
  $top: unset,
  $right: unset,
  $bottom: unset,
  $left: unset,
  $position: absolute
) {
  position: $position;
  top: $top;
  right: $right;
  bottom: $bottom;
  left: $left;
}

@mixin mondayButton($backgroundColor, $hoverColor, $textColor: #fff) {
  background-color: $backgroundColor !important;
  color: $textColor !important;
  .mondayButtonText {
    min-width: 100px;
  }

  .mondayButtonDivider {
    background-color: $hoverColor !important;
  }

  &:hover,
  &:active,
  &:visited,
  &:focus {
    background-color: $hoverColor !important;
    color: $textColor;

    .mondayButtonDivider {
      background-color: $backgroundColor !important;
    }
  }
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

// Autumn
.mondayButtonBlue {
  @include mondayButton(#2e5d9e, #0f5c97);
  // @include mondayButton(#0f5c97, #0f5c97);
}
.mondayButtonRed {
  @include mondayButton(#ea3943, #eb4345);
  // @include mondayButton(#fe4c4a, #eb4345);
}
.mondayButtonGreen {
  @include mondayButton(#1da193, #1da193, #fff);
}
.mondayButtonGrey {
  @include mondayButton(#f5f5f7, #e6e9ef, #323338);
}
.mondayButtonDarkGrey {
  @include mondayButton(#787677, #787677, #fbfbfb);
}
.mondayButtonYellow {
  @include mondayButton(#f5a623, #e6951e, #fbfbfb);
}
@mixin flex($align-items, $justify-content, $flex-direction: row) {
  display: flex;
  align-items: $align-items;
  justify-content: $justify-content;
  flex-direction: $flex-direction;
}

@media (max-width: 450px) {
  body {
    -webkit-overflow-scrolling: touch;
  }
}
