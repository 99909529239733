.wageInfoContainer{
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  height: 100%;
  padding: 0px 20px;
  .wageInfoRow{
    display: grid;
    grid-template-columns: 1fr 1fr;
    width: 100%;
    font: normal normal normal 16px Open Sans;
    .infoLabel{
      font-size: 14px;
    }
    .infoData{
      font-weight: 600;
    }
  }
}