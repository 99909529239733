.swal2-styled.swal2-confirm {
  background: rgb(24, 170, 115) !important;
}

.paysub-btn {
  border: none;
  border: none;
}

.header-wrapper {
  // margin-left: 20px;
  display: flex;
  flex-direction: row;
  gap: 20px;
  //winter
  // padding: 24px;
  // box-shadow: 0px 1px 4px rgb(0 0 0 / 16%);
  // border-radius: 10px;
  .wage-header__card {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 25px 10px;
    // min-width: 255px;
    // margin-right: 23px;
    border-radius: 10px;
    flex-grow: 1;
    flex-basis: 0px;
    background-color: red;
    &:hover {
      opacity: 0.8;
      cursor: pointer;
    }
    .statusValue {
      font-size: 30px;
      font-weight: 600;
      opacity: 1;
      text-transform: none;
      letter-spacing: 0;
      color: #fff;
      line-height: 1;
    }
    .statusName {
      font-size: 20px;
      font-weight: 400;
      opacity: 1;
      text-transform: none;
      letter-spacing: 0;
      color: #fff;
      max-width: 100%;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      line-height: normal;
    }
  }
}

.paymentTypeIcons {
  svg {
    fill: red;
  }
}

@media (max-width: 450px) {
  .ant-collapse-content-box {
    padding: 10px !important;
    .header-wrapper {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 10px;
      padding: 0;
      .wage-header__card {
        padding: 10px;
        .statusName {
          font-size: 16px;
        }
      }
    }
  }
  .row-mob-with-fix {
    width: 100% !important;
  }
}
