@import "../../../../index.scss";

.documentationConfigurationContainer {
  width: 98%;
  height: 100%;
  padding-top: 60px;
  margin-right: 500px;

//* styles header justify-content:center, and align-items:center
  .header {
    @include flex(center, center);
    flex-wrap: wrap;
    min-height: 120px;
    margin-bottom: 60px;
  }

  .headerDivider {
    height: 140px;
    margin: 0 50px;
    border-left-width: 2px;
  }
}

//* responsive DocumentationCategoriesView for iPad Air
@media (max-width: 821px) {
  .documentationConfigurationContainer {
    width: 100%;
    height: 100%;
    padding-top: 60px;
    margin: 0px;
  }
}

//* responsive DocumentationCategoriesView for iPhone 12 Pro
@media (max-width: 391px) {
  .documentationConfigurationContainer .headerDivider {
    display: none;
  }
  .documentationConfigurationContainer {
    margin-top: -75px;
  }
}