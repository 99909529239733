div#tradingview_widget_wrapper {
	height: 220px;

	a {
		display: none;
	}
	span {
		display: none;
	}
}
