@import "../../../../../assets/globalVariables.scss";

.forgotModal {
  .ant-modal-close {
    color: white;
    background: #ea3943 0% 0% no-repeat padding-box;
    &:hover {
      background: #dd363e 0% 0% no-repeat padding-box;
    }
  }
  .ant-modal-header {
    height: 45px;
    background: #ea3943 0% 0% no-repeat padding-box;
    border-radius: 10px 10px 0px 0px;
    color: white;
    display: flex;
    align-items: center;
    .ant-modal-title {
      color: white;
      font-size: 18px;
      font-family: "Open Sans", sans-serif;
    }
  }
  .forgot-modal-content {
    // background-color: #ff4343;
    .warning-title {
      display: flex;
      align-items: center;
      gap: 10px;
      width: 100%;
      font: normal normal normal 14px/19px Open Sans;
      letter-spacing: 0px;
      color: #ea3943;
      .warn-icon {
        margin-right: 10px;
        margin-top: 5px;
      }
    }
    .forgot-form {
      .first-row-forgot {
        display: grid;
        grid-template-columns: 1fr 1fr;
        row-gap: 10px;

        // display: flex;
        // flex-direction: column;
        .ant-picker.ant-picker-disabled {
          background: #f5f5f500;
          border-color: #d9d9d900;
          color: #ea3943 !important;
        }
        .ant-picker.ant-picker-disabled .ant-picker-suffix {
          color: #ea3943;
        }
        .ant-picker-input > input[disabled] {
          // color: rgba(0, 0, 0, 0.25);
          color: #ea3943;
          fill: #ea3943;
          width: 120px;
        }
        .start-shift-forgot {
          display: flex;
          gap: 10px;
        }
        .end-shift-forgot {
          display: flex;
          // gap: 60px;
          .ant-picker.ant-picker {
            color: #ea3943 !important;
          }
          .ant-picker.ant-picker-disabled .ant-picker-suffix {
            color: #ea3943;
          }
        }
      }
    }
  }
  textarea#shenimi {
    min-height: 48px;
    // width: 450px;
  }
  &.dark {
    .ant-modal-content {
      background: $d-black2;
      .warning-title {
        color: #f0747b;
        svg {
          fill: currentColor;
        }
      }
      .ant-picker {
        color: #f0747b !important;
        border: 1px solid #f0747b !important;
        input,
        .anticon {
          color: #f0747b !important;
        }
        &.ant-picker-disabled {
          border: none !important;
        }
      }
    }
  }
}
