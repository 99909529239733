@import "../../../../../../../index.scss";

//* styles documentationListCardHeader align-items:center, justify-content:space-between
.documentationListCardHeader {
  width: 100%;
  @include flex(center, space-between);

  //* styles leftSection align-items:baseline, justify-content:space-between
  .leftSection {
    @include flex(center, space-between);
  }

  //* styles documentationListCardHeaderTitle font-size:18px, font-weight:700, letter-spacing:0, and text color
  .documentationListCardHeaderTitle {
    margin-right: 47px;
    // background-color: #f5f5f7;
  }

  //* styles employeeName font-size:16px, font-weight:600, letter-spacing:0, text color, and text-overflow:ellipsis
  .employeeName {
    max-width: 450px;
    margin-right: 30px;
    @include textEllipsis();
  }

  //* includes button to create new documentaion
  .newButton {
    align-self: center;
    @include mondayButton(#71cf48, #68c142);
    .mondayButtonIcon {
      width: 30px;
    }
  }
}

//* makes responsive for iPhone 13 pro
@media (max-width: 391px) {
  .documentationListCard .formCardTitle {
    height: 80px !important;
  }
  .documentationListCardHeader {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
  }
  .documentationListCardHeader .leftSection {
    display: flex;
    flex-direction: column;
  }
  .documentationListCardHeader .documentationListCardHeaderTitle {
    font-size: 14px;
  }
  .documentationListCardHeader .addressTitle {
    font-size: 12px;
  }
  .documentationListCardHeader .newButton {
    width: 146px;
  }
  .mondayButtonText {
    font-size: 11px;
  }
  .documentationListCardHeader .newButton .mondayButtonIcon {
    width: 11px;
  }
}