@import "../../../../assets/globalVariables.scss";

.yesterdayModal {
  .ant-modal-body {
    height: 200px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0;
    .yesterday-shift-wrapper {
      // background-color: red;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      padding: 20px;
      gap: 20px;
      .yesterday-shift-text {
        width: 352px;
        // display: flex;
        text-align: center;
        font: normal normal 600 16px/22px Open Sans;
      }
    }
  }
  &.dark {
    .shift-card {
      background: $d-black2;
      box-shadow: 0 0 2px $white;
      .shifts-container .shift-card-time .label-card {
        color: $white;
      }
    }
  }
}

@media (max-width: 450px) {
  .ant-modal.yesterdayModal {
    height: 400px !important;
    .ant-modal-body {
      height: 100%;
      margin: 0px !important;
    }
    .ant-modal-content {
      height: 100% !important;
    }
    .yesterday-shift-wrapper {
      display: grid !important;
      justify-items: center !important;
    }
  }
  .ant-drawer-right.ant-drawer-open .ant-drawer-content-wrapper {
    width: 100% !important;
  }
}
