.editPayDayModal {
  width: fit-content !important;
  .editPayDayWrapper {
    display: flex;
    flex-direction: column;
    gap: 15px;
    .wrapTable {
      display: flex;
      flex-direction: column;
      gap: 10px;
      max-height: 400px;
      overflow-y: auto;
    }
    .amountCards {
      display: flex;
      justify-content: space-between;
      gap: 20px;
    }
  }
}

@keyframes chartAnimation {
  from {
    width: 0;
  }
  to {
    width: 1;
  }
}

@media (max-width: 450px) {
  // .ant-modal.new-shift {
  //   height: 100% !important;
  // }
  .new-shift .ant-modal-content {
    height: 100% !important;
  }
  .new-shift .ant-modal-body {
    height: 100% !important;
  }
  .new-shift-wrapper {
    .shortcuts {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 10px;
    }
    .to-edit-fields {
      display: grid !important;
      grid-template-columns: 1fr 1fr;
      gap: 20px !important;
      margin-left: 0 !important;
      div {
        margin: 0 !important;
      }
    }
    .warning-title {
      width: 100% !important;
    }
  }
}
@media (max-width: 390px) {
  .new-shift-wrapper .to-edit-fields {
    display: grid !important;
    gap: 5px !important;
    margin-left: -10px !important;
    .shift-field-start {
      margin-left: 20px !important;
    }
  }
  .new-shift .ant-modal-body {
    height: fit-content !important;
  }
}
