@import "../../../../assets/globalVariables.scss";

.njoftime {
  // width: 1630px;
  // padding: 5px 20px 0px 20px;
  width: 100%;
  overflow: hidden;
  height: 100%;
  .card-container {
    height: 100%;
    // border-radius: 10px;
    box-shadow: 1px 1px 1px 1px #dfdfde;
    // .ant-tabs-nav-wrap {
    //   // border-top-left-radius: 10px;
    //   // border-top-right-radius: 10px;
    // }
    border-radius: 10px;
    .ant-tabs-tab {
      margin: 0;
      border: none;
      width: -webkit-fill-available;
      justify-content: center;
      background: #f1f7fb;
      font-weight: 600;
      .tabLabel {
        display: flex;
        align-items: center;
        gap: 5px;
      }
      &.ant-tabs-tab-active {
        background: #1da193;
        svg{
          fill: #fbfbfb;
        }
        .ant-tabs-tab-btn{
          color: #fff !important;
          text-shadow: 0 0 0.25px currentColor;
        }
      }
      &:hover{
        color: #1d3445;
      }
    }
    .ant-tabs-nav-list {
      width: 100% !important;
    }
    &.dark {
      .ant-tabs-tab {
        color: $white;
        background: #2c3432;
        &.ant-tabs-tab-active{
          background: #116158;
        }
        &:hover{
          color: #f5f6f7;
        }
      }
    }
  }
}
