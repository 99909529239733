@import "../../../assets/globalVariables.scss";

.hiringCharts {
  width: 100%;
  height: 100%;
  .charts {
    display: flex;
    flex-direction: column;
    border-radius: 10px;
    padding: 10px;
    animation: hiringCharts 0.5s;
    height: 100%;
    .headerChart {
      display: flex;
      justify-content: flex-end;
      gap: 5px;
      .topTitle {
        font-weight: 600;
      }
      // .selectContent {
      //   display: flex;
      //   gap: 5px;
      // }
      .selectYear {
        width: 90px !important;
        height: 32px !important;
      }
      .selectOptions {
        width: 138px !important;
        height: 32px !important;
      }
    }
    .noData {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100%;
    }
  }
  &.dark {
    color: $white;
  }
}

// @keyframes hiringCharts {
//   0% {
//     opacity: 0;
//     transform: scale(0.9);
//   }
//   100% {
//     opacity: 1;
//     transform: scale(1);
//   }
// }
