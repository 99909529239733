@import "../../../../../../../index.scss";

//* styles docFileTreeHeader justify-content:center, align-items:flex-start
.docFileTreeHeader {
  width: 100%;
  @include flex(center, flex-start);
  height: 45px;

  .gDriveIcon {
    width: 40px;
    height: 36px;
    margin-right: 25px;
  }
}

//* styles CardHeader for iPhone 13 pro
@media (max-width: 391px) {
  .docFileTreeHeader {
    width: 65vw;
  }
  .docFileTreeHeader .docFileTreeHeaderTitle {
    font-size: 14px;
  }
}