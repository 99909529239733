.requestModal {
  .requestModalContent {
    display: flex;
    justify-content: space-between;
    gap: 20px;
    .ant-form {
      .ant-form-item-row {
        display: grid;
        grid-template-columns: 2fr 5fr;
      }

      .ant-select,
      .ant-picker,
      .ant-select-selector {
        width: 100%;
      }
    }
  }
}

@media (max-width: 768px) {
  .requestModal {
    display: none;
  }
}
