.accessConfigWrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;
  height: 100%;
  padding: 20px 25px;
  border: 1px solid #a0a0a0;
  overflow-y: auto;
  //winter
  // background: rgba(251, 251, 251, 0.6);
  // overflow: hidden;
  .overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    border-radius: 20px;
    backdrop-filter: blur(5px);
    z-index: 999;
  }
  h1 {
    font-size: large;
    font-weight: 600;
  }
  .accessConfigContent {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    column-gap: 120px;
    .configWrapper {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      cursor: pointer;
      transition: all 200ms ease-in-out;
      user-select: none;
      text-align: end;
      &:hover {
        letter-spacing: 0.25px;
      }
      button {
        margin: 20px;
      }
      .ant-switch {
        height: 1px;
        background-color: #dedede;
        width: 60px;
      }
      .ant-switch-handle {
        margin-top: -15px;
      }
      .ant-switch {
        .ant-switch-handle::before {
          margin-top: 3px;
          width: 20px;
          height: 20px;
          background-position: center;
          border-radius: 50%;
          z-index: 3;
          background-color: red !important;
        }
      }
      .ant-switch.ant-switch-checked {
        .ant-switch-handle::before {
          margin-top: 3px;
          width: 20px;
          height: 20px;
          background-position: center;
          border-radius: 50%;
          z-index: 3;
          background-color: #1da193 !important;
        }
      }
    }
  }
  &.dark {
    border: none;
  }
}

@media (max-width: 1366px) {
  .accessConfigWrapper {
    .accessConfigContent {
      grid-template-columns: repeat(3, 1fr);
    }
  }
}
@media (max-width: 850px) {
  .accessConfigWrapper {
    .accessConfigContent {
      grid-template-columns: repeat(2, 1fr);
    }
  }
}
@media (max-width: 450px) {
  .accessConfigWrapper {
    height: auto;
    .accessConfigContent {
      grid-template-columns: 1fr;
      .configWrapper {
        text-align: left;
        justify-content: space-between;
      }
    }
  }
}
