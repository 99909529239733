.card-report-header {
  background: #1d3445;
  .ant-card-body,
  .ant-collapse-content-box {
    padding: 15px !important;
    height: auto;
  }
  .ant-collapse-header {
    color: #fbfbfb !important;
  }
  .ant-card-head-title,
  .ant-collapse-header-text {
    font-weight: 400 !important;
    font-size: 16px;
    line-height: normal;
  }
  .ant-card-head {
    // background-color: #1d3943;
    //Autumn
    min-height: 45px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
  }
  .content-header-card {
    display: flex;
    align-items: center;
    gap: 15px;
    overflow-x: auto;
    padding: 2px;
    .time-period {
      display: flex;
      align-items: center;
      font-size: 16px;
      font-weight: 500;
      gap: 15px;
      .range-options {
        display: flex;
        align-items: center;
        gap: 5px;
      }
    }
  }
  .ant-select.ant-select-borderless.select-option.ant-select-single.ant-select-show-arrow {
    background: #f5f5f7;
    border-radius: 5px;
    height: 32px;
    svg {
      fill: #323338;
    }
  }
  .select-option-multi {
    border-radius: 5px;
    width: 500px !important;
    // height: 32px;
    height: auto !important;
    .ant-select-selection-item-remove {
      color: inherit;
    }
  }
}

@media (max-width: 450px) {
  .card-report-header {
    .content-header-card {
      flex-direction: column;
      .time-period {
        flex-direction: column;
        width: 100%;
        .range-options {
          flex-direction: column;
          align-items: center;
          gap: 15px;
          width: 100%;
          .range-pick-report {
            width: 100%;
          }
        }
      }
    }
    .select-option-multi {
      width: 100% !important;
    }
  }
}
