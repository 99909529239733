@import "../../../index.scss";
@import "../../../assets/globalVariables.scss";

//* styles formCard align-items:flex-start, justify-content:flex-start, and flex-direction:column
.formCard {
  @include flex(flex-start, flex-start, column);
  position: relative;
  padding: 15px 30px;
  background: #ffffff;
  width: 100%;
  border-radius: 0px 0px 4px 4px;
  box-shadow: 0px 3px 6px #00000029;
  // margin-bottom: 15px;
  transition: all 0.5s ease-in-out;

  //* styles title text font-size:25px, font-weight:600, letter-spacing:0.38px, and text color
  .formCardTitle {
    @include text(25px, 600, 0.38px, #1f2a44);
    margin-bottom: 20px;
  }

  //* styles childrenContainer align-items:flex-start, justify-content:flex-start
  .childrenContainer {
    position: relative;
    @include flex(flex-start, flex-start);
    width: 100%;
    height: 100%;
    // flex-wrap: wrap;
    transition: all 0.5s ease-in-out;
  }

  .collapseIcon {
    position: absolute;
    right: 15px;
    font-size: 20px;
    color: grey;
    transition: all 0.5s ease-in-out;
  }
  &.dark {
    background: none;
    box-shadow: 0px 0px 5px #75eade;
    color: $white;
    .formCardTitle {
      color: $white;
      background: $d-black1;
    }
  }
}

//* styles FormCard for iPhone 12 pro
@media (max-width: 391px) {
  .docConfigCard .formCardTitle {
    font-size: 18px;
  }
}
