.delayed-clockouts-container {
  display: flex;
  flex-direction: column;
  gap: 10px;
  height: 100%;
  overflow-y: auto;
  padding: 15px;
  .clockout-header {
    text-align: center;
    padding: 15px 0px;
    font: normal normal 600 16px/12px "Open Sans";
  }
  .clockout-users-names {
    padding: 5px 20px;
    font: normal normal 500 14px/12px "Open Sans";
  }
  .ant-col.ant-col-10 {
    flex: 0 0 40.66667%;
  }
}
