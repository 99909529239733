@import "../../../../../assets/globalVariables.scss";

.clockin-container {
  height: 275px;
  overflow: scroll;
  padding-top: 12px;
  .late-clock-ins {
    margin-bottom: 11px;
    margin-right: 10px;
    margin-left: 10px;
    padding-left: 7px;
    justify-content: space-between;
    align-items: center;
    display: flex;
    .clockin-time {
      width: 61px;
      height: 21px;
      background: #ea3943 0% 0% no-repeat padding-box;
      border-radius: 2px;
      font: 14px/22px "Open Sans";
      text-align: center;
      color: #ffffff;
    }
    .line-for-clockin-vones {
      display: inline-block;
      width: 185px;
      border-bottom: 1px solid rgba(0, 0, 0, 0.09);
      line-height: 0;
    }
  }
  &.dark .line-for-clockin-vones {
    border-bottom: 1px solid rgba(251, 251, 251, 0.25);
  }
}
.no-clockin-container {
  display: flex;
  flex-direction: column;
  gap: 10px;
  height: 100%;
  overflow-y: auto;
  padding: 15px;
  font: normal normal normal 14px/19px "Open Sans";
  letter-spacing: 0px;
  &.dark {
    color: unset;
    .line-card {
      border-bottom: 1px solid rgba(251, 251, 251, 0.25);
    }
    .ant-badge-status-text {
      color: $white !important;
    }
    path#Path_1 {
      fill: $white;
    }
    path#Path_3 {
      fill: $white;
    }
    path#Path_2 {
      fill: $white;
    }
    path#Path_4 {
      fill: $white;
    }
  }
  .ant-col.ant-col-10 {
    flex: 0 0 39.5% !important;
  }
}
.dep-icon {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  .dep-icon-text {
    max-height: 20px;
    max-width: calc(100% - 40px);
    overflow: hidden;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .name-badger {
    display: flex;
    align-items: center;
  }
  .line-card {
    padding: 0 5px;
  }
  .ant-badge-status-text {
    white-space: nowrap;
  }
}
