.modal-announce {
  .new-announcemenet-content {
    .new-announcemenet-content__item {
      // ant input
      .ant-input {
        border-radius: 5px;
        background: #f5f5f7;
        border: none;
      }
    }
    .new-announcemenet-merged-items {
      // background-color: red;
      display: flex;
      justify-content: space-between;
      .new-announcemenet-content__item {
        flex: 1;
        // antd select style
        .ant-select-selector {
          border-radius: 5px;
          background: #f5f5f7;
          border: none;
        }
        .ant-input {
          border-radius: 5px;
          background: #f5f5f7;
        }
      }
      .new-announcemenet-content__item2 {
        flex: 1;
        .ant-input {
          border-radius: 5px;
          background: #f5f5f7;
          border: none;
        }
      }
    }
  }
  .new-announcemenet-footer {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }
}
