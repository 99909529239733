@import "../../../../../assets/globalVariables.scss";

.vactions-container {
  .employees-list {
    padding: 0px 20px;
    .list-title {
      color: #1d3445;
      text-align: center;
      padding: 12px 57px;
      font: normal normal 600 16px/22px "Open Sans", sans-serif;
      background: #f1f7fb 0% 0% no-repeat padding-box;
      border-radius: 5px;
      width: 100%;
      //    margin: auto;
    }

    .emp-days {
      font: normal normal 600 16px/12px "Open Sans", sans-serif;
      color: #1d3445;
      margin: 10px 0px;
    }
    .employee-badger.ant-badge {
      margin-top: 10px;
      .ant-badge-status-dot {
        height: 12px;
        width: 12px;
      }
      .ant-badge-status-text {
        font: normal normal normal 16px/22px "Open Sans", sans-serif;
      }
    }

    .remaining-days {
      float: right;
      margin-top: 9px;
      font: normal normal normal 16px/22px "Open Sans", sans-serif;
    }
    .emp-days-container {
      overflow-y: scroll;
      height: 340px;
      white-space: nowrap;
    }
    .ant-col.ant-col-12.remain-text {
      text-align: right;
    }
  }

  //KOLONA 2: DATA, ORA DHE EMRAT E DEP.
  .departments-container {
    // margin: 0px;
    height: 400px;
    width: 98%;
    padding: 10px 20px;
    background: #f1f7fb 0% 0% no-repeat padding-box;
    border-radius: 10px;
    .time {
      font: normal normal 600 28px/38px "Open Sans", sans-serif;
      color: #323338;
    }
    .day-date {
      font: normal normal 300 18px/24px "Open Sans", sans-serif;
      color: #323338;
      margin-bottom: 26px;
    }
    .department {
      font: normal normal 600 14px/19px "Open Sans", sans-serif;
      padding: 3px 10px;
      color: #1264a3;
      margin-bottom: 20px;
      // padding: 0px 4px;
      &:hover {
        color: #013b69;
        background-color: #cad7e2;
        border-radius: 2px;
        // padding: 0px 4px;
        // font: normal normal 600 16px/19px "Open Sans", sans-serif;
        cursor: pointer;
      }
    }
    .chosen-department {
      font: normal normal 600 14px/19px "Open Sans", sans-serif;
      color: #323338;
      margin-bottom: 20px;
    }
    .no-data-dep {
      display: flex;
      justify-content: center;
      margin-top: 60px;
      font: normal normal bold 26px/55px "Open Sans";
      letter-spacing: 0px;
      color: #013b69;
    }
  }
  &.dark {
    color: $white;
    .employees-list {
      .emp-days {
        color: $white;
      }
      .list-title {
        color: $white;
        background: $d-black2;
      }
    }
    .departments-container {
      background: $d-black2;
      .time,
      .day-date,
      .chosen-department {
        color: $white;
      }
    }
  }
}
