@import "../../../assets/globalVariables.scss";

.listaEkandidateve {
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: 1fr 3fr;
  grid-template-rows: auto;
  gap: 10px;
  .teGjithKandidatet {
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 12px;
    width: 100%;
    // height: 750px;
    border-radius: 10px;
    background: #ffffff;
    padding: 20px 10px;
    .kandidatet {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 10px;
      width: 100%;
      animation: kandidatet 0.5s;
    }
  }
  .kandidatiContent {
    width: 100%;
    // width: 1170px;
    border-radius: 10px;
    background-color: #ffffff;
    .kandidatiSelektuarContent {
      display: flex;
      flex-direction: column;
      height: 100%;
      .kandidatiTabs {
        height: 100%;
      }

      .statusColor {
        width: 100%;
        max-height: 10px;
        min-height: 10px;
        border-radius: 10px 10px 0px 0px;
      }
      .kandidatiView {
        display: flex;
        justify-content: space-between;
        padding: 12px;
        .kandidatiDetails {
          display: flex;
          flex-direction: column;
          gap: 20px;
          width: 100%;
          font-size: 14px;
          .nameAndStatus {
            display: flex;
            justify-content: space-between;
            align-items: center;
            gap: 10px;
            .nameAndStatusItem {
              display: flex;
              align-items: center;
              gap: 10px;
              width: 100%;

              .fshiniKandidatinBtn {
                border-radius: 5px;
              }
              .skualifkoBtn {
                width: 106px;
                height: 32px;
                border: 1px solid #ea3943;
                color: #ea3943;
                border-radius: 5px;
              }
              .pranoBtn {
                width: 106px;
                height: 32px;
                border: 1px solid #20ac9d;
                color: #20ac9d;
                border-radius: 5px;
              }
              .name {
                font-weight: 600;
                font-size: 25px;
              }
              .statusContent {
                display: flex;
                justify-content: center;
                align-items: center;
                width: 155px;
                height: 25px;
                border-radius: 5px;
                color: #fff;
              }
            }
          }
          .details {
            display: flex;
            .detailsRow {
              display: flex;
              flex-direction: column;
              justify-content: flex-start;
              gap: 10px;
              width: 100%;
              span {
                .detailsTitle {
                  font-weight: 600;
                  margin-right: 5px;
                }
              }
            }
          }
          .pozicioniEdheData {
            display: flex;
            flex-direction: column;
            .centerContent {
              display: flex;
              align-items: center;
              gap: 5px;
              :nth-child(1) {
                font-weight: 600;
              }
              span {
                display: flex;
                gap: 5px;
                align-items: center;
              }
            }
          }
        }
      }
      .kandidadiTabs {
        padding: 12px;
        height: 100%;
        .ant-tabs-nav {
          box-shadow: none;
        }
      }
    }
  }
  &.dark {
    color: $white;
    .teGjithKandidatet {
      box-shadow: 0px 0px 5px #75eade;
      background: $d-black1;
      .kandidatet {
        .applicant {
          background: $d-black2;
          // border: 1px solid $grey-3;
          &.selected {
            border: 1px solid $grey-3;
          }
        }
      }
    }
    .kandidatiContent {
      box-shadow: 0px 0px 5px #75eade;
      background: $d-black1;
    }
  }
}
.noKandidads {
  display: flex;
  justify-content: center;
  align-items: center;
  // width: 1630px;
  // height: 799px;
  height: 100%;
  border-radius: 10px;
  background-color: #ffffff;
  .noData {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;
    .addKandidat {
      width: 150px;
      height: 32px;
      border-radius: 5px;
      background-color: #20ac9d;
      border: none;
      color: #fff;
      .txtKandidat {
        width: 350px;
        height: 32px;
      }
    }
  }
}

@media (max-width: 850px) {
  .listaEkandidateve {
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    .teGjithKandidatet {
      .kandidatet {
        height: max-content !important;
        max-height: 250px;
        overflow-y: auto;
      }
    }
    .kandidatiView {
      .nameAndStatus {
        flex-direction: column;
        .nameAndStatusItem:nth-child(1) {
          justify-content: space-between;
        }
        .nameAndStatusItem:nth-child(2) {
          justify-content: flex-end;
        }
      }
      .details {
        flex-direction: column;
        gap: 10px;
      }
    }
  }
}

@keyframes kandidatet {
  0% {
    opacity: 0;
    transform: translateY(10px);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}
@keyframes applicant {
  from {
    // font-size: 14px;
    width: 95%;
  }
  to {
    width: 100%;
    // font-size: 18px;
  }
}
@keyframes applicantReverse {
  from {
    width: 100%;
    // font-size: 18px;
  }
  to {
    width: 95%;
    // font-size: 14px;
  }
}
