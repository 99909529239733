@import "../../../../index.scss";

.documentationStatusesModalContent {
  //* styles documentStatus justify-content:flex-start, align-items:center
  .documentStatus {
    @include flex(flex-start, center);
  }
  .labeledInputContainer {
    margin-right: 30px;
    .labeledInputLabel {
      margin: 0;
    }
  }

  .ant-input-affix-wrapper {
    border-radius: 5px;
    border: none;
    background-color: #f5f5f7;
  }

//* styles colorPalette justify-content:center, align-items:center
  .colorPalette {
    @include flex(center, center);
  }

//* styles colorSquare width:30px, height:30px, border-radius: 5px, and justify-content:center, align-items:center 
  .colorSquare {
    @include square(30px, 30px, 5px);
    @include flex(center, center);
    font-size: 25px;
    margin-right: 20px;
    cursor: pointer;

    &:hover {
      filter: brightness(0.7);
    }
  }

  .statusColorPicker {
    margin-right: 20px;
  }
  .selectedColor {
    @extend .colorSquare;
    border: 1px solid #d9d9d9;
    width: fit-content;
    padding: 0 20px;
    font-size: 20px;
    color: #fff;
    cursor: unset;
  }

//* styles statusesLabel text font-size:20px, font-weight:600, letter-spacing:0, text color,and justify-content:center, align-items:center  
  .statusesLabel {
    background-color: #f7f8fa;
    border-radius: 5px;
    width: 220px;
    padding: 0 40px;
    margin-bottom: 20px;
    @include text(20px, 600, 0, #323338);
    @include flex(center, center);
  }

  .removeBtn {
    @extend .colorSquare;
    font-size: 14px;
    background-color: #fe4c4a;
    color: #fff;
  }

  .colorPickerContainer {
    transform: scale(1);
  }

//* styles actionBtnContainer justify-content:center, and align-items:space-betweeen
  .actionBtnContainer {
    @include flex(center, space-between);
  }

  .addStatusBtn {
    @include mondayButton(#71cf48, #68c142);
  }

//* styles decisiveButtons justify-content:center, align-items:center
  .decisiveButtons {
    @include flex(center, center);

    .cancelBtn {
      margin-right: 20px;
      @include mondayButton(#f5f5f7, #e6e9ef, #000);
    }
  }
  .newReportModalContent {
  //* styles inputs justify-content:flex-end, and align-items:flex-start
    .inputs {
      @include flex(flex-end, flex-start);
      .labeledInputContainer {
        margin-right: 20px;
        width: 250px;

        .ant-input,
        .ant-select-selector {
          border-radius: 5px;
          border: 1px solid #707070;
        }
      }
    }

    .datasetsContainer {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      column-gap: 40px;
      row-gap: 5px;
      width: max-content;
      margin-bottom: 20px;

      .labeledInputContainer {
        width: fit-content;
      }

      .configElementContainer {
        margin: 0;
      }
    }

//* styles dataSetLabel justify-content:center, align-items:flex-start,and text font-size:16px, font-weight:400, letter-spacing:0, color text 
    .dataSetLabel {
      @include flex(center, flex-start);
      @include text(16px, 400, 0, #323338);
    }
    .dataSetLabelTag {
      background-color: #797f8f;
      color: #fff;
      border-radius: 5px;
      margin-left: 20px;
    }
  }

  .cardLayoutModal {
    color: #323338;

    .ant-modal {
      width: unset !important;
    }

    .ant-modal-content {
      border-radius: 10px;
      width: fit-content;
      height: fit-content;
      margin: auto;
    }
  //* ant-modal-header justify-content:center, align-items:flex-start
    .ant-modal-header {
      @include flex(center, flex-start);
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
      background-color: #f8f8fa;
      padding: 0 20px;
      height: 45px;
    }
    .ant-modal-close-x {
      width: 45px;
      height: 45px;
      line-height: 45px;
    }

//* styles ant-modal-title text font-size:16px, font-weight:700, letter-spacing:0, and text color
    .ant-modal-title {
      @include text(16px, 700, 0, #323338);
    }

    .ant-modal-body {
      padding: 20px;
    }

//* styles footerContainer justify-content:center, and align-items:flex-end
    .footerContainer {
      @include flex(center, flex-end);
    }
  }
}

//* responsive DocStatusesModal for iPhone 12 Pro
@media (max-width: 391px) {
  .documentationStatusesModalContent .documentStatus {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: row;
  }
  .documentationStatusesModalContent .statusesLabel {
    width: 150px;
  }
  .documentationStatusesModalContent .labeledInputContainer {
    margin-right: -40px;
  }
  .documentationStatusesModalContent .ant-form .ant-form-item {
    width: 150px !important;
  }
  .documentationStatusesModalContent {
    overflow: auto;
  }
  .documentationStatusesModalContent .actionBtnContainer {
    gap: 5px;
    margin-bottom: 5px;
  }
  button.ant-btn.ant-btn-default.mondayButtonStyle.addStatusBtn {
    width: 135px;
  }
  button.ant-btn.ant-btn-default.mondayButtonStyle.cancelBtn {
    width: 135px;
  }
}