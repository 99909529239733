.infoCard {
  width: 100%;
  height: 100%;
  border-radius: 10px;
  opacity: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  .ant-card-head {
    width: 100%;
    height: 45px;
    color: #ffffff;
    background: #1d3445 0% 0% no-repeat padding-box;
    border-radius: 10px 10px 0px 0px;
    opacity: 1;
  }

  .inputInfo {
    width: 100%;
    padding: 0 30px;
    height: 100px;
    .ant-row.ant-form-item-row {
      display: grid;
      grid-template-columns: 1fr 3fr;
      grid-template-rows: 1fr;
      align-items: center;
    }
    .ant-form-item-control-input-content {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 10px;
    }
  }

  .inputInfo label {
    display: flex;
    justify-content: flex-end;
    font-weight: bolder;
  }
  .inputInfo Input {
    width: 100%;
  }
}
