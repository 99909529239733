@import "../../../../index.scss";

.docConfigCard {
  border-radius: 20px;
  .formCardTitle {
    color: #323338;
  }

//* styles infoTitle text font-size:16px, font-weight:normal, letter-spacing:0, and text color
  .infoTitle {
    @include text(16px, normal, 0, #323338);
  }

  .labelSubtitle {
    font-size: 16px;
    &:first-child {
      font-weight: 600;
    }

    &:nth-child(2) {
      color: #323338;
      font-weight: 600;
    }
  }

//* styles infoValue text font-size:16px, font-weight:normal, letter-spacing:0, and text color
  .infoValue {
    @include text(16px, normal, 0, #767b81);
  }
}

//* responsive DocCard for iPad Air
@media (max-width: 821px) {
  .formCard.docConfigCard {
    margin-top: 15px;
  }
}

//* responsive DocCard for monitor Pc
@media (max-width: 1025px) {
  .docConfigCard .labelSubtitle {
    font-size: 23px;
  }
}

//* responsive DocCard for iPhone 12 Pro
@media (max-width: 391px) {
  .docConfigCard .labelSubtitle {
    font-size: 16px;
  }
  .formCard.docConfigCard {
    width: 270px;
 }
}