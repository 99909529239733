@import "../../../../../index.scss";

//* styles statusSquircleContainer align-items:center, justify-content:center, flex-direction:column,and gives last element margin-right:0 
.statusSquircleContainer {
  @include flex(center, center, column);
  padding: 13px 10px;
  height: 104px;
  min-width: 118px;
  margin-right: 23px;
  border-radius: 10px;
  flex-grow: 1;
  flex-basis: 0px;

  &:last-child {
    margin-right: 0;
  }

  //* styles statusValue text font-size:56px, font-weight:600, letter-spacing:0 and text color
  .statusValue {
    @include text(56px, 600, 0, #fff);
    line-height: 1;
  }

  //* styles statsuName text font-size:17px, font-weight:400, letter-spacing:0, and text color
  .statusName {
    @include text(17px, 400, 0, #fff);
    max-width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    line-height: normal;
  }
}
.statusCardOverlay {
  .ant-tooltip-content {
    border-radius: 5px;
    overflow: hidden;
  }
}
