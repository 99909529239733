.additions-table {
	.add-table {
		table {
			font-family: arial, sans-serif;
			border-collapse: collapse;
			border: none;
			width: 100%;
			:first-child {
				background: none;
			}		
		}

		td,
		th {
			// border: 1px solid #dddddd;
			text-align: left;
			padding: 5px;
		}
		tr {
			background: rgba(14, 207, 0, 0.1);
		}

		tr:nth-child(even) {
			background-color: rgba(0, 161, 21, 0.17);
		}
	}

	.payStub-final-table {
		table {
			font-family: arial, sans-serif;
			border-collapse: collapse;
			width: 100%;
			border-radius: 10px;
			border: 2px solid #323338;
		}

		td,
		th {
			// border: 1px solid #dddddd;
			text-align: center;
			padding: 5px;
		}
		th {
			background-color: #323338;
			color: white;
		}
		tr {
			background-color: #70707017;
		}

		tr:nth-child(even) {
			background-color: #7070703d;
		}
	}
	.payStub-print {
		box-shadow: 2px 0px 11px 0px rgba(0, 0, 0, 0.53);
		border-radius: 10px;
	}
}
