@import "../../../../../assets/globalVariables.scss";

.politikatFestaveZyrtare {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  .ant-card-head {
    background: #1d3445 0% 0% no-repeat padding-box;
    color: #ffffff;
    font: normal normal 600 16px/22px Open Sans;
    .ant-card-extra {
      display: flex;
      cursor: pointer;
    }
  }
  .cardContent {
    display: flex;
    flex-direction: column;
    height: 100%;
    overflow: hidden;
    .tableHeaders {
      display: grid;
      h3 {
        text-align: center;
      }
    }
    .tableRows {
      display: flex;
      flex-direction: column;
      gap: 10px;
      overflow-y: auto;
      .rowWrapper {
        display: grid;
        grid-template-columns: 1fr 1fr 4fr 2fr 1fr;
        justify-items: center;
        align-items: center;
        gap: 10px;
        color: #323338;
        * {
          text-align: center;
          align-content: center;
        }
        .data {
          font-weight: 600;
        }
        .rowTitle {
          display: flex;
          align-items: center;
          gap: 10px;
          width: 100%;
          span {
            white-space: nowrap;
            font-weight: 600;
          }
          .ant-divider {
            margin: 0;
          }
        }
      }
      .dayOfWeek {
        display: flex;
        align-items: center;
        gap: 10px;
        width: 105px;
        height: max-content;
        font-size: 14px;
        color: #ffffff;
        .dayOfWeekIcon {
          display: flex;
          align-items: center;
          padding: 0 5px;
        }
        span {
          display: flex;
          justify-content: center;
        }
      }
    }
  }
  &.dark {
    .cardContent .tableRows .rowWrapper {
      color: $white;
      .ant-divider {
        border-block-start-color: $grey-3;
      }
    }
  }
}
@media (max-width: 450px) {
  .politikatFestaveZyrtare {
    .festaCard {
      width: 100%;
      height: 100%;
      overflow: hidden;
      display: flex;
      flex-direction: column;
      .ant-card-body {
        display: flex;
        height: 100%;
        overflow: hidden;
        padding: 12px;
      }
      .cardContent {
        width: 100%;
        .tableRows {
          padding: 5px;
          .mobileRowWrapper {
            display: grid;
            grid-template-columns: 1fr 1fr;
            padding: 10px;
            gap: 5px;
            box-shadow: 0px 1px 4px #00000029;
            border-radius: 5px;
            width: 100%;
            .ant-picker {
              height: fit-content;
            }
          }
        }
      }
    }
  }
}
