@import "../../../assets/globalVariables.scss";

.moreInformationCard {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  width: 100%;
  height: 100%;
  color: #323338;
  .breakContent {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    flex-direction: column;
  }

  .wrapperIcon {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 91px;
    height: 91px;
    background: #f5f5f7 0% 0% no-repeat padding-box;
    opacity: 1;
    border-radius: 50%;
  }

  .breakText {
    width: 126px;
    height: 22px;
    text-align: center;
    font: normal normal 600 16px/19px Open Sans;
    letter-spacing: 0px;
    opacity: 1;
  }

  .oret {
    // autumn theme
    color: #1da193;
    font-size: 17px;
    font-weight: 600;
  }

  .text {
    color: #ea3943;
    font-weight: 500;
    font-size: 15px;
  }
  &.dark {
    color: $white;
    .wrapperIcon {
      background: none;
    }
    .text {
      color: #f0747b;
    }
  }
}
