.emailTemplateContainer {
  height: 100%;
  width: calc(100%);
  padding-top: 10px;
  padding-bottom: 0px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  .fristRowContainer {
    padding-left: 10px;
    padding-right: 10px;
    width: 100%;
    height: 6%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding-bottom: 5px;
  }

  .ag-theme-alpine {
    .ag-cell-label-container {
      .ag-icon {
        color: white;
      }
    }
    .ag-header-viewport .ag-header-container {
      background-color: #1d3445;
      .ag-header-row {
        color: white;
        font-weight: 600;
      }
    }
  }
  .agContainer {
    padding-left: 10px;
    padding-right: 10px;
    height: 87%;
    width: 100%;
    border-radius: 5px !important;
    .ag-root-wrapper {
      border-radius: 10px !important;
    }
  }
  .konfigurimi-footer {
    height: 50px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #fff;
    transition: 0.2s ease-in;
    padding: 10px 20px;
  }
}
