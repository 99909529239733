.employeeWageView {
  display: grid;
  grid-template-columns: 1fr 3fr;
  gap: 20px;
  width: 100%;
  height: 100%;
  overflow-y: auto;
  padding: 5px;
  .employeeWageCards {
    display: flex;
    flex-direction: column;
    gap: 20px;
    width: 100%;
    height: 100%;
    min-height: 650px;
  }
  .wageViewGrid{
    height: 100%;
  }
}

@media screen and (min-width: 451px) and (max-width: 1024px) {
  .employeeWageView {
    display: flex;
    flex-direction: column;
    .employeeWageCards {
      flex-direction: row;
      min-height: unset;
      height: unset;
    }
  }
}
@media (max-width: 450px) {
  .employeeWageView {
    display: flex;
    flex-direction: column;
  }
}

//maybe should delete
.generate-modal {
  .input-form {
    width: 345px;
    height: 32px;
    background: #f5f5f7 0% 0% no-repeat padding-box;
    border-radius: 5px;
    opacity: 1;
  }
  .ant-modal-content {
    border-radius: 10px 10px 10px 10px;
  }

  .ant-modal-body {
    background-color: white;
    // height: 800px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    // margin-left: 20px;
  }
}
