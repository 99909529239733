.leaveStatus {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 155px;
  height: 25px;
  border-radius: 5px !important;
  text-align: center;
  font-family: sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 19px;
  letter-spacing: 0em;
  border: none !important;
}
