.ant-modal.reasonModal {
  width: max-content !important;
  height: auto;
  .ant-modal-content {
    border-radius: 10px !important;
  }
}
.reasonModalContainer {
  display: flex;
}
.reasonLabel {
  font-size: 16px;
  font-weight: 600;
}
.reasonModalComponents {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  gap: 24px;
  .terminationLabel {
    color: #1d3445;
    font-family: Open Sans;
    font-size: 15px;
    font-weight: 600;
    letter-spacing: 0.23px;
    opacity: 1;
    z-index: 10;
    margin: 0;
  }
}
.ant-select.ant-select-in-form-item.inputReason.ant-select-single.ant-select-show-arrow {
  height: 32px;
}
.ant-picker.reasonDate {
  width: 200px;
  height: 32px;
  border-radius: 5px;
}

//* responsive for iPhone 12 Pro
@media (max-width: 391px) {
  .reasonLabel {
    font-size: 14px;
  }
}
