$card_blue: #1d3445;
$input_background: #f5f5f7;
$checkbox_color: #1da193;

@mixin icon-buttons($bgcolor) {
  width: 32px;
  height: 32px;
  background: $bgcolor !important;
  border: none;
  border-radius: 5px;
  // margin-right: 30px;
}

.status-container {
  padding: 5px;
  display: block;
  margin: 0 auto;
  margin-top: 20px;

  path#Path_7623 {
    fill: #9a9a9a;
  }
  .employee-status-card {
    .ant-card-head {
      background-color: $card_blue;
      border-radius: 10px 10px 0 0;
      color: white;
      font-family: "Open Sans", sans-serif;
      font-weight: 600;
      font-size: 18px;
    }
  }

  .add-status-button.ant-btn {
    @include icon-buttons(#f5f5f7);
  }
  .close-status-button.ant-btn {
    @include icon-buttons(#fe4c4a);
    margin-right: 30px;
  }

  .hex-code {
    width: 100px;
    height: 25px;
    margin: 10px 5px 20px 20px;
    padding-left: 5px;
    border: none;
    border-radius: 2px;
  }
  .hex-code-button {
    border: none;
    height: 25px;
    border-radius: 4px;
  }
  .holder {
    margin-top: 22px;
  }
  .status-name {
    background-color: #323232;
    color: #fff;
    text-align: center;
    padding: 0px;
    width: 244px;
    height: 32px;
    border-radius: 5px;
    font: normal normal normal 16px/28px "Open Sans", sans-serif;
  }
  .down-button.ant-btn {
    @include icon-buttons(#f5f5f7);
    margin: 0px 0px;
  }
  .up-button.ant-btn {
    @include icon-buttons(#f5f5f7);
    margin: 0px 7px 0px;
  }
  .bin-button.ant-btn {
    @include icon-buttons(#fe4c4a);
    margin: 0px 5px 0px;
  }
  .name-btn-div {
    margin: 0px;
    height: 40px;
  }
  .ant-divider-vertical {
    height: 400px;
    margin-top: 45px;
  }
}

//Color picker (PhotoshopPicker)

.flexbox-fix:nth-child(1) {
  display: none !important;
  width: 0px !important;
}
.flexbox-fix div:nth-child(1) {
  border-style: none !important;
  border-width: 2px !important;
  border-style: hidden !important;
  border-color: #fff !important;
  border-radius: 5px !important;
}
.flexbox-fix div:nth-child(2) {
  border-style: none !important;
  border-width: 2px !important;
  border-style: hidden !important;
  border-color: #fff !important;
  border-radius: 5px !important;
}
.flexbox-fix div:nth-child(3) {
  width: 0px !important;
}
.flexbox-fix:nth-child(2) {
  // width: 550px !important;
  border-style: none !important;
  border-width: 2px !important;
  border-style: hidden !important;
  border-color: #fff !important;
  border-image: none !important;
  div:nth-child(1) {
    border: none !important;
  }
}

.hue-vertical {
  width: 32px;
}
.photoshop-picker > div:nth-of-type(1) {
  background-image: none !important;
  border-bottom: none !important;
  box-shadow: none !important;
  font: normal normal normal 14px/19px "Open Sans", sans-serif !important;
}
