.racket-component {
  display: table;
  margin: 0px auto;
  height: 130px;
  width: 130px;

  .middle {
    display: table-cell;
    vertical-align: middle;
    .racket-circle-outline {
      margin: auto;
      border: solid 3px rgb(24, 170, 115);
      border-radius: 50%;
      width: 130px;
      height: 130px;
      display: grid;
      place-items: center;
      .racket-success {
        justify-content: center;
        animation: racketAnimation 0.6s linear infinite;
      }
      .tick-success {
        justify-content: center;
        animation: tickAnimation 0.5s linear;
      }
    }
  }
}

@keyframes racketAnimation {
  from {
    transform: rotate(0deg);
    opacity: 1;
  }
  to {
    transform: rotate(359deg);
    opacity: 0;
  }
}

@keyframes tickAnimation {
  from {
    transform: rotate(0deg);
    opacity: 0.2;
  }
  to {
    transform: rotate(359deg);
    opacity: 1;
  }
}
