@import "../../../../index.scss";

.docConfigTemplateModal {
  $background: #f5f5f7;

  .closeIcon {
    position: absolute;
    top: 0;
    right: 0;
    padding: 15px;
    color: #323338;

    &:hover {
      color: #232425;
    }
  }

  .templateName {
    color: #323338;
    .ant-input {
      border: 0 !important;
      border-radius: 5px !important;
      width: 240px;
    }

    //* styles labeledInputLabel text font-size:16px, font-weight:600, letter-spacing:0, and text color
    .labeledInputLabel {
      @include text(16px, 600, 0, inherit);
      margin-right: 15px;
    }

    //* styles labeledInputContainer justify-content:baseline, and align-items:center
    .labeledInputContainer {
      @include flex(baseline, flex-end);
    }
  }

  //* styles modalBodyContainer justify-content:center, align-items:center, and flex-direction:column
  .modalBodyContainer {
    @include flex(center, center, column);
  }

  //* styles templateActionButtons justify-content:center, and align-items:center
  .templateActionButtons {
    @include flex(center, center);
  }

  .ant-input-affix-wrapper,
  .ant-select-selector,
  .ant-picker,
  .ant-input {
    border: 0;
    border-radius: 5px;
    background-color: $background;
  }
  &.dark {
    .templateName {
      color: $white;
    }
    .documentationsCheckboxesContainer .checkboxesContainerTitle {
      color: $white;
    }
    .transferLabelContainer {
      background: $d-black5;
      color: $white;
    }
  }
}

//* responsive NewTemplateModal for Pc monitor
@media (max-width: 1025px) {
  .docConfigTemplateModal .templateName .ant-input {
    font-size: 17px;
  }
}

//* responsive NewTemplateModal for iPhone 12 Pro
@media (max-width: 391px) {
  .docConfigTemplateModal .templateName .ant-input {
    font-size: 15px;
  }
  .docConfigTemplateModal .templateName .labeledInputContainer {
    display: flex;
    flex-direction: column;
  }
}
