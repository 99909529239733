@import "../../../index.scss";

.uploadedFilesModal {
  //* styles fileName text font-size:17px, font-weight:normal, letter-spacing:0, and text color
  .fileName {
    @include text(17px, normal, 0, #0984ff);
    cursor: pointer;
  }
//* styles infoValue text font-size:17px, font-weight:normal, letter-spacing:0, and text color
  .infoValue {
    @include text(17px, normal, 0, #767b81);
  }

  .filesContainer {
    display: grid;
    grid-template-columns: auto auto;
    column-gap: 30px;
    padding: 20px;
  }
}
