.settingsFooter {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 52px;
  padding: 10px;
  border-radius: 10px;
  background-color: #fff;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.1607843137);
  animation: showFooter 1s ease-in-out;
  &.dark {
    background: none;
    box-shadow: 0 0 5px #75eade;
  }
}

@keyframes showFooter {
  from {
    opacity: 0;
    transform: translate3d(0, 50%, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}
