@import "../../../../../assets/globalVariables.scss";

.hidden-cards-drawer {
  .ant-list-header {
    border: none;
    span {
      display: flex;
      align-items: center;
      gap: 10px;
    }
  }
  .card-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    background-color: #f1f1f5;
    padding: 5px 10px;
    font-family: Open Sans;
    font-size: 14px;
    font-weight: 600;
    line-height: 19.07px;
    border-radius: 5px;
    .card-icon {
      background: #2e5d9e;
      height: 32px;
      width: 32px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 5px;
      cursor: pointer;
    }
  }
  &.dark {
    .ant-list {
      border: none;
      color: $white;
      background: $d-black2;
      .ant-list-item {
        color: $white;
        .card-item {
          background: $d-black3;
          .card-icon {
            background: $d-black1;
          }
        }
      }
    }
  }
}
