@import "../../../index.scss";

//* styles googlePickerButtonContainer justify-content:flex-start, and align-items:flex-start
.googlePickerButtonContainer {
  @include flex(flex-start, flex-start);

//* styles uploadButton text font-size:14px, font-weight:400, letter-spacing:00.21px, and text color
  .uploadButton {
    font-family: Helvetica;
    @include text(14, 400, 00.21px, #7b7b7b);
  }

//* styles uploadedFileNamesContainer justify-content:flex-start, align-items:flex-start, and flex-direction:column,and text font-size:11px, font-weight:400, letter-spacing:0, and text color 
  .uploadedFileNamesContainer {
    @include flex(flex-start, flex-start, column);
    @include text(11px, 400, 0, #d69615);
    margin-left: 10px;
  }

//* styles fileNameContainer justify-content:center, and align-items:flex-start
  .fileNameContainer {
    @include flex(center, flex-start);
  }
  .deleteIcon {
    margin-right: 5px;
    color: grey;
  }
  .fileName {
    cursor: pointer;
    display: block;
    max-width: 110px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}
