@import "../../assets/globalVariables.scss";

.ant-layout-header {
  height: 70px !important;
  background: #fbfbfb;
  //comment it on winter ^^^
  justify-content: center;
  align-items: center;
  display: flex;
  width: 100%;
  padding: 0px !important;
  box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.1) !important;
  -webkit-box-shadow: 0 3px 5px rgba(57, 63, 72, 0.3);
  // -moz-box-shadow: 0 3px 5px rgba(57, 63, 72, 0.3);
  // box-shadow: 0 2px 3px rgba(76, 79, 83, 0.3);

  //winter
  // background: radial-gradient(ellipse at bottom, #1b2735 0%, #1d3445 100%);
  // color: #1da193;
}

.time-weather-div {
  display: flex;
  font-size: 16px;
  font-weight: 500;
}
.fahrenheit-span {
  margin-left: 25px;
  display: flex;
  cursor: pointer;
}

.header-app {
  align-items: center;
  // height: fit-content;
  height: 100%;
  width: 100%;
  .editQOTD {
    width: 100%;
    display: flex;
    font-size: 14px;
    font-family: "Open Sans";
    font-weight: 400;
    font: normal normal normal 16px/22px Open Sans;
    line-height: 19px;
    height: 100%;
    font-style: italic;
    align-items: center;
    //winter
    // color: #1da193;
    color: #1d3943;
  }
  .user-info {
    font-size: larger;
    font-weight: 600;
    position: absolute;
  }
  .logo-header {
    margin-right: 5%;
    cursor: pointer;
    position: absolute;
  }
  .icons svg {
    // background: #B31312;
    // Autumn Theme
    background: #1d3445;
    //winter
    // border: 1px solid #1da193;
    width: 32px;
    height: 32px;
    border-radius: 5px;
    color: #fbfbfb;
    padding: 6px;
    // margin-right: 20px;
    cursor: pointer;
  }
  .icons {
    display: flex;
    align-items: center;
    gap: 10px;
    height: 39px;
    margin-right: 30px;
    line-height: 0;
    .theme-toggle {
      padding: 0;
    }
  }
  .icons-div {
    display: flex;
    align-items: center;
    gap: 5px;
    margin-right: 30px;
  }
  .user-title h1 {
    font-weight: 600;
    font-size: medium;
  }
  .search-effect {
    margin-right: 20px;
    margin-top: 3px;
  }
  .ant-layout-header {
    height: 119px !important;
    // background: #fff !important;
    justify-content: center;
    align-items: center;
    display: flex;
    width: 100%;
    padding: 0px;
  }
  path#Path_7415_00000134212190235252654350000000058067522740290455_ {
    fill: #fbfbfb;
  }
  button.ant-btn.ktheu-btn {
    background: #0f0f0f;
    color: #fbfbfb;
    border-radius: 5px;
  }
  &.dark {
    color: $white;
    .editQOTD {
      color: #bfbfbf;
    }
    .icons svg {
      background: #434a48;
    }
  }
}

.ant-popover.ant-popconfirm.popup-logout-modal.ant-popover-placement-left {
  // width: 542px !important;
  height: 70px;
  .ant-popover-inner-content {
    width: 100%;
    height: 70px !important;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0px;
    .ant-popconfirm-inner-content {
      display: flex;
      gap: 15px;
      width: 100%;
      padding: 0 15px;
      .ant-popconfirm-message {
        padding: 0;
      }
      .ant-popconfirm-buttons {
        display: flex;
        gap: 5px;
        .ant-btn {
          height: 28px;
          width: 60px;
        }
      }
    }
  }
  .ant-popover-inner {
    border-radius: 10px !important;
  }
  .ant-popover-message {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .ant-popover-message-title {
    margin-right: 13px;
    font-weight: 500;
  }
  .ant-popover-buttons {
    height: 70px;
    margin: 0px;
    padding-left: 8.2px;
    button.ant-btn.ant-btn-sm {
      width: 50px;
      height: 70px;
      background: #ff4e00;
      border: none;
      color: #fbfbfb;
      border-radius: 0px;
      cursor: pointer;
    }
    button.ant-btn.ant-btn-primary.ant-btn-sm {
      width: 50px;
      height: 70px;
      border: none;
      background: #1da193;
      margin: 0px;
      color: #fbfbfb;
      border-radius: 0px 8px 8px 0px;
      cursor: pointer;
    }
  }
}
.icons-kryefaqja {
  display: flex;
  justify-content: center;
  align-items: center;
  line-height: normal;
  svg {
    display: flex;
    justify-content: center;
    align-items: center;
    background: $white;
    width: 32px;
    height: 32px;
    border-radius: 5px;
    fill: $d-black1;
    padding: 6px;
    cursor: pointer;
  }
}

.timeClock {
  margin-bottom: 2px;
}

.user-title h1 {
  color: #fbfbfb;
}

@keyframes iconHoverScale {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@media (max-width: 1400px) {
  .dashboard .dashboard-first-row {
    grid-template-columns: auto auto;
    gap: 20px;
    display: grid;
    padding-right: 20px;
  }
  .col-header-name.qotd {
    display: none !important;
  }
}
@media (max-width: 830px) {
  .ant-drawer.ant-drawer-right.ant-drawer-open.notification-drawer {
    width: 650px !important;
    .ant-drawer-content-wrapper {
      width: 650px !important;
    }
    .single-notif .notif-actions {
      min-width: 71px;
      display: contents;
    }
  }
  .time-sheet-header-comp {
    flex-wrap: wrap;
    gap: 10px;
  }
  // .progress-shift-bar {
  // 	width: 350px !important;
  // }
  .ant-row.small-cards-view {
    margin-top: 10px;
  }
  div#supervisor {
    margin-left: 20px;
  }
  .timesheet-wrapper .timesheet-calculations .timesheet-progress-wrapper {
    max-height: 900px !important;
  }
  .ant-row.harder-row {
    flex-wrap: wrap !important;
    flex-flow: column !important;
    .ant-col {
      flex-wrap: wrap !important;
      gap: 10px !important;
    }
    button.ant-btn.perdorues-te-ri-btn {
      margin-top: -35px !important;
    }
  }
}

@media (max-width: 821px) {
  .ant-row.header-row-konfigurimet {
    .ant-col.ant-col-3 {
      display: none !important;
    }
    .ant-row.header-row {
      padding-left: 0px !important;
      .times-div {
        width: 482px !important;
        gap: 10px !important;
      }
      .header-app .icons {
        margin-right: 10px !important;
      }
    }
  }
}
@media (max-width: 769px) {
  .header-app .icons {
    margin-right: 10px !important;
    gap: 10px;
  }
  .ant-row.header-row {
    padding-left: 0px !important;
    .times-div {
      width: 460px !important;
      gap: 10px !important;
    }
    .time-weather-div {
      font-size: 14px !important;
    }
  }
}
@media (max-width: 600px) {
  .header-app {
    display: flex !important;
    justify-content: space-between;
    gap: 5px;
    .icons svg {
      border: 1px solid #fbfbfb;
    }
  }
  .header-for-mobile {
    width: 100%;
    height: 100%;
  }
  .header-dashbord-mobile {
    width: 100%;
    justify-content: flex-end;
    margin-right: 10px;
    display: flex;
  }
  .user-title {
    text-align: center;
    h1 {
      font-size: 12px !important;
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      text-align: center;
      margin: 0 !important;
      // width: 190px;
      font: normal normal 500 11px/15px "Open Sans", sans-serif;
    }
  }
}

@media (max-width: 391px) {
  .ant-popover.ant-popconfirm.popup-logout-modal.ant-popover-placement-left {
    width: 320px !important;
    left: 0px !important;
  }
  .ant-popover.ant-popconfirm.popup-logout-modal.ant-popover-placement-left
    .ant-popover-message-title {
    font-size: small;
    margin-right: 0px !important;
  }
  .ant-popover.ant-popconfirm.popup-logout-modal.ant-popover-placement-left
    .ant-popover-inner-content {
    padding-left: 0px !important;
  }
  .ant-row.header-row {
    width: 310px !important;
    .ant-col.ant-col-16.col-header-name {
      margin-left: 20px;
      .user-info {
        width: fit-content;
        margin-left: -10px;
        font-size: 10px !important;
      }
    }
    .ant-col.ant-col-5 {
      justify-content: flex-end !important;
    }
  }

  .header-dashbord-mobile {
    width: 100% !important;
  }

  .ant-row.header-row-konfigurimet {
    padding-left: 0px !important;
    .ant-col.ant-col-3 {
      display: none;
    }
  }
  .times-div {
    display: none !important;
  }
  // .icons-div{
  // 	margin-right: -35px !important;
  // }
  // .ant-layout-header .header-app {
  //   display: grid;
  //   grid-template-columns: 310px !important;
  //   width: 310px !important;
  //   .ant-row.header-row {
  //     width: 310px !important;
  //   }
  // }
  // .header-app .ant-col.ant-col-16.konfig-header .user-title {
  //   padding-left: 34px !important;
  //   // width: 272px !important;
  // }
}

.background-img-header {
  width: 32px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  //  border: 1px solid #B31312;
  border-radius: 5px;
}

.flex-wish-text {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 25px;
  line-height: 34px;
  // background-image: url("https://plus.unsplash.com/premium_photo-1669799891975-8f668765be09?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1471&q=80");
  // object-fit: scale-down;
}

//winter
@function random_range($min, $max) {
  $rand: random();
  $random_range: $min + floor($rand * (($max - $min) + 1));
  @return $random_range;
}

.ant-layout-header {
  .snowflake {
    $total: 50;
    position: absolute;
    width: 20px;
    height: 20px;

    @for $i from 1 through $total {
      $random-x: random(1000000) * 0.0001vw;
      $random-offset: random_range(-100000, 100000) * 0.0001vw;
      $random-x-end: $random-x + $random-offset;
      $random-x-end-yoyo: $random-x + ($random-offset / 2);
      $random-yoyo-time: random_range(30000, 80000) / 100000;
      $random-yoyo-y: $random-yoyo-time * 70px;
      $random-scale: random(10000) * 0.0001;
      $fall-duration: random_range(60, 80) * 1s;
      $fall-delay: random(30) * -1s;

      &:nth-child(#{$i}) {
        opacity: random(10000) * 0.0001;
        transform: translate($random-x, -10px) scale($random-scale);
        animation: fall-#{$i} $fall-duration $fall-delay linear infinite;
      }

      @keyframes fall-#{$i} {
        #{percentage($random-yoyo-time)} {
          transform: translate($random-x-end, $random-yoyo-y)
            scale($random-scale);
        }

        to {
          transform: translate($random-x-end-yoyo, 70px) scale($random-scale);
        }
      }
    }
  }
}
