.konfigurimet {
  display: flex;
  flex-direction: column;
  gap: 10px;
  height: 100%;
  overflow: hidden;
  // width: 1500px;
  .globalPageWrapper:has(.departmentRolesContainer) {
    padding: 5px;
  }
}

@media (max-width: 1100px) {
  .header-app {
    display: grid;
  }
}
