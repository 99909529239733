@import "../../../../../index.scss";
@import "../../../../../assets/globalVariables.scss";

.permissionModal {
  .ant-modal-header {
    border-radius: 10px 10px 0 0;
  }
  .ant-modal-body {
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 20px;
    .permissionsList {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: left;
      max-height: 250px;
      overflow: auto;
      .permissionsHeader {
        display: flex;
        justify-content: space-around;
        font-size: 16px;
        font-weight: 600;
        width: calc(100% - 50px);
      }
      .permission {
        position: relative;
        display: flex;
        justify-content: space-around;
        width: 100%;
        .content {
          width: 100%;
          position: relative;
          display: flex;
          flex-direction: row;
          gap: 2px;
          span {
            width: 100%;
            text-align: center;
            padding: 10px 0;
            background-color: #f7f8fa;
          }
        }
        .divider {
          margin: 2px 0 !important;
          width: 100%;
          position: absolute;
          bottom: 0;
        }
      }
      .deletePermissionIcon {
        background-color: #fe4c4a;
        width: 45px;
        margin: 2px 0 2px 5px;
        border-radius: 5px;
        cursor: pointer;
        @include flex(center, center);
        &:hover {
          background-color: #eb4345;
        }
      }
    }
    .mondayButtonContainer {
      display: flex;
      justify-content: flex-end;
    }
  }
  &.dark {
    .ant-modal-body .permissionsList .permission .content span {
      background: $d-black5;
    }
  }
}
