@import "../../../../../assets/globalVariables.scss";

.request-modal {
  .modal-status-text {
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 1px 1px;
    margin: 5px 10px;
    border-radius: 5px;
    color: #fff;
  }
  .label-text {
    text-align: left;
    font: normal normal 600 16px/22px "Open Sans";
    color: #323338;
  }
  .label-quill-div {
    h3 {
      margin-top: 20px;
      text-align: start;
      margin-left: 90px;
    }
  }
  .quill-div {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
  }
  .ql-editor {
    min-height: 100px;
  }
  &.dark {
    .label-text {
      color: $white;
    }
  }
}
