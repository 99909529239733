.ant-modal-footer {
  .print-btn {
    border: none;
    width: 150px;
    height: 32px;
    background: #1da193 0% 0% no-repeat padding-box;
    border-radius: 5px;
    color: #f5f5f7;

    cursor: pointer;
    &:hover {
      opacity: 0.85;
      border: 0.1px solid #18aa72d0;
    }
  }
}
