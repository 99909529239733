@import "../../../assets/globalVariables.scss";

$card_blue: #1d3445;
$input_background: #f5f5f7;
$checkbox_color: #1da193;

@mixin icon-buttons($bgcolor) {
  width: 32px;
  height: 32px;
  background: $bgcolor !important;
  border: none;
  border-radius: 5px;
  // margin-right: 30px;
}

.status-container,
.employee-status-comp {
  padding: 5px;
  display: block;
  margin: 0 auto;
  margin-top: 20px;

  .employee-status-card {
    .ant-card-head {
      background-color: $card_blue;
      border-radius: 10px 10px 0 0;
      font-family: "Open Sans", sans-serif;
      font-weight: 600;
      font-size: 18px;
    }
    .left-side .ant-form {
      flex-direction: row;
    }
  }
  // .employee-status-card.ant-card {
  //   width: 340px !important; //Duhet vene gjeresi fikse sepse ne momentin e ngarkimit, zvogelohet card
  // }
  .add-status-button.ant-btn {
    @include icon-buttons(#f5f5f7);
  }
  .close-status-button.ant-btn {
    @include icon-buttons(#fe4c4a);
    margin-right: 30px;
  }

  .holder {
    margin-top: 22px;
  }
  .status-name {
    background-color: #323232;
    color: #fff;
    text-align: center;
    padding: 0px;
    width: 244px;
    height: 32px;
    border-radius: 5px;
    font: normal normal normal 16px/28px "Open Sans", sans-serif;
  }
  .down-button.ant-btn {
    @include icon-buttons(#f5f5f7);
    margin: 0px 0px;
  }
  .up-button.ant-btn {
    @include icon-buttons(#f5f5f7);
    margin: 0px 7px 0px;
  }
  .bin-button.ant-btn {
    @include icon-buttons(#fe4c4a);
    margin: 0px 5px 0px;
  }
  .name-btn-div {
    margin: 0px;
    height: 40px;
  }
  .ant-divider-vertical {
    height: 400px;
    margin-top: 45px;
  }
  &.dark {
    .ant-card {
      color: $white;
      background: $d-black2;
      box-shadow: 0px 0px 5px #75eade;
      border: none;
      .ant-card-head {
        color: $white;
        background: $d-black1;
        border: none;
      }
      .photoshop-picker {
        background: $d-black2 !important;
      }
      .hex-code-button {
        color: $d-black1;
      }
    }
  }
}

//Color picker (PhotoshopPicker)

.flexbox-fix:nth-child(1) {
  display: none !important;
  width: 0px !important;
}
.flexbox-fix div:nth-child(1) {
  border-style: none !important;
  border-width: 2px !important;
  border-style: hidden !important;
  border-color: #fff !important;
  border-radius: 5px !important;
}
.flexbox-fix div:nth-child(2) {
  border-style: none !important;
  border-width: 2px !important;
  border-style: hidden !important;
  border-color: #fff !important;
  border-radius: 5px !important;
}
.flexbox-fix div:nth-child(3) {
  width: 0px !important;
}
.flexbox-fix:nth-child(2) {
  // width: 550px !important;
  border-style: none !important;
  border-width: 2px !important;
  border-style: hidden !important;
  border-color: #fff !important;
  border-image: none !important;
  div:nth-child(1) {
    border: none !important;
  }
}

.hue-vertical {
  width: 32px;
}
.photoshop-picker {
  width: 306px !important;
  background-color: #fff !important;
  border: none !important;
  box-shadow: none !important;

  .flexbox-fix:nth-child(3) {
    width: 0px;
  }
}
.photoshop-picker > div:nth-of-type(1) {
  background-image: none !important;
  border-bottom: none !important;
  box-shadow: none !important;
  font: normal normal normal 14px/19px "Open Sans", sans-serif !important;
}

@media (max-width: 769px) {
  .status-container .right-side {
    margin-left: 10px;
  }
}

@media (max-width: 769px) {
  .status-container {
    .alldivs {
      display: grid !important;
    }
  }
  .status-container {
    height: 97% !important;
    width: 100%;
    .status-name {
      width: 210px !important;
    }
  }
  .status-container
    .emp-status
    .ant-form-item-control-input-content
    .ant-input {
    width: 150px;
  }
  .status-container .ant-divider-vertical {
    display: none;
  }
  .status-container .ant-card.ant-card-bordered.employee-status-card {
    height: 700px !important;
  }
  .status-container .photoshop-picker {
    width: 240px !important;
  }
}

@media (max-width: 450px) {
  .status-container .photoshop-picker {
    width: 100% !important;
  }
  .status-container .right-side {
    margin-left: 10px;
  }
}
