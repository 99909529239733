$card_blue: #1d3445;
$input_background: #f5f5f7;
$checkbox_color: #1da193;
// icont

.container {
  display: flex;
  grid-gap: 20px;
  text-align: left;
  padding-bottom: 23px;
  flex-wrap: nowrap;
  width: 100%;
}

.info-card .ant-card-head {
  background-color: $card_blue !important;
  border-radius: 10px 10px 0 0 !important;
  color: white !important;
  font-family: "Open Sans", sans-serif !important;
  font-weight: 600 !important;
  font-size: 18px;
  .ant-card-head-wrapper {
    height: 45px;
  }
}
.info-card .ant-card-body {
  padding: 15px 20px;
  height: 422px;
  overflow-y: auto;
  overflow-x: hidden;
  .ant-form-item {
    margin-bottom: 12px;
  }
}

/* Icon styles */
.icon-button {
  button {
    width: 32px;
    height: 32px;
    border-radius: 5px;
    border: none;
    cursor: pointer;
  }

  button:hover {
    opacity: 0.7;
  }
  #close {
    background-color: #ea3943;
    :hover {
      opacity: 0.7;
    }
  }
  #tick {
    background-color: #1da193;
    :hover {
      opacity: 0.7;
    }
  }
}

.pen-icon {
  margin-top: 5px;
  width: 14px;
  pointer-events: none;
}

.action-icon {
  margin-top: 5px;
  width: 16px;
  pointer-events: none;
}

.personal-info {
  width: 330px;
}

//Pozicionon checkboxin te statusi, pa editim
.info-main-div {
  height: 310px !important;
}

div#tabBody {
  justify-content: flex-start;
  margin-left: 20px;
}

.ant-row.employe-view-row {
  .react-tel-input .form-control {
    width: 100% !important;
  }
}

// @media (max-width: 450px) {
//   .ant-tabs.ant-tabs-top.ant-tabs-mobile.punojsit-tab {
//     .ant-tabs-nav-list {
//       display: grid !important;
//     }
//   }
// }

@media (max-width: 391px) {
  .icon-button button {
    margin-right: -5px;
  }
}
