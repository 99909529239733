@import "../../../../../assets/globalVariables.scss";

.departmentRolesContainer {
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
  height: 100%;
  .departmentRoles {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 20px;
    height: 100%;
    overflow: auto;
    padding: 10px;
    .roleCard {
      box-shadow: 1px 1px 2px 1px #00000029;
      border-radius: 10px;
      .ant-form {
        height: 100%;
        .ant-card {
          display: flex;
          flex-direction: column;
          height: 100%;
          .ant-card-head {
            background: #1d3445;
            color: #fff;
            border-radius: 10px 10px 0 0;
            padding: 0 10px;
            .cardHeaderTitle {
              display: flex;
              justify-content: space-between;
              .name {
                display: flex;
                justify-content: center;
                align-items: center;
                gap: 10px;
                width: 100%;
              }
              .icon {
                width: 25px;
                height: 25px;
                background: #f1f7fb;
                display: flex;
                justify-content: center;
                align-items: center;
                border-radius: 5px;
                cursor: pointer;
              }
              .editMode {
                background: #ea3943;
                path#Path_6864 {
                  fill: #fff;
                }
              }
            }
          }
          .ant-card-body {
            display: flex;
            flex-direction: column;
            height: 100%;
            gap: 10px;
            .supervisor {
              width: 100%;
              display: flex;
              justify-content: center;
              span {
                font-size: small;
                border-radius: 5px;
                font-weight: 400;
                color: #fff;
                padding: 5px 10px;
                background: #1d3445;
              }
            }
            h1 {
              width: 100%;
              text-align: center;
              margin: 0;
            }
            .modeContainer {
              display: flex;
              flex-direction: column;
              align-items: center;
              gap: 10px;
              width: 100%;
              height: 100%;
              .quillTextEditor {
                height: 100%;
              }
              .description {
                width: 100%;
                height: 100%;
                background: #f1f7fb;
                padding: 10px;
                border-radius: 5px;
              }
            }
          }
        }
      }
      .fallbackText {
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 16px;
        text-align: center;
      }
    }
  }
  .departmentRolesFooter {
    position: sticky;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 100%;
    padding: 15px 0;
    border-radius: 10px;
    background: #fff;
    box-shadow: 2px 1px 5px 1px #00000029;
    padding-right: 30px;
  }
  &.dark {
    .departmentRoles .roleCard {
      box-shadow: 0px 0px 5px #75eade;
      .ant-form .ant-card {
        color: $white;
        background: $d-black3;
        .ant-card-head {
          border: none;
          background: $d-black1;
        }
        .modeContainer .description,
        .supervisor span {
          background: $d-black5;
        }
      }
    }
    .departmentRolesFooter {
      background: $d-black1;
      box-shadow: 0px 0px 5px #75eade;
    }
  }
}
// @media (min-width: 1920px) {
//   .departmentRoles {
//     grid-template-columns: 1fr 1fr 1fr 1fr 1fr !important;
//   }
// }
@media (max-width: 1200px) {
  .departmentRoles {
    grid-template-columns: 1fr 1fr !important;
  }
}
@media (max-width: 450px) {
  .departmentRoles {
    grid-template-columns: 1fr !important;
    .roleCard {
      height: 450px;
    }
  }
}
