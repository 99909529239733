$card_blue: #1d3445;
$input_background: #f5f5f7;
$checkbox_color: #1da193;
@import "../../../../assets/globalVariables.scss";
.employeePayment {
  display: grid;
  grid-template-columns: 1fr 2fr;
  gap: 30px;
  height: 100%;
  width: 100%;
  .paymentCard {
    width: 100%;
    display: flex;
    flex-direction: column;
    .ant-card-head {
      display: flex;
      background-color: #1d3445;
      border-radius: 10px 10px 0 0;
      color: #fff;
      font-family: Open Sans, sans-serif;
      font-size: 18px;
      font-weight: 600;
      .ant-card-head-wrapper {
        width: 100%;
        .ant-card-head-title,
        .ant-card-extra {
          padding: 0;
        }
      }
    }
    .ant-card-body {
      height: 100%;
    }
    .paymentData {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-template-rows: 1fr 1fr 1fr;
      height: 100%;
      gap: 1rem;
      .brick {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background-color: #f5f5f7;
        border-radius: 5px;
        padding: 20px;
        gap: 1rem;
        .fieldName {
          font: 800 16px / 22px "Open Sans";
          text-align: center;
        }
        .label {
          color: #000000d9;
          font: 600 14px / 22px "Open Sans";
          text-align: center;
        }
      }
    }
    .infoHeader {
      display: block;
      width: 100%;
      text-align: center;
      font: normal normal 600 16px/22px Open Sans;
      margin-bottom: 20px;
    }
    .paymentForm {
      display: flex;
      flex-direction: column;
      gap: 30px;
      .formItem {
        display: grid;
        grid-template-columns: minmax(0, 2fr) minmax(0, 5fr);
        grid-template-rows: auto;
        gap: 20px;
        .input {
          display: flex;
          gap: 1rem;
        }
      }
    }
    &.dark {
      .paymentData .brick {
        background: $d-black5;
        .fieldName {
          color: $white;
        }
        .label {
          color: $grey-2;
        }
      }
    }
  }
  .payment-grid {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
  }
}
@media (max-width: 1366px) {
  .employeePayment {
    display: grid;
    grid-template-columns: minmax(0, 2fr) minmax(0, 3fr) !important;
  }
}
@media (max-width: 1366px) {
  .employeePayment {
    .brick {
      svg {
        width: 60px;
        height: 60px;
      }
      .fieldName {
        font-size: 14px !important;
      }
      .label {
        font-size: 12px !important;
      }
    }
  }
}
@media (max-width: 1366px) {
  .employeePayment {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
}
