@import "../../index.scss";

.punonjesitPage {
  display: flex;
  flex-direction: column;
  gap: 15px;
  height: 100%;
  .punonjesitCards {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 15px;
  }
  .employeeCard {
    display: flex;
    flex-direction: column;
    gap: 5px;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 225px;
    cursor: pointer;
    border-radius: 10px;
    box-shadow: 1px 1px 1px 1px #00000029;
    padding: 10px;
    text-align: center;
    font-size: 14px;
    svg {
      height: 80px;
      width: 80px;
    }
    .cardTitle {
      text-align: center;
      font: normal normal 600 20px/25px "Open Sans", sans-serif;
    }
  }
}
@media (max-width: 450px) {
  .punonjesitPage {
    gap: 5px;
    .ant-collapse {
      width: 100%;
      .ant-collapse-content {
        background: unset;
        border: none;
        .ant-collapse-content-box {
          display: grid;
          grid-template-columns: repeat(4, minmax(0, 1fr));
          gap: 5px;
          padding: 5px;
          font-weight: 600;
        }
      }
    }
    .punonjesitCards{
      gap: 5px;
    }
    .employeeCard {
      height: 100%;
      font-size: 12px;
      svg {
        width: 35px;
        height: 35px;
      }
      .cardTitle {
        font: normal normal 600 12px/16px "Open Sans", sans-serif;
      }
    }
  }
}
