.dynamicInterface {
  .login-grid {
    width: 100%;
    padding: 0 25px;
    .ant-form {
      gap: 30px;
      .ant-form-item {
        margin: 0;
      }
      .custom-form {
        display: flex;
        justify-content: space-around;
      }
    }
  }
  .ant-form-item-control-input-content {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  span.ant-input-affix-wrapper {
    background-color: #f5f5f7 !important;
    border: none;
    input.ant-input {
      background: #f5f5f7;
      color: #0288d2;
    }
  }
  .ant-row.ant-form-item.ant-form-item-with-help.ant-form-item-has-error {
    span.ant-input-affix-wrapper {
      background-color: #ea3943 !important;
      border: none;
      ::placeholder {
        color: #fff !important;
      }
      input.ant-input {
        background-color: #ea3943 !important;
        color: #fff !important;
      }
      span.ant-input-suffix {
        color: #fff;
      }
    }
  }

  label.ant-checkbox-wrapper {
    display: flex;
    justify-content: space-around;
    span.ant-checkbox {
      height: 20px;
      border: none;
    }
    span.ant-checkbox:hover {
      border: none !important;
    }

    span.ant-checkbox-inner {
      width: 25px;
      height: 25px;
      background: #f5f5f7;
      :focus {
        border: #f5f5f7;
      }
    }
    span.ant-checkbox-inner:hover {
      border: none;
    }
  }
  span.ant-checkbox.ant-checkbox-checked {
    span.ant-checkbox-inner {
      background: #0288d2;
      border: none;
    }
  }
  label.ant-checkbox-wrapper.ant-checkbox-wrapper-checked:hover {
    border: none !important;
    span.ant-checkbox.ant-checkbox-checked {
      border: none !important;
    }
  }
  label.ant-checkbox-wrapper.ant-checkbox-wrapper-checked:focus {
    border: none !important;
    span.ant-checkbox.ant-checkbox-checked {
      border: none !important;
    }
  }

  a.login-form-forgot {
    color: #0288d2;
  }

  button.ant-btn.ant-btn-primary.login-form-button {
    background: #f5f5f7;
    color: #323338;
    border: none;
    width: 187px;
    height: 32px;
    border-radius: 5px;
    // pointer-events: none;
  }
  button.ant-btn.ant-btn-primary.filled-button {
    background: #0288d2;
    color: #fff;
    border: none;
    width: 187px;
    height: 32px;
    border-radius: 5px;
  }

  .ant-checkbox-checked::after {
    border: none !important;
  }

  button.your-custom-class {
    box-shadow: rgb(0 0 0 / 24%) 0px 0px 0px 0px,
      rgb(0 0 0 / 24%) 0px 0px 0px 0px !important;
    color: #0288d2 !important;
    cursor: pointer;
  }
}

.ant-form-item input[type="checkbox"] {
  width: 25px;
  height: 25px;
}

.login .ant-checkbox-checked .ant-checkbox-inner::after {
  position: absolute;
  // width: 8.714286px;
  // height: 16.142857px;
  height: 75%;
  display: table;
  border: 2px solid #0288d2;
  border-top: 0;
  border-left: 0;
  transform: rotate(45deg) scale(1) translate(-50%, -50%);
  opacity: 1;
  transition: all 0.2s cubic-bezier(0.12, 0.4, 0.29, 1.46) 0.1s;
  margin-left: -1px;
  margin-top: -1.5px;
  content: " ";
}

.login span.ant-checkbox.ant-checkbox-checked span.ant-checkbox-inner {
  background: #f5f5f7 !important;
}

.loading-login {
  display: flex;
  justify-content: center;
  padding: 10px 0px;
  animation: appearSoftly 1s;
}

.swal-modal.custum-swal-loginError {
  .swal-text {
    text-align: center;
  }
  .swal-button {
    background-color: #ea3943;
  }
}

.swal-modal.custum-swal-loginSuccess {
  .swal-button {
    background-color: #1da193;
  }
}
@media (max-width: 500px) {
  .swal-modal {
    width: calc(100% - 69px) !important;
  }
}

@media (max-width: 450px) {
  .login label.ant-checkbox-wrapper {
    margin-right: 0px;
  }
  .dynamicInterface {
    .login-grid {
      width: 95% !important;
    }
  }
  .forgot-password .ant-form-item-control-input-content {
    margin-left: 10px;
    margin-right: 10px;
  }
  .forgot-password .login-grid {
    width: 380px;
  }
  .login .ant-form-item-control-input-content {
    margin-right: 15px;
    margin-left: 15px;
  }
  .forgot-password button.ant-btn.ant-btn-primary.back-form-button {
    width: 100px;
  }
}

@media (max-width: 391px) {
  .login label.ant-checkbox-wrapper {
    margin-right: 0px;
  }
  .login-grid {
    width: 360px !important;
    height: 380px !important;
  }
  .forgot-password .ant-form-item-control-input-content {
    margin-left: 10px;
    margin-right: 10px;
  }
  .login .ant-form-item-control-input-content {
    margin-right: 15px;
    margin-left: 15px;
  }
  .forgot-password button.ant-btn.ant-btn-primary.back-form-button {
    width: 100px;
  }
}

@keyframes appearSoftly {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

// @media (max-width: 360px) {
//   .login label.ant-checkbox-wrapper {
//     margin-right: 0px;
//   }
//   .login .login-grid {
//     width: 350px;
//   }
//   .forgot-password .ant-form-item-control-input-content {
//     margin-left: 10px;
//     margin-right: 10px;
//   }
//   .forgot-password .login-grid {
//     width: 350px;
//   }
//   .forgot-password .background {
//     height: 621px;
//   }
//   .forgot-password button.ant-btn.ant-btn-primary.back-form-button {
//     width: 95px;
//   }
//   .login .background {
//     height: 621px;
//   }
// }
