@import "../../../index.scss";

.documentationView {
  display: grid;
  grid-template-columns: 4fr 9fr;
  gap: 15px;
  width: 100%;
  height: 100%;
}

//* responsive for iPad Air
@media (max-width: 821px) {
  .documentationViewContainer .bodyContainer {
    display: grid;
  }
  .formCard.documentationFileTree {
    max-width: 100%;
    height: 100%;
  }
  .formCardTitle {
    width: 110%;
  }
}
