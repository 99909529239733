.sistemi-llojet-kerkesave {
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
  height: 100%;
  overflow-y: auto;
}

@mixin icon-buttons($bgcolor) {
  width: 32px;
  height: 32px;
  background: $bgcolor !important;
  border: none;
  border-radius: 5px;
  // margin-right: 30px;
}
.sistemi-llojet-kerkesave-row {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
}
.llojet-kerkesave {
  width: 100%;
  height: 100%;
  text-align: center;
  .employee-status-card {
    .ant-card-head {
      background-color: #1d3445;
      border-radius: 10px 10px 0 0;
      color: white;
      font-family: "Open Sans", sans-serif;
      font-weight: 600;
      font-size: 18px;
    }
  }

  button.ant-btn.shto-llojn-btn {
    background: #1da193;
    color: #fff;
    border-radius: 5px;
    border: none;
  }
  span.llojet-input-icon {
    background: #1da193;
    border-radius: 3px;
    margin-left: 10px;
    padding-left: 4px;
    padding-right: 4px;
    padding-top: 2px;
    padding-bottom: 2px;
    height: 25px;
  }
  .label-quill-div {
    h3 {
      margin-top: 20px;
      text-align: start;
      margin-left: 90px;
    }
  }
  .quill-div {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
  }
  .ql-editor {
    min-height: 100px;
  }
}
.request-title-2 {
  color: #1da193;
  font-weight: 600;
  cursor: pointer;
}

@media (max-width: 820px) {
  .ant-row.sistemi-llojet-kerkesave-row {
    display: grid;
    .ant-col {
      max-width: 100%;
    }
    .ant-col.ant-col-12.col-respon {
      height: 460px !important;
    }
    .ant-col.ant-col-12.col-second-respon {
      padding-left: 20px;
      margin-bottom: 20px;
    }
  }
  .employee-status-comp
    .emp-status
    .ant-form-item-control-input-content
    .ant-input {
    width: 140px;
  }
}
@media (max-width: 450px) {
  .sistemi-llojet-kerkesave-row {
    grid-template-columns: 1fr;
  }
}

// @media (max-width: 450px) {
//   .ant-row.sistemi-llojet-kerkesave-row {
//     grid-template-columns: 1fr;
//     .ant-col.ant-col-12.col-respon {
//       height: 510px !important;
//       width: 100% !important;
//       padding-right: 0px;
//       text-align: start;
//     }
//     .ant-col.ant-col-12.col-second-respon {
//       padding-left: 20px;
//       margin-bottom: 20px;
//       height: 600px !important;
//       width: 100% !important;
//       .card {
//         height: 100% !important;
//       }
//       .ant-card {
//         height: 100% !important;
//       }
//       .alldivs {
//         display: grid !important;
//       }
//     }
//     .photoshop-picker {
//       width: 280px !important;
//     }
//   }
//   .employee-status-comp .right-side {
//     margin-left: 0px !important;
//   }
//   .ant-divider.ant-divider-vertical {
//     display: none;
//   }
//   .employee-status-comp
//     .emp-status
//     .ant-form-item-control-input-content
//     .ant-input {
//     width: 140px;
//   }
//   .llojet-kerkesave .quill.note_input.quillTextEditor.lloj-kerkesash-quill {
//     width: 200px;
//   }
//   .llojet-kerkesave .label-quill-div h3 {
//     margin-left: 0px !important;
//   }
//   .ant-col.ant-col-12.col-respon {
//     width: 310px !important;
//     height: 100%;
//     margin-bottom: 10px;
//     .card {
//       height: 100% !important;
//     }
//     .ant-card {
//       height: 100% !important;
//     }
//   }
// }
