@import "../../../../../../index.scss";

//* styles documentationsCheckboxesContainer justify-content:center, align-items:center, and flex-direction:column
.documentationsCheckboxesContainer {
  width: 100%;
  max-width: 1050px;
  @include flex(center, center, column);

//* styles checkBoxes justify-content:center, align-items:center
  .checkBoxes {
    @include flex(center, center);
    flex-wrap: wrap;
    gap: 10px;
  }
  .checkboxesContainerTitle {
    @include text(16px, 600, 0, #323338);
    margin-bottom: 10px;
  }

  .checkboxTitle {
    font-size: 16px;
  }
}
