.createUserModal {
  .userInformations {
    display: flex;
    .userInformationsForm {
      flex: 1;
      display: flex;
      flex-direction: column;
      gap: 10px;
      .ant-form-item-row {
        display: grid;
        grid-template-columns: 2fr 3fr;
      }
    }
    .image {
      flex: 1;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
    }
  }
}
