.autumn-leaf {
	position: absolute;
	width: 32px;
	height: 32px;
	/* background-color: #ff9900;  */
	/* Autumn leaf color */
	/* clip-path: polygon(50% 0%, 100% 40%, 80% 100%, 20% 100%, 0% 40%); */
	animation: fall linear infinite;
	/* background-image: url("./../spider_icon.png"); */
	background-size: cover;
	background-image: url("http://www.clipartqueen.com/image-files/red-lobed-fall-clipart-leaf.png");
	z-index: 23242424;
	opacity: 1; /* Adjust the opacity as desired */
}
.pumpkinFall {
	position: absolute;
	width: 52px;
	height: 52px;
	/* background-color: #ff9900;  */
	/* Autumn leaf color */
	/* clip-path: polygon(50% 0%, 100% 40%, 80% 100%, 20% 100%, 0% 40%); */
	animation: fall linear infinite;
	background-image: url("./../pumpkin.png");
	background-size: cover;
	/* background: url("http://www.clipartqueen.com/image-files/red-lobed-fall-clipart-leaf.png"); */
	z-index: 23242424;
	opacity: 1;
}
.snowFall {
	position: absolute;
	width: 12px;
	height: 12px;
	/* background-color: #ff9900;  */
	/* Autumn leaf color */
	/* clip-path: polygon(50% 0%, 100% 40%, 80% 100%, 20% 100%, 0% 40%); */
	animation: fall linear infinite;
	background: white;
	border-radius: 50%;
	/* background-image: url("./../snowflake.png"); */
	background-size: cover;
	/* background: url("http://www.clipartqueen.com/image-files/red-lobed-fall-clipart-leaf.png"); */
	z-index: 23242424;
	opacity: 1;
}
.bat-icon {
	position: absolute;
	width: 72px;
	height: 80px;
	/* background-color: #ff9900;  */
	/* Autumn leaf color */
	/* clip-path: polygon(50% 0%, 100% 40%, 80% 100%, 20% 100%, 0% 40%); */
	animation: fall linear infinite;
	background-image: url("./../bat_icon.png");
	background-size: cover;
	/* background: url("http://www.clipartqueen.com/image-files/red-lobed-fall-clipart-leaf.png"); */
	z-index: 23242424;
	opacity: 1; /* Adjust the opacity as desired */
}
.autumn-leaves-container {
	position: absolute;
	width: 100%;
	height: 100vh;
	overflow: hidden;
	pointer-events: none;
}

@keyframes fall {
	0% {
		transform: translateY(-20px);
		opacity: 1;
	}
	100% {
		transform: translateY(100vh);
		opacity: 0;
	}
}
@keyframes witchAnimation {
	0% {
		transform: translateX(0) translateY(0) rotate(0deg);
	}
	100% {
		transform: translateX(100px) translateY(50px) rotate(360deg);
	}
}
