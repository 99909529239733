@import "../../../../assets/globalVariables.scss";

.payStub-table2 {
  table {
    font-family: arial, sans-serif;
    border-collapse: collapse;
    border: none;
    width: 100%;
    border: 1px solid #e3eaf3;
  }

  td,
  th {
    // border: 1px solid #dddddd;
    // text-align: left;
    padding: 5px;
    border: 1px solid #e3eaf3;
  }
  th {
    background: white;
    .sort-icon-wrapper {
      display: none;
    }
    &:hover {
      color: #fcc94a;
      cursor: pointer;
      .sort-icon-wrapper {
        display: contents;
      }
    }
  }
  tr {
    background: #70707017;
    // text-align: right;
    &:hover {
      background: #e3eaf3;
    }
  }

  tr:nth-child(even) {
    background: #ffffff;
    &:hover {
      background: #e3eaf3;
    }
  }
  &.dark {
    td,
    table {
      border-color: $grey-2;
    }
    tr,
    th {
      border-color: $grey-2;
      background: none;
    }
    tr:hover{
      background: $grey-3;
    }
  }
}
